export const FETCH_TIMELINE_REQUEST = 'FETCH_TIMELINE_REQUEST';
export const FETCH_TIMELINE_SUCCESS = 'FETCH_TIMELINE_SUCCESS';
export const FETCH_TIMELINE_FAILURE = 'FETCH_TIMELINE_FAILURE';


export const fetchTimelineRequest = (patientId,visitId) => {
  return {
      type: FETCH_TIMELINE_REQUEST,
      patientId:patientId,
      visitId:visitId
  }
}

export const fetchTimelineSuccess = data => {
  return {
      type: FETCH_TIMELINE_SUCCESS,
      payload: data
  }
}

export const fetchTimelineFailure = error => {
  return {
      type: FETCH_TIMELINE_SUCCESS,
      payload: error
  }
}
