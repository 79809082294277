import React from 'react';

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import Sunday from './ScheduleByDays/Sunday'
import Monday from './ScheduleByDays/Monday'
import Tuesday from './ScheduleByDays/Tuesday'
import Wednesday from './ScheduleByDays/Wednesday'
import Thursday from './ScheduleByDays/Thursday'
import Friday from './ScheduleByDays/Friday'
import Saturday from './ScheduleByDays/Saturday'

const styles = (theme) => ({
  root: {
    // padding: "16px",
  },
});
const useStyles = makeStyles(styles);

const ScheduleData = (props) => {
  const classes = useStyles();
  const { count } = props;
  return (
    <div className={classes.root} style={{ marginTop: "36px" }}>
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CustomTabs
          style={{ width: 828, padding: 2 }}
            headerColor="primary"
            tabs={[
              {
                tabName: "Sunday",
                tabContent: (
                  <React.Fragment>
                <Sunday count={count}/>
                  </React.Fragment>
                ),
              },
              {
                tabName: "Monday",
                tabContent: (
                  <React.Fragment>
                    <div>
                      <Monday />
                    </div>
                  </React.Fragment>
                ),
              },
              {
                tabName: "Tuesday",
                tabContent: (
                  <React.Fragment>
                    <div>
                      <Tuesday />
                    </div>
                  </React.Fragment>
                ),
              },
              {
                tabName: "Wednesday",
                tabContent: (
                  <React.Fragment>
                    <div>
                      <Wednesday />
                    </div>
                  </React.Fragment>
                ),
              },
              {
                tabName: "Thursday",
                tabContent: (
                  <React.Fragment>
                    <div>
                      <Thursday />
                    </div>
                  </React.Fragment>
                ),
              },
              {
                tabName: "Friday",
                tabContent: (
                  <React.Fragment>
                    <div>
                      <Friday />
                    </div>
                  </React.Fragment>
                ),
              },
              {
                tabName: "Saturday",
                tabContent: (
                  <React.Fragment>
                    <div>
                      <Saturday />
                    </div>
                  </React.Fragment>
                ),
              },
            ]}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ScheduleData;