export const FETCH_USER_INFO_REQUEST = "FETCH_USER_INFO_REQUEST";
export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const FETCH_USER_INFO_FAILURE = "FETCH_USER_INFO_FAILURE";

export const PROFILE_UPDATE = "PROFILE_UPDATE";
export const PROFILE_UPDATE_START = "PROFILE_UPDATE_START";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAILURE = "PROFILE_UPDATE_FAILURE";

export const fetchUserInfoRequest = () => {
  return {
    type: FETCH_USER_INFO_REQUEST,
  };
};

export const fetchUserInfoSuccess = (userData) => {
  return {
    type: FETCH_USER_INFO_SUCCESS,
    payload: userData,
  };
};

export const fetchUserInfoFailure = (error) => {
  return {
    type: FETCH_USER_INFO_FAILURE,
    payload: error,
  };
};

export const profileUpdate = (profileUpdateData) => {
  return {
    type: "PROFILE_UPDATE",
    profileUpdateData: profileUpdateData,
  };
};

export const profileUpdateStart = () => {
  return {
    type: "PROFILE_UPDATE_START",
  };
};

export const profileUpdateSuccess = (data) => {
  return {
    type: "PROFILE_UPDATE_SUCCESS",
    payload: data,
  };
};

export const profileUpdateFail = (error) => {
  return {
    type: "PROFILE_UPDATE_FAILURE",
    error: error,
  };
};
