import React from "react";
import { Dialog, Typography, Grid } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/styles";
import Button from "../CustomButtons/Button";
import { putRequest } from "../../api/Api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({}));

const UnassignAppointmentAlert = ({
  open,
  onClose,
  visitId,
  setCurrentVisitId,
}) => {
  const classes = useStyles();

  const unassignConfirmationHandler = () => {
    const token = "Bearer " + localStorage.getItem("jwt");
    const body = {
      token: token,
      endPoint: "unassign_visit_from_doctor",
      formData: "",
      params: [visitId],
    };
    putRequest(body).then((data) => {
      if (data.error) {
        alert(data.error);
        onClose(false);
        setCurrentVisitId(null);
      } else {
        alert(data.message);
        onClose(false);
        setCurrentVisitId(null);
      }
    });
  };

  return (
    <Dialog TransitionComponent={Transition} open={open}>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          md={12}
          sm={12}
          justify="center"
          direction="column"
          alignItems="center"
        >
          <Typography
            align="center"
            gutterBottom
            variant="h5"
            style={{ marginTop: "4%", fontSize: "21px" }}
          >
            Do you wish to unassign this appointment ?
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={12}
          sm={12}
          justify="center"
          alignItems="center"
        >
          <Button
            style={{ backgroundColor: "rgb(50, 123, 175)", color: "white" }}
            onClick={unassignConfirmationHandler}
          >
            Yes
          </Button>
          <Button
            style={{
              backgroundColor: "#b3b2b0",
              color: "black",
              marginLeft: "2%",
            }}
            onClick={() => onClose(false)}
          >
            No
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default UnassignAppointmentAlert;
