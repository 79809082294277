import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButtons/Button";
import PropTypes from "prop-types";
import { Dialog, Typography } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import CardBody from "../../components/Card/CardBody";
import moment from "moment";
import { preLogOffConsultation } from "../../actions";
import { connect } from "react-redux";
import { checkEndTime } from "../../utils/helper";
import axios from "../../utils/axios1";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EndConsultation = (props) => {
  const { onClose, open, preLogOffConsultation, logOffType } = props;
  const classes = useStyles();
  const [msg, setMsg] = useState("");
  const [activeAppointmentCount, setActiveAppointmentCount] = useState();

  useEffect(() => {
    let scheduledEndTime = localStorage.getItem("end_time");
    let desiredMsg = checkEndTime(scheduledEndTime, logOffType);
    setMsg(desiredMsg);
    let jwt = localStorage.getItem("jwt");
    if (jwt && open) {
      let token = "Bearer " + jwt;
      axios
        .get(`/get_doctor_current_appointments`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setActiveAppointmentCount(response.data.active_appointments);
        })
        .catch((error) => {
          if (error.response.data !== "") {
            alert(error.response.data.error);
          } else {
            alert(error.response.statusText);
          }
        });
    }
  }, [open, logOffType]);

  const endConsultations = () => {
    let consultationId = localStorage.getItem("consultation_id");
    let doctor_pre_log_off_history = {
      doctor_consultation_id: consultationId,
      log_off_type: logOffType,
    };
    preLogOffConsultation(doctor_pre_log_off_history);
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      TransitionComponent={Transition}
      open={open}
      // fullWidth={true}
      // maxWidth={"md"}
    >
      <div className={classes.root1}>
        <div className={classes.header}>
          <Typography
            align="center"
            gutterBottom
            variant="h5"
            style={{ color: "blue" }}
          >
            End Consultation
          </Typography>
        </div>
        <CardBody>
          <Typography>{msg}</Typography>
          <Typography>
            Your End Time is : {localStorage.getItem("end_time")}{" "}
          </Typography>
          <br />
          <Typography>
            You have{" "}
            <span style={{ fontWeight: 800 }}>{activeAppointmentCount}</span>{" "}
            Active Appointment(s)
          </Typography>
        </CardBody>
        <CustomButton data_testid={`end-consultation-cancel-button`} style={{ width: 80, float: "right" }} onClick={onClose}>
          Cancel
        </CustomButton>
        <CustomButton
          style={{
            width: 80,
            float: "right",
            backgroundColor: "rgb(50, 123, 175)",
          }}
          data_testid={`end-consultation-submit-button`}
          onClick={endConsultations}
        >
          Submit
        </CustomButton>
      </div>
    </Dialog>
  );
};

EndConsultation.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
  return {
    preLogOffConsultation: (data) => dispatch(preLogOffConsultation(data)),
  };
};

export default connect(null, mapDispatchToProps)(EndConsultation);
