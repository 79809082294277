import React, { useState,useEffect} from "react";
import { Grid } from "@material-ui/core";

import CardBody from "../../../components/Card/CardBody";
import Card from "../../../components/Card/Card";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Button from '../../../components/CustomButtons/Button'
import { fetchTodaySchedule } from "../../../actions";
import ScheduleData from './ScheduleData'
import ScheduleForm from './ScheduleForm'

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "block",
    boxSizing: "border-box",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid black",
    padding: "14px 15px",
    marginTop: "9px",
    fontSize: "14px",
  },
}));

const Schedule = (props) => {
  const {    
    fetchTodaySchedule 
  } = props;
  const classes = useStyles();
  const [isScheduleForm, setIsShowScheduleForm] = useState(false);
  const [count,setCount]= useState(0)



  const editScheduleHandle = () => {
    setIsShowScheduleForm(true);
    setCount(count+1)
  };
  const hideEditScheduleHandle = () => {
    setIsShowScheduleForm(false);
    setCount(count-1)
  };

  return (
    <div className={classes.root1}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Card style={{ marginTop: 1, backgroundColor: "#fffff2" }}>
          <Grid item xs={12} sm={12} md={12}>
            <Button 
            style={{ float:'right',backgroundColor:'rgb(50, 123, 175)' }}
            onClick={editScheduleHandle}
            >Add</Button>
            </Grid>
            <CardBody>
              {isScheduleForm === false ?
              <ScheduleData/>:
              <ScheduleForm count={count} hideEditScheduleHandle={hideEditScheduleHandle}/> }
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTodaySchedule:(slotParam)=>dispatch(fetchTodaySchedule(slotParam)),
  };
};

export default connect(null, mapDispatchToProps)(Schedule);

