export const FETCH_DOCUMENT_REQUEST = 'FETCH_DOCUMENT_REQUEST';
export const FETCH_DOCUMENT_SUCCESS = 'FETCH_DOCUMENT_SUCCESS';
export const FETCH_DOCUMENT_FAILURE = 'FETCH_DOCUMENT_FAILURE';


export const fetchDocumentRequest = (patientId,visitId) => {
  return {
      type: FETCH_DOCUMENT_REQUEST,
      patientId:patientId,
      visitId:visitId
  }
}

export const fetchDocumentSuccess = medicine => {
  return {
      type: FETCH_DOCUMENT_SUCCESS,
      payload: medicine
  }
}

export const fetchDocumentFailure = error => {
  return {
      type: FETCH_DOCUMENT_FAILURE,
      payload: error
  }
}
