import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Link as RouterLink } from "react-router-dom";
import Card from "../../../../components/Card/Card";
import CardAvatar from "../../../../components/Card/CardAvatar";
import CardBody from "../../../../components/Card/CardBody";
import avatar from "../../../../assets/img/patient1.jpg";
import styles from "../../../../assets/jss/material-dashboard-react/views/doctorCategory";
import DateRangeIcon from "@material-ui/icons/DateRange";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import { fetchSearchPatientByDiagnosisRequest } from "../../../../actions";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import PrescriptionModal from "./PrescriptionModal/PrescriptionModal";
import ListIcon from "@material-ui/icons/List";
import Fab from "@material-ui/core/Fab";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import { Link, Typography, Button } from "@material-ui/core";
import moment from 'moment'

const useStyles = makeStyles(styles);
const PatientCard = (props) => {
  const { doctor, fetchSearchPatientByDiagnosisRequest, diagnosis } = props;

  const [prescriptionModalOpen, setPrescriptionModalOpen] = useState(false);
  console.log(doctor);
  var today = new Date();
  var birthDate = new Date(doctor.attributes.dob);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  const classes = useStyles();

  const handlePres = (id) => {
    console.log(id);
    console.log(diagnosis);
    fetchSearchPatientByDiagnosisRequest(id, diagnosis);
    handlePrescriptionOpen();
  };

  const handlePrescriptionOpen = () => {
    setPrescriptionModalOpen(true);
  };

  const handlePrescriptionClose = () => {
    setPrescriptionModalOpen(false);
  };
  return (
    <Card profile className={classes.card}>
      <CardAvatar profile>
        {doctor.attributes.profile_photo !== null ?
          <div
            style={{
              height: "130px",
              backgroundColor: "#489a9c",
            }}
          >
            {" "}
            <img
              src={doctor.attributes.profile_photo}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                position: "acsolute",
                cursor: "pointer",
              }}
            />
          </div>:
          <img src={avatar} alt="..." />}
      </CardAvatar>
      <CardBody>
        <h6 className={classes.cardCategory} style={{ fontWeight: 600 }}>
          {doctor.attributes.name}
        </h6>
        <br />
        <div className={classes.stats}>
          <DateRangeIcon />
          <span style={{ fontWeight: 600 }}>Age: </span>
          <span
            style={{
              fontWeight: 600,
              marginLeft: 5,
              color: "rgb(29, 96, 154)",
            }}
          >
            {age}/{doctor.attributes.gender}
          </span>
        </div>{" "}
        <br />
        <div className={classes.stats}>
          <DirectionsWalkIcon />
          <span style={{ fontWeight: 600 }}>Last Visit: </span>
          <span
            style={{
              fontWeight: 600,
              marginLeft: 5,
              color: "rgb(29, 96, 154)",
            }}
          >
              {doctor.attributes.last_visit_datetime ?
                moment.utc(doctor.attributes.last_visit_datetime)
                .local()
                .format("DD/MM/YYYY")
                    : ""}
          </span>
        </div>
        <br />
        <div className={classes.stats}>
          <ListIcon />
          <span style={{ fontWeight: 600 }}>Total Visit: </span>
          <span
            style={{
              fontWeight: 600,
              marginLeft: 5,
              color: "rgb(29, 96, 154)",
            }}
          >
            {doctor.attributes.visits.length}
          </span>
        </div>
        <br />
      </CardBody>
      <div className={classes.actions}>
        <Typography
          style={{ fontWeight: 500, marginTop: -12, color: "brown" }}
          variant="body2"
        >
          Action
        </Typography>
        <Link
          color="inherit"
          component={RouterLink}
          to={`/patient_detail_visit_wise/${doctor.attributes.id}/${doctor.attributes.last_visit_id}`}
          variant="h6"
        >
          <Tooltip title="View Detail" aria-label="View Detail">
            <Fab className={classes.fab1}>
              <VisibilityIcon />
            </Fab>
          </Tooltip>
        </Link>
        {console.log(diagnosis)}
        {diagnosis !== undefined ? (
          <Button
            variant="contained"
            className={classes.button}
            style={{ backgroundColor: "rgb(134, 199, 101)", width: 193 }}
            onClick={() => handlePres(doctor.attributes.id)}
            startIcon={<LocalHospitalIcon />}
          >
            Get Prescription
          </Button>
        ) : null}
        <PrescriptionModal
          onClose={handlePrescriptionClose}
          open={prescriptionModalOpen}
        />
      </div>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.fetchpatient.loading,
    prescriptionRecord: state.fetchpatient.searchPrescription,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSearchPatientByDiagnosisRequest: (patientId, diagnosis) =>
      dispatch(fetchSearchPatientByDiagnosisRequest(patientId, diagnosis)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientCard);
