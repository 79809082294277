import * as actionTypes from "actions";

const INITIAL_STATE = {
  error: null,
  loading: false,
  userData: null,
  message: "",
};

const userProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
        error: "",
      };
    case actionTypes.FETCH_USER_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        userData: null,
        error: action.payload,
      };
    case actionTypes.PROFILE_UPDATE_START:
      return {
        ...state,
        ...{ loading: true, error: null },
      };
    case actionTypes.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    case actionTypes.PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        message: "",
        loading: false,
      };
    default:
      return state;
  }
};

export default userProfileReducer;
