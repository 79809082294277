import * as actionTypes from "actions";
const userInitialState = {
  tests: [],
  loading:false,
  testName:[]
};

const pathologyTestReducer = (state = userInitialState,  action) => {
  switch (action.type) {
    case actionTypes.FETCH_PATHALOGY_TEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_PATHALOGY_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        tests: action.payload,
        error: "",
      };
    case actionTypes.FETCH_PATHALOGY_TEST_FAILURE:
      return {
        ...state,
        loading: false,
        tests: [],
        error: action.payload,
      };
      case actionTypes.FETCH_PATHALOGY_TEST_BY_NAME_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.FETCH_PATHALOGY_TEST_BY_NAME_SUCCESS:
        return {
          ...state,
          loading: false,
          testName:action.payload,
          error: "",
        };
      case actionTypes.FETCH_PATHALOGY_TEST_BY_NAME_FAILURE:
        return {
          ...state,
          loading: false,
          testName:[],
          error: action.payload,
        };
    default:
      return state;
  }
};

export default pathologyTestReducer;
