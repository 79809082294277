import { takeEvery, call, put,delay } from "redux-saga/effects";
import { FETCH_TIMELINE_REQUEST } from '../actions';
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherTimelineSaga() {
  yield takeEvery(FETCH_TIMELINE_REQUEST, timelineSaga);
}

function* timelineSaga(action) {
  try {
    const response = yield call(doctorService.timelineData,action.patientId,action.visitId);
    yield put( actions.fetchTimelineSuccess(response.data) );
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_patient_timelines',
       method:'GET',
     })
    }
    yield put( actions.fetchTimelineFailure(error) );
  }
}


