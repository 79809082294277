import { Link } from "react-router-dom";
import React from "react";
import moment from 'moment'

export const withLink = (to, children) => <Link to={to}>{children}</Link>;

export const ageCalculator = date => {
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const difference = today - birthDate;
  const timeInDay = 86400000;
  if (
    today.getFullYear() === birthDate.getFullYear() ||
    difference < 365 * timeInDay
  ) {
    // this is the number of milli-seconds in a day
    if (difference > timeInDay && difference < 7 * timeInDay) {
      age = Math.floor(parseFloat(difference / timeInDay));
      return `${age} days`;
    }
    if (difference >= 7 * timeInDay && difference < 30 * timeInDay) {
      age = parseFloat(difference / (7 * timeInDay)).toPrecision(3);
      return `${age} weeks`;
    }
    if (difference >= 30 * timeInDay && difference < 366 * timeInDay) {
      age = parseFloat(difference / (30 * timeInDay)).toPrecision(3);
      return `${age} months`;
    } else {
      age = Math.floor(difference / (60 * 60 * 1000));
      return `${age} hours`;
    }
  } else {
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return `${age}`;
  }
};

export const daysArray = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const getDayOfDate = date => {
  const givenDate = new Date(date);
  return daysArray[givenDate.getDay()];
};

export const abbreviatedName = name => {
  if (name.length > 12) {
    const nameArray = name.split(" ");
    const initial = nameArray
      .slice(0, -1)
      .map(name => name[0].toUpperCase())
      .join(".");
    return `${initial}. ${nameArray.slice(-1)}`;
  } else {
    return name;
  }
};

export const patientArrived = [
  { key: 1, label: "Arrived" },
  { key: 0, label: "Not Arrived" },
];

export const convertTimeIntoMiliseconds = (time)=>{
  let requiredTimeInMiliseconds =
  Number(time.split(":")[0]) * 60 * 60 * 1000 +
  Number(time.split(":")[1]) * 60 * 1000;
  return requiredTimeInMiliseconds;
}

export const extendTime = [
  { key: '10', label: "10 mins" },
  { key: '20', label: "20 mins" },
  { key: '30', label: "30 mins" },
  { key: '40', label: "40 mins" },
  { key: '50', label: "50 mins" },
  { key: '60', label: "60 mins" },
  { key: '70', label: "70 mins" },
  { key: '80', label: "80 mins" },
  { key: '90', label: "90 mins" },
];


export const dateChecker = date => {
  const dateToday = new Date();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    date ===
    `${dateToday.getDate()} ${
      months[dateToday.getMonth()]
    }, ${dateToday.getFullYear()}`
  );
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const blockButton = (id,status) => {
  document.getElementById(id).disabled = status;
};

export const startTimer = (duration, display) => {
  var timer = duration,
    minutes,
    seconds;
  setInterval(() => {
    minutes = parseInt(timer / 60, 10);
    seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    display.textContent = minutes + ":" + seconds;

    if (--timer < 0) {
      timer = duration;
    }
  }, 1000);
};

export const checkEndTime =(scheduledEndTime,logOffType)=>{
  if(scheduledEndTime !== null){
    var scheduledEndTimeMss = Number(scheduledEndTime.split(':')[0]) * 60 * 60 * 1000 + Number(scheduledEndTime.split(':')[1]) * 60 * 1000
    }
    let d = new Date();
    let PresentTime= moment(d).format('HH:mm')
    let PresentTimeMss = Number(PresentTime.split(':')[0]) * 60 * 60 * 1000 + Number(PresentTime.split(':')[1]) * 60 * 1000;
    let msg =''
    if(parseInt(scheduledEndTimeMss)> parseInt(PresentTimeMss)){
      msg= logOffType === "pre_offline" ? 'You are Ending Consultation before time' : 'You are Logging Out before time'
      return msg;
    }
}

export  const encodeGetParams = (p) =>
Object.entries(p)
  .map((kv) => kv.map(encodeURIComponent).join("="))
  .join("&");

  export const errorResponsesArray = [
    500,501,502,503,504,505,506,507,508,510,511,400,403,404,405,406,409,415,417,431
  ]

  let signAllowedFormats=["jpeg","png","tiff","jpg"];

export function checkFileExtension(data){
  let responseObj={}
  for (const file of data) {
    let extension = file.name.split(".").pop()
    if(signAllowedFormats.includes(extension)){
      responseObj.isAllowed = true;
      responseObj.message = ""
    }else{
      responseObj.isAllowed = false;
      responseObj.message = `You have uploaded ${extension} file of signature,It is not allowed.Please upload ${signAllowedFormats.join(
        ","
      )} file format`    
    }
  }
  return responseObj
}