import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import "../../PatientDetail/component/Scroll.css";

import CustomButton from "../../../components/CustomButtons/Button";
import { Dialog, Grid, Typography,TextField } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import Slide from "@material-ui/core/Slide";
import { postFeedback } from "../../../actions";
import { connect } from "react-redux";

import avatar from "../../../assets/img/feedback.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const labels = {
  1: "Poor",
  2: "Unsatisfactory",
  3: "Ok",
  4: "Good",
  5: "Excellent",
};


const useStyles = makeStyles((theme) => ({
  rating: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
}));

const FeedbackModal = (props) => {
  const { open, onClose,visitId,postFeedback,ratingData,handleFeedbackCounter } = props;
  const [recordValue, setRecordValue] = useState(ratingData);
  const [msoAssistance, setMsoAssistance] = useState(ratingData);
  const [videoQuality, setVideoQuality] = useState(ratingData);
  const [hover, setHover] = useState(-1);
  const [hovermsoAssistance, setMsoAssistanceHover] = useState(-1);
  const [hoverVideo, setVideoHover] = useState(-1);
  const [remarkValue,setRemarkValue] = useState('')
  const classes = useStyles();

  const handleRecord =(newValue)=>{
    setRecordValue(newValue)
  }

  const handleAssistance =(newValue)=>{
    setMsoAssistance(newValue)
  }

  const handleVideo =(newValue)=>{
    setVideoQuality(newValue)
  }

  const handleRemark =(value)=>{
    setRemarkValue(value)
  }

  const handleSubmit = ()=>{

    const doctor_to_mso_feedback = {
      visit_id :visitId,
      mso_records: recordValue,
      mso_assistance:msoAssistance ,
      video_quality:videoQuality ,
      remark: remarkValue
  }
  console.log(doctor_to_mso_feedback)
  postFeedback(doctor_to_mso_feedback)
  handleFeedbackCounter()
  onClose()
  }

  return (
    <Dialog
      onClose={onClose}
      TransitionComponent={Transition}
      open={open}
      fullWidth={true}
      maxWidth={"md"}
    >
      <div style={{ flexGrow: 1,padding: "17px" }}>
        <div style={{ margin: "0 auto",padding: 12,color: "blue" }}>
          <Typography align="center" gutterBottom variant="h5">
            Feel free to drop us your feedback
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <img src={avatar} alt="feedback" style={{ width: 250,marginTop:50 }} />
          </Grid>

          <Grid item xs={12} sm={8} md={8}>
            <Grid item xs={12} sm={8} md={8}>
              <Typography style={{ fontSize: 19 }}>
                1. The records created by the tele-technician
              </Typography>
              <div className={classes.rating}>
                <Rating
                  name="record"
                  value={recordValue}
                  precision={1}
                  onChange={(event, newValue) => {
                    handleRecord(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                />
                {recordValue !== null && (
                  <Box ml={2}>{labels[hover !== -1 ? hover : recordValue]}</Box>
                )}
              </div>
            </Grid><br/>
            <Grid item xs={12} sm={8} md={8}>
              <Typography style={{ fontSize: 19 }}>
                2. The competence of the tele-technician to assist in
                examination
              </Typography>
              <div className={classes.rating}>
                <Rating
                  name="assistance"
                  value={msoAssistance}
                  precision={1}
                  onChange={(event, newValue) => {
                    handleAssistance(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setMsoAssistanceHover(newHover);
                  }}
                />
                {msoAssistance !== null && (
                  <Box ml={2}>{labels[hovermsoAssistance !== -1 ? hovermsoAssistance : msoAssistance]}</Box>
                )}
              </div>
            </Grid><br/>
            <Grid item xs={12} sm={8} md={8}>
              <Typography style={{ fontSize: 19 }}>
                3. The video-consultation quality (internet connectivity)
              </Typography>
              <div className={classes.rating}>
                <Rating
                  name="video"
                  value={videoQuality}
                  precision={1}
                  onChange={(event, newValue) => {
                    handleVideo(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setVideoHover(newHover);
                  }}
                />
                {videoQuality !== null && (
                  <Box ml={2}>{labels[hoverVideo !== -1 ? hoverVideo : videoQuality]}</Box>
                )}
              </div>
            </Grid><br/>
            <Grid item xs={12} sm={8} md={8}>
              <Typography style={{ fontSize: 19 }}>
                Any Remark
              </Typography>
              <TextField
                id="outlined-multiline-static"
                label="Remark"
                multiline
                value ={remarkValue || '' }
                onChange={(e)=>handleRemark(e.target.value)}
                rows={4}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <CustomButton 
              onClick={handleSubmit}
              style={{
                 backgroundColor:'rgb(50, 123, 175)'
                  }}>Submit</CustomButton>
              <CustomButton onClick={onClose}>Cancel</CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

FeedbackModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    ratingData: state.prescription.initialFeedBackValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postFeedback : (formData)=>dispatch(postFeedback(formData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackModal);
