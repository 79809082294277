import * as actionTypes from "actions";
const userInitialState = {
  exercise: [],
  loading:false,
  exerciseName:[]
};

const exerciseReducer = (state = userInitialState,  action) => {
  switch (action.type) {
    case actionTypes.FETCH_EXERCISE_TEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_EXERCISE_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        exercise: action.payload,
        error: "",
      };
    case actionTypes.FETCH_EXERCISE_TEST_FAILURE:
      return {
        ...state,
        loading: false,
        exercise: [],
        error: action.payload,
      };
      case actionTypes.FETCH_EXERCISE_TEST_BY_NAME_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.FETCH_EXERCISE_TEST_BY_NAME_SUCCESS:
        return {
          ...state,
          loading: false,
          exerciseName:action.payload,
          error: "",
        };
      case actionTypes.FETCH_EXERCISE_TEST_BY_NAME_FAILURE:
        return {
          ...state,
          loading: false,
          exerciseName:[],
          error: action.payload,
        };
    default:
      return state;
  }
};

export default exerciseReducer;
