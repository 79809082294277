import React, { useState,useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, Button, Typography, Link, Grid } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import CardBody from "../Card/CardBody";
import Controls from "../controls/Controls";
import moment from "moment";
import endConsult from "../../assets/img/consultationEnd.jpg";
import { endConsultation } from "../../actions";
import { connect } from "react-redux";
import { extendTime,convertTimeIntoMiliseconds } from "../../utils/helper";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EndconsultationAlert = (props) => {
  const { onClose, open, endConsultation,isCloseModal } = props;
  const classes = useStyles();
  const [timeValue, setTimeValue] = useState(null);
  const [isShowTimeValue, setIsShowTimeValue] = useState(false);
  const [countDownTimerOn, setCountDownTimerOn] = useState(true);

  const directlyEndConsultation = ()=>{
    let endTime = moment().format("YYYY-MM-DD HH:mm:ss");
    const newEndTime = new Date(endTime).toISOString();
    endConsultation(newEndTime, false,'','no');
    localStorage.removeItem('extendTimeCounter');
  }

  useEffect(() => {
    let timer = null
    if(localStorage.getItem("end_time")){
    let currentTimeOfUser = convertTimeIntoMiliseconds(moment().format("HH:mm"))
    let endTimeOfUser = convertTimeIntoMiliseconds(localStorage.getItem("end_time"))
    let thresholdTime =  endTimeOfUser-currentTimeOfUser
    let timerTime = thresholdTime - 1000 * 60 
    let gracePeriodCounter = parseInt(localStorage.getItem("gracePeriodCounter"))
    let extendTimeCounter = parseInt(localStorage.getItem("extendTimeCounter"))
    setCountDownTimerOn(true)
    if (countDownTimerOn && endTimeOfUser > currentTimeOfUser) {
      timer = setTimeout(() =>{
        let graceDateObj = moment().add(parseInt(15), "m").toDate(); 
        let graceTime = moment(graceDateObj).format("YYYY-MM-DD HH:mm:ss");
        const newTime = new Date(graceTime).toISOString();
        if(extendTimeCounter === 0 && gracePeriodCounter === 0){
        endConsultation(newTime, true,graceDateObj,'yes');
        }else{
          directlyEndConsultation()
        }
       },timerTime)
       
    } else if (!countDownTimerOn) {
      clearTimeout(timer);
    }
  }
    return () => clearTimeout(timer);
  }, [countDownTimerOn,open]);



  const handleCloseDialogBox = () => {
    console.log(timeValue);
    if (isShowTimeValue && !timeValue) {
      alert("Please select time to extend consultation");
      return false;
    }
    setTimeValue(null);
    setIsShowTimeValue(false);
    if (isShowTimeValue && timeValue) {
      let threshHoldValue = localStorage.getItem("threshold_time");
      let time = parseInt(timeValue) + parseInt(threshHoldValue);
      let newDateObj = moment().add(parseInt(time), "m").toDate();      
      let desiredTime = moment(newDateObj).format("YYYY-MM-DD HH:mm:ss");
      const newTime = new Date(desiredTime).toISOString();
      setCountDownTimerOn(false)
      endConsultation(newTime, true,newDateObj,'no');
    } else if (!isShowTimeValue && !timeValue) {
      directlyEndConsultation()
    }
  };

  useEffect(()=>{
   if(isCloseModal){
    onClose();
   }
  },[isCloseModal])

  const extendConsultationHandler = (value) => {
    setIsShowTimeValue(value);
  };

  const ExtendTimeComponent = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Controls.Select
          name="extendedTime"
          label="Select Time to extend consulation"
          value={timeValue || ""}
          onChange={(e) => setTimeValue(e.target.value)}
          options={extendTime.map((e) => ({
            title: e.label,
            value: e.key,
            id: e.key,
          }))}
          style={{ width: 315, marginBottom: "3%" }}
        />
        <Typography
          style={{ textAlign: "center", fontSize: 16, fontWeight: 400 }}
        >
          Don't want to extend consultation time ?
          <Link onClick={() => extendConsultationHandler(false)}>Go Back</Link>
        </Typography>
      </div>
    );
  };

  return (
    <Dialog TransitionComponent={Transition} open={open}>   
      <Grid container>
        <Grid container item xs={12} sm={12} md={12} justify="center">
          <img
            src={endConsult}
            style={{ width: "70%", marginTop: "1%" }}
            alt="logo"
          />
        </Grid>
        <Grid container item xs={12} sm={12} md={12} justify="center">
          <Typography
            align="center"
            gutterBottom
            variant="h5"
            style={{ marginTop: "4%", fontSize: "21px" }}
          >
            You have reached maximum time limit of Consultation 
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} justify="center">
          <CardBody>
            <Typography
              variant="subtitle2"
              style={{ textAlign: "center", marginTop: "-2%" }}
            >
              Thank you for providing consultation today.{" "}
            </Typography>
            <br />
            {isShowTimeValue ? (
              <ExtendTimeComponent />
            ) : (
              <Typography
                style={{ textAlign: "center", fontSize: 18, fontWeight: 500 }}
              >
                If you want to extend consultation then{" "}
                <Link onClick={() => extendConsultationHandler(true)}>
                  Click Here
                </Link>
              </Typography>
            )}
          </CardBody>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} justify="center">
          <Button
            color="secondary"
            style={{
              backgroundColor: "#efd2d2",
              marginBottom: "2%",
            }}
            onClick={() => handleCloseDialogBox()}
          >
            {isShowTimeValue ? "Submit" : "Ok"}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

EndconsultationAlert.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    isCloseModal:state.startConsultation.isCloseModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    endConsultation: (endtime, check,newDate,isGrace) =>
      dispatch(endConsultation(endtime, check,newDate,isGrace)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EndconsultationAlert);
