export const FETCH_CALENDER_COUNT_REQUEST = 'FETCH_CALENDER_COUNT_REQUEST';
export const FETCH_CALENDER_COUNT_SUCCESS = 'FETCH_CALENDER_COUNT_SUCCESS';
export const FETCH_CALENDER_COUNT_FAILURE = 'FETCH_CALENDER_COUNT_FAILURE';

export const FETCH_CALENDER_APPOINTMENTS_START = 'FETCH_CALENDER_APPOINTMENTS_START';
export const FETCH_CALENDER_APPOINTMENTS_SUCCESS = 'FETCH_CALENDER_APPOINTMENTS_SUCCESS';
export const FETCH_CALENDER_APPOINTMENTS_FAILURE = 'FETCH_CALENDER_APPOINTMENTS_FAILURE';
export const FETCH_CALENDER_APPOINTMENTS ='FETCH_CALENDER_APPOINTMENTS';

export const fetchCalenderCountRequest = (month) => {
  return {
      type: FETCH_CALENDER_COUNT_REQUEST,
      month:month,
  }
}

export const fetchCalenderCountSuccess = count => {
  return {
      type: FETCH_CALENDER_COUNT_SUCCESS,
      payload: count
  }
}

export const fetchCalenderCountFailure = error => {
  return {
      type: FETCH_CALENDER_COUNT_FAILURE,
      payload: error
  }
}

export const fetchCalenderAppointment = (date) => {
  return {
      type: 'FETCH_CALENDER_APPOINTMENTS',
      date:date,

  }
};

export const fetchCalenderAppointmentStart = () => {
  return {
      type: FETCH_CALENDER_APPOINTMENTS_START,
  }
}

export const fetchCalenderAppointmentSuccess = appointments => {
  return {
      type: FETCH_CALENDER_APPOINTMENTS_SUCCESS,
      payload: appointments
  }
}

export const fetchCalenderAppointmentFailure = error => {
  return {
      type: FETCH_CALENDER_APPOINTMENTS_FAILURE,
      payload: error
  }
}