import React,{useEffect} from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useTable from "../../../../components/Table/useTable";
import { TableBody, TableRow, TableCell, Grid } from "@material-ui/core";
import { connect } from 'react-redux'
import { fetchTodaySchedule } from '../../../../actions'
import Spinner from '../../../../components/Spinner/Spinner'
const styles = (theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 7,
    marginTop: -30,
    float: "right",
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles(styles);

const headCells = [
  { id: "Day Of Week", label: "Day Of Week" },
  { id: "Slot From", label: "Slot From" },
  { id: "Slot To", label: "Slot To" },

];

const Tuesday = (props) => {
  const classes = useStyles();
  const { todaySlots,fetchTodaySchedule  } = props;
  const { TblContainer, TblHead } = useTable(todaySlots, headCells);

  useEffect(()=>{

    const encodeGetParams = (p) =>
    Object.entries(p)
      .map((kv) => kv.map(encodeURIComponent).join("="))
      .join("&");

    let slotParam ={
      id:localStorage.getItem("userId"),
      day_of_week:2
    }
    fetchTodaySchedule(encodeGetParams(slotParam))
  },[])

  return (
    <div className={classes.root}>
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.results}>
            <TblContainer>
              <TblHead  style={{ backgroundColor: 'rgb(76, 75, 70)' }}/>
              <TableBody>
                {todaySlots.map(item=>(
                <StyledTableRow key={item.id}>
                  <TableCell>{item.attributes.day_of_week.value}</TableCell>
                  <TableCell>{item.attributes.slot_from}</TableCell>
                  <TableCell>{item.attributes.slot_to}</TableCell>
                </StyledTableRow>
                ))}
              </TableBody>
            </TblContainer>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state =>{
  return{
    todaySlots : state.startConsultation.todaySchedule
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTodaySchedule:(slotParam)=>dispatch(fetchTodaySchedule(slotParam))
  };
};

export default connect(mapStateToProps,mapDispatchToProps) (Tuesday);
