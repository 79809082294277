import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useTable from "../../../../components/UseTable/useTable";
import { TableBody, TableRow, TableCell, Grid } from "@material-ui/core";
// import CardBody from '../../../components/Card/CardBody'
// import Card from '../../../components/Card/Card'

const styles = (theme) => ({});
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      // backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles(styles);

const headCells = [
  { id: "Drug", label: "Drug" },
  { id: "Dose", label: "Dose" },
  { id: "Frequency", label: "Frequency" },
  { id: "Intake", label: "Intake" },
  { id: "Duration(Days)", label: "Duration(Days)" },
];

const PrescriptionData = (props) => {
  const classes = useStyles();
  const { prescriptionData } = props;
  const { TblContainer, TblHead } = useTable(prescriptionData, headCells);

  return (
    <div>
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
          {/* <Card style={{ marginTop:1 }}> */}
          {/* <CardBody> */}
          {prescriptionData !== undefined ? (
            <div>
              <TblContainer
                style={{
                  borderBottom: "1px solid #d0c2c2",
                  borderCollapse: "unset",
                }}
              >
                <TblHead style={{ backgroundColor: "white" }} />
                <TableBody>
                  {prescriptionData.attributes.prescription_drugs.data.map(
                    (item) => (
                      <StyledTableRow key={item.attributes.id}>
                        <TableCell style={{ wordBreak:'break-word' }}>
                        {item.attributes.drug_name}
                        <br/>
                        {item.attributes.generic_name ? `(${item.attributes.generic_name})` : ''}
                        </TableCell>
                        <TableCell>
                          {item.attributes.dose}
                          <div
                            style={{
                              marginLeft: -7,
                              fontWeight: 600,
                              fontSize: 9,
                            }}
                          >
                            {item.attributes.drug_type}
                          </div>
                        </TableCell>
                        <TableCell>{item.attributes.frequency.title}</TableCell>
                        <TableCell>
                          {item.attributes.intake_pattern.title}
                          <div style={{ fontWeight: 400 }}>
                            {item.attributes.instruction}
                          </div>
                        </TableCell>
                        <TableCell>{item.attributes.duration}</TableCell>
                      </StyledTableRow>
                    )
                  )}
                </TableBody>
              </TblContainer>
            </div>
          ) : null}
          {/* </CardBody> */}
          {/* </Card> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default PrescriptionData;
