export const SEARCH_DRUG_IN_FORMULARY = "SEARCH_DRUG_IN_FORMULARY";
export const SEARCH_DRUG_IN_FORMULARY_START = "SEARCH_DRUG_IN_FORMULARY_START";
export const SEARCH_DRUG_IN_FORMULARY_SUCCESS =
  "SEARCH_DRUG_IN_FORMULARY_SUCCESS";
// export const SEARCH_DRUG_IN_FORMULARY_EMPTY = 'SEARCH_DRUG_IN_FORMULARY_EMPTY';
export const SEARCH_DRUG_IN_FORMULARY_FAIL = "SEARCH_DRUG_IN_FORMULARY_FAIL";

export const ADD_PRESCRIPTION = "ADD_PRESCRIPTION";
export const ADD_PRESCRIPTION_START = "ADD_PRESCRIPTION_START";
export const ADD_PRESCRIPTION_SUCCESS = "ADD_PRESCRIPTION_SUCCESS";
export const ADD_PRESCRIPTION_FAIL = "ADD_PRESCRIPTION_FAIL";

export const POST_PRESCRIPTION = "POST_PRESCRIPTION";
export const POST_PRESCRIPTION_START = "POST_PRESCRIPTION_START";
export const POST_PRESCRIPTION_SUCCESS = "POST_PRESCRIPTION_SUCCESS";
export const POST_PRESCRIPTION_FAIL = "POST_PRESCRIPTION_FAIL";

export const SEND_TO_MSO_PRESCRIPTION = "SEND_TO_MSO_PRESCRIPTION";
export const SEND_TO_MSO_PRESCRIPTION_START = "SEND_TO_MSO_PRESCRIPTION_START";
export const SEND_TO_MSO_PRESCRIPTION_SUCCESS =
  "SEND_TO_MSO_PRESCRIPTION_SUCCESS";
export const SEND_TO_MSO_PRESCRIPTION_FAIL = "SEND_TO_MSO_PRESCRIPTION_FAIL";

export const FETCH_PRESCRIPTION_BY_ID = "FETCH_PRESCRIPTION_BY_ID";
export const FETCH_PRESCRIPTION_BY_ID_SUCCESS =
  "FETCH_PRESCRIPTION_BY_ID_SUCCESS";
export const FETCH_PRESCRIPTION_BY_ID_FAIL = "FETCH_PRESCRIPTION_BY_ID_FAIL";

export const UPDATE_PRESCRIPTION = "UPDATE_PRESCRIPTION";
export const UPDATE_PRESCRIPTION_START = "UPDATE_PRESCRIPTION_START";
export const UPDATE_PRESCRIPTION_SUCCESS = "UPDATE_PRESCRIPTION_SUCCESS";
export const UPDATE_PRESCRIPTION_FAIL = "UPDATE_PRESCRIPTION_FAIL";

export const POST_FEEDBACK = "POST_FEEDBACK";
export const POST_FEEDBACK_START = "POST_FEEDBACK_START";
export const POST_FEEDBACK_SUCCESS = "POST_FEEDBACK_SUCCESS";
export const POST_FEEDBACK_FAIL = "POST_FEEDBACK_FAIL";

export const FETCH_EMPTY_TITLE_SUCCESS = "FETCH_EMPTY_TITLE_SUCCESS";
export const FETCH_EMPTY_TITLE_FAILURE = "FETCH_EMPTY_TITLE_FAILURE";

export const IS_SHOW_FEEDBACK_MODAL_SUCCESS = "IS_SHOW_FEEDBACK_MODAL_SUCCESS";
export const IS_SHOW_FEEDBACK_MODAL_FAILURE = "IS_SHOW_FEEDBACK_MODAL_FAILURE";

export const POST_FEEDBACK_INITIAL = "POST_FEEDBACK_INITIAL";
export const EMPTY_FEEDBACK_INITIAL = "EMPTY_FEEDBACK_INITIAL";

export const IS_SHOW_PRESCRIPTION_TO_SENT_SUCCESS =
  "IS_SHOW_PRESCRIPTION_TO_SENT_SUCCESS";
export const IS_SHOW_PRESCRIPTION_TO_SENT_FAILURE =
  "IS_SHOW_PRESCRIPTION_TO_SENT_FAILURE";

  export const IS_REFERRED_CASE = "IS_REFERRED_CASE";

  export const LAST_VISIT_PRESCRIPTION = "LAST_VISIT_PRESCRIPTION";
export const LAST_VISIT_PRESCRIPTION_SUCCESS =
  "LAST_VISIT_PRESCRIPTION_SUCCESS";
export const LAST_VISIT_PRESCRIPTION_FAIL = "LAST_VISIT_PRESCRIPTION_FAIL";

export const EMPTY_LAST_VISIT_PRESCRIPTION = "EMPTY_LAST_VISIT_PRESCRIPTION";

export const FETCH_MSO_DETAILS = "FETCH_MSO_DETAILS";
export const FETCH_MSO_DETAILS_SUCCESS = "FETCH_MSO_DETAILS_SUCCESS";
export const FETCH_MSO_DETAILS_FAIL = "FETCH_MSO_DETAILS_FAIL";
export const RESET_MSO_DETAILS = "RESET_MSO_DETAILS";

export const IS_UPDATE_PRESCRIPTION = "IS_UPDATE_PRESCRIPTION";
export const EMPTY_LAST_PRESCRIPTION = "EMPTY_LAST_PRESCRIPTION";
export const RESET_PRESCRIPTION_DATA = "RESET_PRESCRIPTION_DATA";

export const MSO_UNAVAILABLE = "MSO_UNAVAILABLE";
export const MSO_AVAILABLE = "MSO_AVAILABLE";

export const msoUnavailable = () => {
  return {
    type: "MSO_UNAVAILABLE",
  };
};

export const msoAvailable = () => {
  return {
    type: "MSO_AVAILABLE",
  };
};

export const isUpdatePrescription = (isUpdatePres) => {
  return {
    type: "IS_UPDATE_PRESCRIPTION",
    isUpdatePres:isUpdatePres
  };
};


export const resetMsoDetail = () => {
  return {
    type: "RESET_MSO_DETAILS",
  };
};

export const fetchMsoDetail = (msoId) => {
  return {
    type: "FETCH_MSO_DETAILS",
    msoId: msoId,
  };
};

export const fetchMsoDetailSuccess = (data) => {
  return {
    type: "FETCH_MSO_DETAILS_SUCCESS",
    payload: data,
  };
};

export const fetchMsoDetailFail = (error) => {
  return {
    type: "FETCH_MSO_DETAILS_FAIL",
    error: error,
  };
};

export const emptyLastVisitPrescription = () => {
  return {
    type: "EMPTY_LAST_VISIT_PRESCRIPTION",
  };
};

  export const fetchLastVisitPrescription = (patientId, visitId) => {
    return {
      type: "LAST_VISIT_PRESCRIPTION",
      patientId: patientId,
      visitId: visitId,
    };
  };
  
  export const fetchLastVisitPrescriptionSuccess = (data,message) => {
    return {
      type: "LAST_VISIT_PRESCRIPTION_SUCCESS",
      payload: data,
      message:message
    };
  };
  
  export const fetchLastVisitPrescriptionFail = (error) => {
    return {
      type: "LAST_VISIT_PRESCRIPTION_FAIL",
      error: error,
    };
  };

  export const isReferredCase = (data) => {
    return {
      type: "IS_REFERRED_CASE",
      payload: data,
    };
  };

export const fetchSuggestionTitleEmptySuccess = (data) => {
  return {
    type: "FETCH_EMPTY_TITLE_SUCCESS",
    payload: data,
  };
};

export const isShowPrescriptionToSendSuccess = (data) => {
  return {
    type: "IS_SHOW_PRESCRIPTION_TO_SENT_SUCCESS",
    payload: data,
  };
};

export const isShowPrescriptionToSendFail = (error) => {
  return {
    type: "IS_SHOW_PRESCRIPTION_TO_SENT_FAILURE",
    error: error,
  };
};

export const isShowFeedbackModalSuccess = (data) => {
  return {
    type: "IS_SHOW_FEEDBACK_MODAL_SUCCESS",
    payload: data,
  };
};

export const postInitialFeedback = (data) => {
  return {
    type: "POST_FEEDBACK_INITIAL",
    payload: data,
  };
};

export const emptyInitialFeedback = () => {
  return {
    type: "EMPTY_FEEDBACK_INITIAL",
  };
};

export const isShowFeedbackModalFail = (error) => {
  return {
    type: "IS_SHOW_FEEDBACK_MODAL_FAILURE",
    error: error,
  };
};

export const updatePrescription = (formData, id,visitId) => {
  return {
    type: "UPDATE_PRESCRIPTION",
    formData: formData,
    id: id,
    visitId:visitId
  };
};

export const updatePrescriptionStart = () => {
  return {
    type: "UPDATE_PRESCRIPTION_START",
  };
};

export const updatePrescriptionSuccess = () => {
  return {
    type: "UPDATE_PRESCRIPTION_SUCCESS"
  };
};

export const updatePrescriptionFail = (error) => {
  return {
    type: "UPDATE_PRESCRIPTION_FAIL",
    error: error,
  };
};

export const sendToMsoPrescription = (prescription, pId) => {
  return {
    type: "SEND_TO_MSO_PRESCRIPTION",
    prescription: prescription,
    pId: pId,
  };
};

export const sendToMsoPrescriptionStart = () => {
  return {
    type: "SEND_TO_MSO_PRESCRIPTION_START",
  };
};

export const sendToMsoPrescriptionSuccess = (data, status) => {
  return {
    type: "SEND_TO_MSO_PRESCRIPTION_SUCCESS",
    payload: data,
    status: status,
  };
};

export const sendToMsoPrescriptionFail = (error) => {
  return {
    type: "SEND_TO_MSO_PRESCRIPTION_FAIL",
    error: error,
  };
};

export const fetchPrescriptionById = (patientId, visitId) => {
  return {
    type: "FETCH_PRESCRIPTION_BY_ID",
    patientId: patientId,
    visitId: visitId,
  };
};

export const fetchPrescriptionByIdSuccess = (data) => {
  return {
    type: "FETCH_PRESCRIPTION_BY_ID_SUCCESS",
    payload: data,
  };
};

export const fetchPrescriptionByIdFail = (error) => {
  return {
    type: "FETCH_PRESCRIPTION_BY_ID_FAIL",
    error: error,
  };
};

export const searchDrugInFormulary = (drug) => {
  return {
    type: "SEARCH_DRUG_IN_FORMULARY",
    drug: drug,
  };
};

export const searchDrugInFormularyStart = () => {
  return {
    type: "SEARCH_DRUG_IN_FORMULARY_START",
  };
};

export const searchDrugInFormularySuccess = (data) => {
  return {
    type: "SEARCH_DRUG_IN_FORMULARY_SUCCESS",
    payload: data,
  };
};

export const searchDrugInFormularyFail = (error) => {
  return {
    type: "SEARCH_DRUG_IN_FORMULARY_FAIL",
    error: error,
  };
};

export const addPrescription = (drug) => {
  return {
    type: "ADD_PRESCRIPTION",
    drug: drug,
  };
};

export const addPrescriptionStart = () => {
  return {
    type: "ADD_PRESCRIPTION_START",
  };
};

export const addPrescriptionSuccess = (data) => {
  return {
    type: "ADD_PRESCRIPTION_SUCCESS",
    payload: data,
  };
};

export const addPrescriptionFail = (error) => {
  return {
    type: "ADD_PRESCRIPTION_FAIL",
    error: error,
  };
};

export const postPrescription = (formData) => {
  return {
    type: "POST_PRESCRIPTION",
    formData: formData,
  };
};

export const postPrescriptionStart = () => {
  return {
    type: "POST_PRESCRIPTION_START",
  };
};

export const postPrescriptionSuccess = (data, check) => {
  return {
    type: "POST_PRESCRIPTION_SUCCESS",
    payload: data,
    // check:check
  };
};

export const postPrescriptionFail = (error) => {
  return {
    type: "POST_PRESCRIPTION_FAIL",
    error: error,
  };
};

export const postFeedback = (formData) => {
  return {
    type: "POST_FEEDBACK",
    formData: formData,
  };
};

export const postFeedbackStart = () => {
  return {
    type: "POST_FEEDBACK_START",
  };
};

export const postFeedbackSuccess = (data, check) => {
  return {
    type: "POST_FEEDBACK_SUCCESS",
    payload: data,
    check: check,
  };
};

export const postFeedbackFail = (error) => {
  return {
    type: "POST_FEEDBACK_FAIL",
    error: error,
  };
};

export const emptyLastPrescription = () => {
  return {
    type: "EMPTY_LAST_PRESCRIPTION",
  };
};

export const resetPrescription = () => {
  return {
    type: "RESET_PRESCRIPTION_DATA",
  };
};
