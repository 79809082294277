export const FETCH_TIMELINE_DATA_REQUEST = 'FETCH_TIMELINE_DATA_REQUEST';

export const FETCH_TIMELINE_VITAL_SUCCESS = 'FETCH_TIMELINE_VITAL_SUCCESS';
export const FETCH_TIMELINE_VITAL_FAILURE = 'FETCH_TIMELINE_VITAL_FAILURE';

export const FETCH_TIMELINE_MEDICAL_SUCCESS = 'FETCH_TIMELINE_MEDICAL_SUCCESS';
export const FETCH_TIMELINE_MEDICAL_FAILURE = 'FETCH_TIMELINE_MEDICAL_FAILURE';

export const FETCH_TIMELINE_PRESCRIPTION_SUCCESS = 'FETCH_TIMELINE_PRESCRIPTION_SUCCESS';
export const FETCH_TIMELINE_PRESCRIPTION_FAILURE = 'FETCH_TIMELINE_PRESCRIPTION_FAILURE';

export const TIMELINE_DATA_EMPTY_REQUEST = 'TIMELINE_DATA_EMPTY_REQUEST';


export const fetchTimelineDataRequest = (patientId,visitId) => {
  return {
      type: FETCH_TIMELINE_DATA_REQUEST,
      patientId:patientId,
      visitId:visitId
  }
}

export const fetchTimelineVitalSuccess = data => {
  return {
      type: FETCH_TIMELINE_VITAL_SUCCESS,
      payload: data
  }
}

export const fetchTimelineVitalFailure = error => {
  return {
      type: FETCH_TIMELINE_VITAL_FAILURE,
      payload: error
  }
}

export const fetchTimelineMedicalSuccess = data => {
  return {
      type: FETCH_TIMELINE_MEDICAL_SUCCESS,
      payload: data
  }
}

export const fetchTimelineMedicalFailure = error => {
  return {
      type: FETCH_TIMELINE_MEDICAL_FAILURE,
      payload: error
  }
}

export const fetchTimelinePrescriptionSuccess = data => {
  return {
      type: FETCH_TIMELINE_PRESCRIPTION_SUCCESS,
      payload: data
  }
}

export const fetchTimelinePrescriptionFailure = error => {
  return {
      type: FETCH_TIMELINE_PRESCRIPTION_FAILURE,
      payload: error
  }
}

export const timelineDataEmpty = () => {
  return {
      type: 'TIMELINE_DATA_EMPTY_REQUEST',
  }
};
