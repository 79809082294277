export const SET_FOLLOW_UP_VALUES = 'SET_FOLLOW_UP_VALUES';
export const SET_FOLLOW_UP = 'SET_FOLLOW_UP';
export const SET_FOLLOW_UP_TYPE = 'SET_FOLLOW_UP_TYPE';
export const SET_FOLLOW_UP_FOR = 'SET_FOLLOW_UP_FOR';
export const SET_FOLLOW_UP_VALUES_EMPTY = 'SET_FOLLOW_UP_VALUES_EMPTY';
export const SET_FOLLOW_UP_VALUES_FAILURE = 'SET_FOLLOW_UP_VALUES_FAILURE';


export const setFollowUpValuesEmpty = (follow) => {
  return {
      type: SET_FOLLOW_UP_VALUES_EMPTY,
  }
}

export const setFollowUpValues = (follow) => {
  return {
      type: SET_FOLLOW_UP_VALUES,
      follow:follow,
  }
}

export const setFollowUp = data => {
  return {
      type: SET_FOLLOW_UP,
      payload: data
  }
}
export const setFollowUpType = data => {
  return {
      type: SET_FOLLOW_UP_TYPE,
      payload: data
  }
}
export const setFollowUpFor = data => {
  return {
      type: SET_FOLLOW_UP_FOR,
      payload: data
  }
}

export const setFollowUpValuesFailure = error => {
  return {
      type: SET_FOLLOW_UP_VALUES_FAILURE,
      payload: error
  }
}
