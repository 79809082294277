import React from "react";
import { Grid } from "@material-ui/core";
import CustomButton from "../../components/CustomButtons/Button";
import PropTypes from "prop-types";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import Leave from "../Setting/Leave";
import { Dialog, Typography } from "@material-ui/core";
import Schedule from "./Schedule/Schedule";
import Slide from "@material-ui/core/Slide";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AddAlarmIcon from "@material-ui/icons/AddAlarm";
import ScheduleIcon from "@material-ui/icons/Schedule";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Reminder from "./Reminder/Reminder";
import UserProfile from "./UserProfile/UserProfile";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Setting = (props) => {
  const { onClose, open } = props;
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      TransitionComponent={Transition}
      open={open}
      fullWidth={true}
      maxWidth={"md"}
    >
      <div className={classes.root1}>
        {/* <div className={classes.header}>
          <Typography
            align="center"
            gutterBottom
            variant="h5"
            style={{ color: "blue" }}
          >
            Settings
          </Typography>
        </div> */}
        <DialogTitle
          style={{ color: "blue", textAlign: "center" }}
          id="customized-dialog-title"
          onClose={onClose}
        >
          Settings
        </DialogTitle>
        <CustomTabs
          headerColor="success"
          style={{ width: 460, padding: 2 }}
          tabs={[
            {
              tabName: "Schedule",
              tabIcon: ScheduleIcon,
              tabContent: (
                <React.Fragment>
                  <div>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Schedule />
                      </Grid>
                    </Grid>
                  </div>
                </React.Fragment>
              ),
            },
            {
              tabName: "Leave",
              tabIcon: CalendarTodayIcon,
              tabContent: (
                <React.Fragment>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <Leave />
                    </Grid>
                  </Grid>
                </React.Fragment>
              ),
            },
            {
              tabName: "Reminder",
              tabIcon: AddAlarmIcon,
              tabContent: (
                <React.Fragment>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <Reminder />
                    </Grid>
                  </Grid>
                </React.Fragment>
              ),
            },
            {
              tabName: "Profile",
              tabIcon: AccountBoxIcon,
              tabContent: (
                <React.Fragment>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <UserProfile onClose={onClose} />
                    </Grid>
                  </Grid>
                </React.Fragment>
              ),
            },
          ]}
        />
        <CustomButton style={{ width: 128, float: "right" }} onClick={onClose}>
          Cancel
        </CustomButton>
      </div>
    </Dialog>
  );
};

Setting.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default Setting;
