import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CustomTabs from "../../../../components/CustomTabs/CustomTabs";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Calender from "../../../Dashboard/components/calender/Calender";
import SearchIcon from '@material-ui/icons/Search';
import SearchPatient from '../SearchPatient/SearchPatient'

const styles = (theme) => ({
  root: {
  },
});
const useStyles = makeStyles(styles);

const CalendarTab = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ marginTop: "36px" }}>
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CustomTabs
          style={{ width: 305,padding:0 }}
            headerColor="primary"
            tabs={[
              {
                tabName: "Calendar",
                tabIcon: CalendarTodayIcon,
                tabContent: (
                  <React.Fragment>
                    <div>
                      <Calender />
                    </div>
                  </React.Fragment>
                ),
              },
              {
                tabName: "Search Patient",
                tabIcon: SearchIcon,
                tabContent: (
                  <React.Fragment>
                    <div>
                      <SearchPatient />
                    </div>
                  </React.Fragment>
                ),
              },
            ]}
          />
        </Grid>
      </Grid>
    </div>
  );
};


export default CalendarTab;
