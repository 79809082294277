import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Typography, Grid } from "@material-ui/core";

import CardBody from "../../../../components/Card/CardBody";

const styles = (theme) => ({});

const useStyles = makeStyles(styles);

const ExerciseNotesData = (props) => {
  const classes = useStyles();
  const { exerciseNotes } = props;

  const [exerciseNotesData, setExerciseNotesData] = useState("");
  const [exerciseNotesDataNotInList, setExerciseNotesDataNotInList] = useState(
    ""
  );

  useEffect(() => {
    var exercise_notes = "";
    var exercise_notes_not_in_list = "";
    if (exerciseNotes !== undefined) {
      if (exerciseNotes.attributes.exercise_note === null) {
        console.log("");
      } else if (
        exerciseNotes.attributes.exercise_note.hasOwnProperty("exercise_data")
      ) {
        for (
          let i = 0;
          i < exerciseNotes.attributes.exercise_note.exercise_data.length;
          i++
        ) {
          exercise_notes =
            exercise_notes +
            "," +
            exerciseNotes.attributes.exercise_note.exercise_data[i].name;
        }
        exercise_notes = exercise_notes.substring(1);
        // console.log(exercise_notes);
        setExerciseNotesData(exercise_notes);
      }
      if (exerciseNotes.attributes.exercise_note === null) {
        console.log("");
      } else if (
        exerciseNotes.attributes.exercise_note.hasOwnProperty("exercises")
      ) {
        for (
          let i = 0;
          i < exerciseNotes.attributes.exercise_note.exercises.length;
          i++
        ) {
          exercise_notes_not_in_list =
            exercise_notes_not_in_list +
            "," +
            exerciseNotes.attributes.exercise_note.exercises[i];
        }
        exercise_notes_not_in_list = exercise_notes_not_in_list.substring(1);
        // console.log(exercise_notes_not_in_list);
        setExerciseNotesDataNotInList(exercise_notes_not_in_list);
      }
    }
  }, [exerciseNotes]);

  return (
    <div>
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
          {/* <Card style={{ marginTop:1 }}> */}
          <CardBody style={{ marginLeft: -11 }}>
            {exerciseNotes !== undefined ? (
              <div>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography style={{ marginLeft: -7, fontSize: 13 }}>
                      {exerciseNotesData}
                      {exerciseNotesDataNotInList !== "" ? (
                        <span>,{exerciseNotesDataNotInList}</span>
                      ) : null}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            ) : null}
          </CardBody>
          {/* </Card> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default ExerciseNotesData;
