import React from "react";
import { Grid } from "@material-ui/core";

import CardBody from "../../../components/Card/CardBody";
import Button from "../../../components/CustomButtons/Button";
import PropTypes from "prop-types";
import AppointmentTable from "./AppointmentDataTable";

import { Dialog, Typography } from "@material-ui/core";
import { leaveAdd,fetchLeaveRequest } from '../../../actions'
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Spinner from "../../../components/Spinner/Spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppointmentData = (props) => {
  const { onClose, open, appointmentData,leaveData,leaveAdd,fetchLeaveRequest,loading } = props;
  const classes = useStyles();
  // console.log(leaveData)

  const applyForLeave =()=>{
    console.log(leaveData)
    leaveAdd(leaveData,'addleave')
    fetchLeaveRequest()
    onClose()

  }

  return (
    <Dialog
      onClose={onClose}
      TransitionComponent={Transition}
      open={open}
      // fullWidth={true}
      // maxWidth={"md"}
    >
      <div className={classes.root1}>
        <div className={classes.header}>
          <Typography
            align="center"
            gutterBottom
            variant="h5"
            style={{ color: "blue" }}
          >
            Appointment List
          </Typography>
        </div>
        <CardBody>
          {loading ? <Spinner/>:
          <Grid item xs={12} sm={12} md={12}>
            {appointmentData.length !== 0 ? (
              <AppointmentTable />
            ) : (
              <Typography style={{ margin:'6px 0 38px 25px' }}>No Appointment For Today</Typography>
            )}
          </Grid>}
          <Grid item xs={12} sm={12} md={12}>
            <Button style={{ width: 128, float: "right" }} onClick={onClose}>
              Cancel
            </Button>
            <Button
              style={{
                width: 128,
                float: "right",
                backgroundColor: "rgb(50, 123, 175)",
              }}
              id="addleave"
              onClick={applyForLeave}
            >
              Apply For Leave
            </Button>
          </Grid>
        </CardBody>
      </div>
    </Dialog>
  );
};

AppointmentData.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    appointmentData: state.leave.appointmentData,
    loading: state.leave.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    leaveAdd: (leaveData,id) => dispatch(leaveAdd(leaveData,id)),
    fetchLeaveRequest :()=>dispatch(fetchLeaveRequest()),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentData);
