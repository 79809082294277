import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timelinePlugin from '@fullcalendar/timeline';
import CardBody from '../../../../components/Card/CardBody'
import CardHeader from '../../../../components/Card/CardHeader'
import CustomCard from '../../../../components/Card/Card'
import AppointmentList from './AppointmentList'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  colors,
  useTheme,
  useMediaQuery,
  Grid
  
} from '@material-ui/core';
import { fetchCalenderCountRequest, fetchCalenderAppointment } from '../../../../actions'
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';

import { Page } from 'components';
import Header from './Header'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: theme.spacing(3),
    '& .fc-unthemed td': {
      borderColor: '#e4cfcf',


    },
    '& .fc-unthemed td .fc-today': {
      borderColor: '#a69225',
      backgroundColor: '#c5d472',
      color: 'black',
      fontSize: '15px'


    },
    '& .fc-widget-header': {
      backgroundColor: colors.grey[50]
    },
    '& .fc-axis': {
      ...theme.typography.body2
    },
    '& .fc-list-item-time': {
      ...theme.typography.body2
    },
    '& .fc-list-item-title': {
      ...theme.typography.body1
    },
    '& .fc-list-heading-main': {
      ...theme.typography.h6
    },
    '& .fc-list-heading-alt': {
      ...theme.typography.h6
    },
    '& .fc th': {
      borderColor: theme.palette.divider
    },
    '& .fc-day-header': {
      ...theme.typography.subtitle2,
      fontWeight: 500,
      color: '#387794',
      padding: theme.spacing(1),
      backgroundColor: colors.grey[50]
    },
    '& .fc-day-top': {
      ...theme.typography.body2
    },
    '& .fc-highlight': {
      backgroundColor: colors.blueGrey[50]
    },
    '& .fc-event': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderWidth: 2,
      opacity: 0.9,
      '& .fc-time': {
        ...theme.typography.h6,
        color: 'inherit'
      },
      '& .fc-title': {
        ...theme.typography.body1,
        color: 'inherit'
      }
    },
    '& .fc-list-empty': {
      ...theme.typography.subtitle1
    }
  },
  card: {
    marginTop: theme.spacing(3)
  }
}));

const FullCalender = (props) => {
  const { fetchCalenderCountRequest,events,fetchCalenderAppointment } = props;

  const classes = useStyles();
  const calendarRef = useRef(null);
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const [view, setView] = useState(mobileDevice ? 'listWeek' : 'dayGridMonth');
  const [date, setDate] = useState(moment().toDate());
  // const [displayDate , setDisplayDate] = useState('')
  console.log(moment().month())

useEffect(()=>{
  fetchCalenderCountRequest(moment().month())
  console.log(moment().toDate())
  let date=moment().toDate()
  console.log(moment(date).format('YYYY-MM-DD'))
  fetchCalenderAppointment(moment(date).format('YYYY-MM-DD'))

},[])


  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    const newView = mobileDevice ? 'listWeek' : 'dayGridMonth';

    calendarApi.changeView(newView);
    setView(newView);
  }, [mobileDevice]);

  let eve =[]
  if(events.length !== 0){
  for (let i = 0; i < events.length; i++) {
    eve.push({
      title: events[i].appointment_count,
      start: events[i].appointment_date,
    });  
  }
}

  const handleDateToday = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.today();
    setDate(calendarApi.getDate());
  };

  const handleViewChange = view => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.changeView(view);
    setView(view);
  };

  const handleDatePrev = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.prev();
    setDate(calendarApi.getDate());
  };

  const handleDateNext = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.next();
    setDate(calendarApi.getDate());
  };

  const handleDateClick = info => {
    console.log(moment(info.date).format('YYYY-MM-DD'))
    // setDisplayDate(moment(info.date).format('YYYY-MM-DD'))
    fetchCalenderAppointment(moment(info.date).format('YYYY-MM-DD'))

  };

  return (
    <Grid container>
    <Grid item xs={12} sm={9} md={9}>
    <Page
      className={classes.root}
    >
      <Header
        date={date}
        onDateNext={handleDateNext}
        onDatePrev={handleDatePrev}
        onDateToday={handleDateToday}
        onViewChange={handleViewChange}
        view={view}
      />
      {console.log(eve)}
      <Card className={classes.card}>
        <CardContent>
          <FullCalendar
            allDayMaintainDuration
            defaultDate={date}
            defaultView={view}
            droppable
            editable
            // eventClick={handleEventClick}
            dateClick={handleDateClick}
            eventResizableFromStart
            header={false}
            // events={eve}
            height={800}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
              timelinePlugin
            ]}
            ref={calendarRef}
            rerenderDelay={10}
            selectable
            weekends
          />
        </CardContent>
      </Card>
    </Page>
    </Grid>
    <Grid item xs={12} sm={3} md={3}>
    <CustomCard
      style={{
        fontSize: "unset",
      }}
    >
      <CardHeader color="danger">
        <h4 className={classes.cardTitleWhite}>
          Appointments
        </h4>
      </CardHeader>
      <CardBody>
        <div style={{ marginLeft: 6 }}>
          
          <AppointmentList/>
        </div>
      </CardBody>
    </CustomCard>
  </Grid>
    </Grid>
  );
};

const mapStateToProps = state =>{
  return{
    events:state.calender.counts,
    // listLoader:state.calender.loading
  }
}

const mapDispatchToProps = dispatch =>{
  return{
    fetchCalenderCountRequest : (month)=> dispatch(fetchCalenderCountRequest(month)),
    fetchCalenderAppointment : (date)=> dispatch(fetchCalenderAppointment(date))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(FullCalender);
