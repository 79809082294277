import * as actionTypes from "actions";

const INITIAL_STATE = {
  error: null,
  message: "",
  loading: false,
  reminder: [],
  reminderById :{}
};

const reminderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REMINDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_REMINDER_SUCCESS:
      return {
        ...state,
        loading: false,
        reminder: action.payload,
        error: "",
      };
    case actionTypes.FETCH_REMINDER_FAILURE:
      return {
        ...state,
        loading: false,
        reminder: [],
        error: action.payload,
      };
      case actionTypes.FETCH_REMINDER_REQUEST_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_REMINDER_SUCCESS_BY_ID:
      return {
        ...state,
        loading: false,
        reminderById: action.payload,
        error: "",
      };
    case actionTypes.FETCH_REMINDER_FAILURE_BY_ID:
      return {
        ...state,
        loading: false,
        reminderById: [],
        error: action.payload,
      };
    case actionTypes.REMINDER_ADD_START:
      return {
        ...state,
        ...{ loading: true, error: null },
      };
    case actionTypes.REMINDER_ADD_SUCCESS:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    case actionTypes.REMINDER_ADD_FAILURE:
      return {
        ...state,
        message: "",
        loading: false,
      };
      case actionTypes.REMINDER_UPDATE_START:
      return {
        ...state,
        ...{ loading: true, error: null },
      };
    case actionTypes.REMINDER_UPDATE_SUCCESS:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    case actionTypes.REMINDER_UPDATE_FAILURE:
      return {
        ...state,
        message: "",
        loading: false,
      };
    case actionTypes.REMINDER_DELETE_START:
      return {
        ...state,
        ...{ loading: true, error: null },
      };
    case actionTypes.REMINDER_DELETE_SUCCESS:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    case actionTypes.REMINDER_DELETE_FAILURE:
      return {
        ...state,
        message: "",
        loading: false,
      };
    default:
      return state;
  }
};

export default reminderReducer;
