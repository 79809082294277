import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import CardBody from "../../../../components/Card/CardBody";

const styles = (theme) => ({});

const useStyles = makeStyles(styles);

const DietNotesData = (props) => {
  const classes = useStyles();
  const { dietNotes } = props;
  const [dietData, setDietData] = useState("");
  const [dietNotesDataNotInList, setDietNotesDataNotInList] = useState("");

  useEffect(() => {
    var diet_notes = "";
    var diet_notes_not_in_list = "";
    if (dietNotes !== undefined) {
      if (dietNotes.attributes.diet_note === null) {
        console.log("");
      } else if (dietNotes.attributes.diet_note.hasOwnProperty("diet_data")) {
        for (
          let i = 0;
          i < dietNotes.attributes.diet_note.diet_data.length;
          i++
        ) {
          diet_notes =
            diet_notes + "," + dietNotes.attributes.diet_note.diet_data[i].note;
        }
        diet_notes = diet_notes.substring(1);
        // console.log(diet_notes);
        setDietData(diet_notes);
      }
      if (dietNotes.attributes.diet_note === null) {
        console.log("");
      } else if (dietNotes.attributes.diet_note.hasOwnProperty("diets")) {
        for (let i = 0; i < dietNotes.attributes.diet_note.diets.length; i++) {
          diet_notes_not_in_list =
            diet_notes_not_in_list +
            "," +
            dietNotes.attributes.diet_note.diets[i];
        }
        diet_notes_not_in_list = diet_notes_not_in_list.substring(1);
        // console.log(diet_notes_not_in_list);
        setDietNotesDataNotInList(diet_notes_not_in_list);
      }
    }
  }, [dietNotes]);

  return (
    <div>
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
          {/* <Card style={{ marginTop:1 }}> */}
          <CardBody>
            {dietNotes !== undefined ? (
              <div>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      style={{ marginLeft: -10, marginTop: -15, fontSize: 13 }}
                    >
                      {dietData}
                      {dietNotesDataNotInList !== "" ? (
                        <span>,{dietNotesDataNotInList}</span>
                      ) : null}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            ) : null}
          </CardBody>
          {/* </Card> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default DietNotesData;
