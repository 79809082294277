import * as actionTypes from "actions";

const userInitialState = {
  loading: false,
  lifestyle: [],
  error: "",
};

const lifestyleReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LIFESTYLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_LIFESTYLE_SUCCESS:
      return {
        ...state,
        lifestyle: action.payload,
        loading: false,
        error: "",
      };
    case actionTypes.FETCH_LIFESTYLE_FAILURE:
      return {
        ...state,
        loading: false,
        lifestyle: [],
        error: action.payload,
      };
      case actionTypes.EDIT_LIFESTYLE_START:
        return {
          ...state,
          ...{ loading: true, error: null },
        };
      case actionTypes.EDIT_LIFESTYLE_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case actionTypes.EDIT_LIFESTYLE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
    default:
      return state;
  }
};

export default lifestyleReducer;
