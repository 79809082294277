import moment from "moment";

const checkActionType = (slackData) => {
  let dataToSend = {};
  dataToSend.errorMessage =
  ` *Error received*

          Portal Name: *${`DOCTOR PORTAL`}*
          Doctor Name: *${localStorage.getItem("doctorName")}*
          Error: *${slackData.errorMessage}*
          Error Code: *${slackData.errorCode}*
          Endpoint: *${slackData.endPoint}*
          Method: *${slackData.method}*
          Date: *${moment().format("DD-MM-YYYY HH:mm")}* 
          `
  dataToSend.env = process.env.REACT_APP_ENV_NAME;
  // dataToSend.env = "production";
  dataToSend.actionType = "errorLogging";
  return dataToSend;
};

export const slackDebugger = (slack) => {
  let slackDataToSend = checkActionType(slack);
  fetch("https://cool-dragonfly-67.deno.dev", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(slackDataToSend),
  }).then((response) => {
    response.json().then((data) => {
      console.log(response.status);
      if (response.status === 200) {
        console.log(data.message);
      } else {
        alert(data.error);
      }
    });
  });
};

// https://cool-dragonfly-67.deno.dev
// http://localhost:8080
