import * as actionTypes from "actions";
const userInitialState = {
  loading: false,
  familyHistory: [],
  editData:[],
  error: "",
};

const familyHistoryReducer = (state = userInitialState,  action) => {
  switch (action.type) {
    case actionTypes.FETCH_FAMILYHISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_FAMILYHISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        familyHistory: action.payload,
        error: "",
      };
    case actionTypes.FETCH_FAMILYHISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        familyHistory: [],
        error: action.payload,
      };
      case actionTypes.FETCH_FAMILYHISTORY_DATA_FOR_EDIT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.FETCH_FAMILYHISTORY_DATA_FOR_EDIT_SUCCESS:
        return {
          ...state,
          loading: false,
          editData: action.payload,
          error: "",
        };
      case actionTypes.FETCH_FAMILYHISTORY_DATA_FOR_EDIT_FAILURE:
        return {
          ...state,
          loading: false,
          editData: [],
          error: action.payload,
        };
      case actionTypes.EDIT_FAMILYHISTORY_START:
        return {
          ...state,
          ...{ loading: true, error: null },
        };
      case actionTypes.EDIT_FAMILYHISTORY_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case actionTypes.EDIT_FAMILYHISTORY_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
    default:
      return state;
  }
};

export default familyHistoryReducer;
