export const FETCH_PATIENT_REQUEST = 'FETCH_PATIENT_REQUEST';
export const FETCH_PATIENT_SUCCESS = 'FETCH_PATIENT_SUCCESS';
export const FETCH_PATIENT_FAILURE = 'FETCH_PATIENT_FAILURE';
export const SELF_ASSIGN_START = 'SELF_ASSIGN_START';
export const SELF_ASSIGN_SUCCESS = 'SELF_ASSIGN_SUCCESS';
export const SELF_ASSIGN_FAIL = 'SELF_ASSIGN_FAIL';

export const FETCH_SEARCH_PATIENT_REQUEST = 'FETCH_SEARCH_PATIENT_REQUEST';
export const FETCH_SEARCH_PATIENT_SUCCESS = 'FETCH_SEARCH_PATIENT_SUCCESS';
export const FETCH_SEARCH_PATIENT_FAILURE = 'FETCH_SEARCH_PATIENT_FAILURE';

export const FETCH_SEARCH_PATIENT_BY_DIAGNOSIS_REQUEST = 'FETCH_SEARCH_PATIENT_BY_DIAGNOSIS_REQUEST';
export const FETCH_SEARCH_PATIENT_BY_DIAGNOSIS_SUCCESS = 'FETCH_SEARCH_PATIENT_BY_DIAGNOSIS_SUCCESS';
export const FETCH_SEARCH_PATIENT_BY_DIAGNOSIS_FAILURE = 'FETCH_SEARCH_PATIENT_BY_DIAGNOSIS_FAILURE';
export const PATIENT_DATA_COUNTER = 'PATIENT_DATA_COUNTER';

export const fetchPatientCounter = () => {
  return {
      type: PATIENT_DATA_COUNTER,
  }
}

export const fetchSearchPatientRequest = (url) => {
  return {
      type: FETCH_SEARCH_PATIENT_REQUEST,
      url:url
  }
}

export const fetchSearchPatientSuccess = (patients) => {
  return {
      type: FETCH_SEARCH_PATIENT_SUCCESS,
      payload: patients
      
  }
}

export const fetchSearchPatientFailure = error => {
  return {
      type: FETCH_SEARCH_PATIENT_FAILURE,
      payload: error
  }
}

export const fetchSearchPatientByDiagnosisRequest = (patientId,diagnosis) => {
  return {
      type: FETCH_SEARCH_PATIENT_BY_DIAGNOSIS_REQUEST,
      patientId:patientId,
      diagnosis:diagnosis
  }
}

export const fetchSearchPatientByDiagnosisSuccess = patients => {
  return {
      type: FETCH_SEARCH_PATIENT_BY_DIAGNOSIS_SUCCESS,
      payload: patients
  }
}

export const fetchSearchPatientByDiagnosisFailure = error => {
  return {
      type: FETCH_SEARCH_PATIENT_BY_DIAGNOSIS_FAILURE,
      payload: error
  }
}





export const fetchPatientRequest = (val,url) => {
  return {
      type: FETCH_PATIENT_REQUEST,
      val:val,
      url:url
  }
}

export const fetchPatientSuccess = (patients,rowCount) => {
  return {
      type: FETCH_PATIENT_SUCCESS,
      payload: patients,
      rowCount:rowCount
  }
}

export const fetchPatientFailure = error => {
  return {
      type: FETCH_PATIENT_FAILURE,
      payload: error
  }
}

export const assignToSelfStart = (id) => {
  return {
      type: 'SELF_ASSIGN_START',
      id:id
  }
};

export const assignToSelfSuccess = (patients) => {
  return {
      type: 'SELF_ASSIGN_SUCCESS',
  }
};

export const assignToSelfFail = (error) => {
  return {
      type: 'SELF_ASSIGN_FAIL',
      error: error
  }
};
