import * as actionTypes from "actions";
const userInitialState = {
  loading: false,
  vital: [],
  medical:[],
  prescription:[],
  error: "",
};

const timelineReducer = (state = userInitialState,  action) => {
  switch (action.type) {
    case actionTypes.FETCH_TIMELINE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_TIMELINE_VITAL_SUCCESS:
      return {
        ...state,
        loading: false,
        vital: action.payload,
        error: "",
      };
    case actionTypes.FETCH_TIMELINE_VITAL_FAILURE:
      return {
        ...state,
        loading: false,
        vital: [],
        error: action.payload,
      };
      case actionTypes.FETCH_TIMELINE_MEDICAL_SUCCESS:
        return {
          ...state,
          loading: false,
          medical: action.payload,
          error: "",
        };
      case actionTypes.FETCH_TIMELINE_MEDICAL_FAILURE:
        return {
          ...state,
          loading: false,
          medical: [],
          error: action.payload,
        };
        case actionTypes.FETCH_TIMELINE_PRESCRIPTION_SUCCESS:
          return {
            ...state,
            loading: false,
            prescription: action.payload,
            error: "",
          };
        case actionTypes.FETCH_TIMELINE_PRESCRIPTION_FAILURE:
          return {
            ...state,
            loading: false,
            prescription: [],
            error: action.payload,
          };
          case actionTypes.TIMELINE_DATA_EMPTY_REQUEST:
            return {
              ...state,
              loading: false,
              prescription: [],
              medical: [],
              vital: [],
              error: '',
            };
    default:
      return state;
  }
};

export default timelineReducer;
