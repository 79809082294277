export const FETCH_LIFESTYLE_REQUEST = 'FETCH_LIFESTYLE_REQUEST';
export const FETCH_LIFESTYLE_SUCCESS = 'FETCH_LIFESTYLE_SUCCESS';
export const FETCH_LIFESTYLE_FAILURE = 'FETCH_LIFESTYLE_FAILURE';

export const EDIT_LIFESTYLE='EDIT_LIFESTYLE'
export const EDIT_LIFESTYLE_START='EDIT_LIFESTYLE_START'
export const EDIT_LIFESTYLE_SUCCESS='EDIT_LIFESTYLE_SUCCESS'
export const EDIT_LIFESTYLE_FAIL='EDIT_LIFESTYLE_FAIL'


export const fetchLifestyleRequest = (patientId) => {
  return {
      type: FETCH_LIFESTYLE_REQUEST,
      patientId:patientId,
  }
}

export const fetchLifestyleSuccess = lifestyle => {
  return {
      type: FETCH_LIFESTYLE_SUCCESS,
      payload: lifestyle
  }
}

export const fetchLifestyleFailure = error => {
  return {
      type: FETCH_LIFESTYLE_FAILURE,
      payload: error
  }
}

export const editLifestyle = (formData,id) => {
  return {
      type: 'EDIT_LIFESTYLE',
      formData :formData,
      id:id
  }
};

export const editLifestyleStart = () => {
  return {
      type: 'EDIT_LIFESTYLE_START'
  }
};

export const editLifestyleSuccess = () => {
  return {
      type: 'EDIT_LIFESTYLE_SUCCESS',
  }
};

export const editLifestyleFail = (error) => {
  return {
      type: 'EDIT_LIFESTYLE_FAIL',
      error: error
  }
};
