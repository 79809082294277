import { takeEvery, call, put,delay } from "redux-saga/effects";
import { FETCH_EXERCISE_TEST_REQUEST,FETCH_EXERCISE_TEST_BY_NAME_REQUEST } from '../actions';
// import doctorService from "../services/doctorService";
import * as actions from "../actions";


export function* watcherExerciseSaga() {
  yield takeEvery(FETCH_EXERCISE_TEST_REQUEST, exerciseSelectedSaga);
  yield takeEvery(FETCH_EXERCISE_TEST_BY_NAME_REQUEST, exerciseSelectedByNameSaga);

}

function* exerciseSelectedSaga(action) {
  try {
    yield put( actions.fetchExerciseSuccess(action.tests) );
  } catch (error) {
    yield put( actions.fetchExerciseFailure(error) );
  }
}

function* exerciseSelectedByNameSaga(action) {
  try {
    yield put( actions.fetchExerciseByNameSuccess(action.testName) );
  } catch (error) {
    yield put( actions.fetchExerciseByNameFailure(error) );
  }
}
