export const FETCH_ALLINTERACTION_REQUEST = 'FETCH_ALLINTERACTION_REQUEST';
export const FETCH_ALLINTERACTION_SUCCESS = 'FETCH_ALLINTERACTION_SUCCESS';
export const FETCH_ALLINTERACTION_FAILURE = 'FETCH_ALLINTERACTION_FAILURE';

export const STORE_ALLERGY = 'STORE_ALLERGY';
export const STORE_ALLERGY_START = 'STORE_ALLERGY_START';
export const STORE_ALLERGY_SUCCESS = 'STORE_ALLERGY_SUCCESS';
export const STORE_ALLERGY_FAILURE = 'STORE_ALLERGY_FAILURE';


export const fetchAllInteractionRequest = (formData) => {
  return {
      type: FETCH_ALLINTERACTION_REQUEST,
      formData:formData
  }
}

export const fetchAllInteractionSuccess = (interaction,length) => {
  return {
      type: FETCH_ALLINTERACTION_SUCCESS,
      payload: interaction,
      length:length
  }
}

export const fetchAllInteractionFailure = error => {
  return {
      type: FETCH_ALLINTERACTION_FAILURE,
      payload: error
  }
}

export const storeAllergy = (molecule,substance) => {
  return {
      type: 'STORE_ALLERGY',
      molecule:molecule,
      substance:substance
  }
};

export const storeAllergyStart = () => {
  return {
      type: 'STORE_ALLERGY_START'
  }
};

export const storeAllergySuccess = (molecule,substance) => {
  return {
      type: 'STORE_ALLERGY_SUCCESS',
      molecule: molecule,
      substance:substance
  }
};


export const storeAllergyFail = (error) => {
  return {
      type: 'STORE_ALLERGY_FAILURE',
      error: error
  }
};
