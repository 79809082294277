export const FETCH_DIFFERENTIAL_REQUEST = 'FETCH_DIFFERENTIAL_REQUEST';
export const FETCH_DIFFERENTIAL_SUCCESS = 'FETCH_DIFFERENTIAL_SUCCESS';
export const FETCH_DIFFERENTIAL_FAILURE = 'FETCH_DIFFERENTIAL_FAILURE';

export const DIFFERENTIAL_ADD = 'DIFFERENTIAL_ADD';
export const DIFFERENTIAL_ADD_START = 'DIFFERENTIAL_ADD_START';
export const DIFFERENTIAL_ADD_SUCCESS = 'DIFFERENTIAL_ADD_SUCCESS';
export const DIFFERENTIAL_ADD_EMPTY = 'DIFFERENTIAL_ADD_EMPTY';
export const DIFFERENTIAL_ADD_FAILURE = 'DIFFERENTIAL_ADD_FAILURE';

export const DIFFERENTIAL_ADD_BY_NAME = 'DIFFERENTIAL_ADD_BY_NAME';
export const DIFFERENTIAL_ADD_BY_NAME_START = 'DIFFERENTIAL_ADD_BY_NAME_START';
export const DIFFERENTIAL_ADD_BY_NAME_SUCCESS = 'DIFFERENTIAL_ADD_BY_NAME_SUCCESS';
export const DIFFERENTIAL_ADD_BY_NAME_FAILURE = 'DIFFERENTIAL_ADD_BY_NAME_FAILURE';

export const DIFFERENTIAL_TYPE_ADD = 'DIFFERENTIAL_TYPE_ADD';
export const DIFFERENTIAL_COUNT = 'DIFFERENTIAL_COUNT';



export const DifferentialTypeAdd = (value) => {
  return {
      type: DIFFERENTIAL_TYPE_ADD,
      value:value,
  }
}

export const DifferentialCount = () => {
  return {
      type: DIFFERENTIAL_COUNT,
  }
}

export const fetchDifferentialRequest = (params) => {
  return {
      type: FETCH_DIFFERENTIAL_REQUEST,
      params:params,
  }
}

export const fetchDifferentialSuccess = diagnosis => {
  return {
      type: FETCH_DIFFERENTIAL_SUCCESS,
      payload: diagnosis
  }
}

export const fetchDifferentialFailure = error => {
  return {
      type: FETCH_DIFFERENTIAL_FAILURE,
      payload: error
  }
}

export const DifferentialAdd = (differential) => {
  return {
      type: 'DIFFERENTIAL_ADD',
      differential:differential,
  }
};

export const DifferentialAddStart = () => {
  return {
      type: 'DIFFERENTIAL_ADD_START'
  }
};

export const DifferentialAddSuccess = (message,data) => {
  return {
      type: 'DIFFERENTIAL_ADD_SUCCESS',
      payload: data,
      message:message
  }
};

export const DifferentialAddEmpty = () => {
  return {
      type: 'DIFFERENTIAL_ADD_EMPTY',
  }
};

export const DifferentialAddFail = (error) => {
  return {
      type: 'DIFFERENTIAL_ADD_FAILURE',
      error: error
  }
};

export const differentialAddByName = (diseaseName) => {
  return {
      type: DIFFERENTIAL_ADD_BY_NAME,
      diseaseName:diseaseName,
  }
}
export const differentialAddByNameStart = () => {
  return {
      type: 'DIFFERENTIAL_ADD_BY_NAME_START'
  }
};

export const differentialAddByNameSuccess = (message,diseaseName) => {
  return {
      type: DIFFERENTIAL_ADD_BY_NAME_SUCCESS,
      payload: diseaseName,
      message:message,
  }
}

export const differentialAddByNameFailure = error => {
  return {
      type: DIFFERENTIAL_ADD_BY_NAME_FAILURE,
      payload: error
  }
}