import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { renderRoutes } from 'react-router-config';
import './assets/scss/index.scss';
import theme from './theme';
import  store  from './store/store';
import routes from './routes';
// import moment from "moment";

const history = createBrowserHistory();

const App = () => {
 
  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
          <Router history={history}>
            {renderRoutes(routes)}
          </Router>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
