
import React from 'react';
import './Spinner.css';



const Spinner=()=> {
 

  return (
    <div className="loaders">Loading...</div>
  );
  }

export default Spinner;
