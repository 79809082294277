import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import fetchPatientReducer from "./fetchPatientReducer";
import medicalProblemReducer from "./MedicalProblemReducer";
import currentMedicationReducer from "./currentMedication";
import vitalReducer from "./vitalReducer";
import lifestyleReducer from "./lifestyleReducer";
import familyHistoryReducer from "./familyHistoryReducer";
import documentReducer from "./documentReducer";
import calenderReducer from "./calenderReducer";
import timelineReducer from "./timelineReducer";
import timelineDataReducer from "./timelineDataReducer";
import patientDetailReducer from "./patientDetailByIdReducer";
import formularyReducer from "./formulary";
import allInteractionReducer from "./allInteraction";
import suggestionReducer from "./suggestionReducer";
import pathologyTestReducer from "./pathologyTestReducer";
import radiologyTestReducer from "./radiologyReducer";
import dietReducer from "./dietReducer";
import exerciseReducer from "./exerciseReducer";
import differentialReducer from "./DifferentialReducer";
import allergyReducer from "./allergyReducere";
import prescriptionReducer from "./prescription";
import flowBoardStatusReducer from "./flowBoardStatus";
import followUpReducer from "./followUpReducer";
import doctorNotesReducer from "./doctorNotesReducer";
import leaveReducer from "./leaveReducer";
import startConsultationReducer from "./startConsultationReducer";
import reminderReducer from "./reminderReducer";
import userProfileReducer from "./userProfile";
import vonageVideoCallReducer from './vonageVideoCall'

const rootReducer = combineReducers({
  login: loginReducer,
  fetchpatient: fetchPatientReducer,
  medicalproblem: medicalProblemReducer,
  currentmedication: currentMedicationReducer,
  vital: vitalReducer,
  lifestyle: lifestyleReducer,
  familyhistory: familyHistoryReducer,
  documents: documentReducer,
  calender: calenderReducer,
  timeline: timelineReducer,
  timelineData: timelineDataReducer,
  patientDetail: patientDetailReducer,
  formulary: formularyReducer,
  allInteraction: allInteractionReducer,
  suggestions: suggestionReducer,
  pathologyTest: pathologyTestReducer,
  radiologyTest: radiologyTestReducer,
  diets: dietReducer,
  exercises: exerciseReducer,
  diagnosis: differentialReducer,
  allergy: allergyReducer,
  prescription: prescriptionReducer,
  flowBoardStatus: flowBoardStatusReducer,
  followUp: followUpReducer,
  doctorNotes: doctorNotesReducer,
  leave: leaveReducer,
  startConsultation: startConsultationReducer,
  reminder: reminderReducer,
  userProfile: userProfileReducer,
  vonageVideo : vonageVideoCallReducer
});

export default rootReducer;
