import * as actionTypes from "actions";
const userInitialState = {
  loading: false,
  document: [],
  error: "",
};

const documentReducer = (state = userInitialState,  action) => {
  switch (action.type) {
    case actionTypes.FETCH_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        document: action.payload,
        error: "",
      };
    case actionTypes.FETCH_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        document: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default documentReducer;
