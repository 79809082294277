import { takeEvery, call, put,takeLatest } from "redux-saga/effects";
import { LOGIN_USER,USER_LOGOUT } from "../actions";
import doctorService from "../services/doctorService";
import * as actions from "../actions";
// import  delay  from 'redux-saga';
import moment from 'moment'
import { encodeGetParams,errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherLoginSaga() {
  yield takeEvery(LOGIN_USER, workerLoginSaga);
  yield takeLatest(USER_LOGOUT, logout);

}

function* workerLoginSaga(action) {
  yield put(actions.loginStart());

  try {
    const response = yield call(doctorService.loginUser, {
      email: action.email,
      password: action.password,
      role: action.role,
    });
    yield localStorage.setItem("jwt", response.data.jwt);
    if(response.status === 201){
    const res = yield call(doctorService.currentUser)
    yield localStorage.setItem("userId", res.data.data.attributes.id);
    yield localStorage.setItem("zoom_id", res.data.data.attributes.zoom_id);
    yield localStorage.setItem("doctorCatId", res.data.data.attributes.doctor_category?.id);
    yield localStorage.setItem("doctorName", res.data.data.attributes.name);
    yield localStorage.setItem("designation", res.data.data.attributes.designation);
    yield localStorage.setItem("consultationContinueValue", false);
    yield put(actions.loginSuccess(res.data.data.attributes.id, 1,res.data.data.attributes.designation));
    const params = {
      type: "individual",
      id: localStorage.getItem('userId'),
      apt_date: moment().format("YYYY-MM-DD"),
    };
    let queryString = encodeGetParams(params);
    const slotsCheck = yield call(doctorService.getDoctorCurrentSlots, queryString);
    if(slotsCheck.data.length !== 0){
    yield put(actions.isStartConsultationOnSlotCheck(true))
   }
  }else{
    alert('Something went wrong !!!')
  }
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'user_token',
       method:'POST',
     })
    }
    console.log(error.response.data.error);
    if(error.response.data.error[0] !== "No slots are available")
    alert(error.response.data.error)
    yield put(actions.authFail(error));
  }
}

function* logout(action) {
  yield put(actions.logoutStart());
  try {
    const id= localStorage.getItem("userId");
    const response = yield call(doctorService.logoutUser, id);
    if(response.status === 200){
      localStorage.clear();
      yield put(actions.logoutSuccess(response.data.message,1))
      alert(response.data.message)
      }
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'logout',
       method:'GET',
     })
    }
    yield put(actions.logoutFail(error));
  }
}


