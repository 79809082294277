import React,{useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { Link as RouterLink } from "react-router-dom";
import WcIcon from "@material-ui/icons/Wc";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import Card from "../../../../../components/Card/Card";
import CardAvatar from "../../../../../components/Card/CardAvatar";
import CardBody from "../../../../../components/Card/CardBody";
import styles from "../../../../../assets/jss/material-dashboard-react/views/doctorCategory";
import DateRangeIcon from "@material-ui/icons/DateRange";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import InitialFeedback from "../../../../Prescription/Feedback/InitialFeedback";
import FeedBackModal from "../../../../Prescription/Feedback/FeedbackModal";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { connect } from "react-redux";
import avatar from "../../../../../assets/img/patient1.jpg";
import FeedbackIcon from "@material-ui/icons/Feedback";
import {
  assignToSelfStart,
  reviewRequestRequest,
} from "../../../../../actions";
import moment from "moment";
import Fab from "@material-ui/core/Fab";
import { Link, Typography, colors } from "@material-ui/core";
import { ageCalculator,capitalizeFirstLetter } from "../../../../../utils/helper";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";


const useStyles = makeStyles(styles);
const PatientCard = (props) => {
  const [feedbackModalOpen, setfeedbackModalOpen] = useState(false);
  const [initialFeedbackModalOpen, setInitialFeedbackModalOpen] = useState(false);
  const [patientVisitId,setPatientVisitId] = useState(undefined)
  const { doctor,handleFeedbackCounter } = props;
  const classes = useStyles();
  const statusColors = {
    "Waiting for doctor": colors.orange[600],
    "Waiting for mso": colors.red[400],
    "Doctor review": colors.orange[600],
    "Prescription being prepared": colors.orange[600],
    "Consultation ongoing": colors.orange[600],
    Cancelled: colors.red[600],
    "Patient stepped out": colors.red[600],
    "Checked out": colors.green[600],
  };

  const handleFeedbackOpen = () => {
    setfeedbackModalOpen(true);
  };

  const handleFeedbackClose = () => {
    setfeedbackModalOpen(false);
  };

  const handleInitialFeedbackOpen = (visitId) => {
    setPatientVisitId(visitId)
    setInitialFeedbackModalOpen(true);
  };

  const handleInitialFeedbackClose = () => {
    setInitialFeedbackModalOpen(false);
  };

  return (
    <>
    <Card profile className={classes.card}>
      <CardAvatar profile>
        {doctor.attributes.patient.profile_photo ? (
          <div
            style={{
              height: "130px",
              backgroundColor: "#489a9c",
            }}
          >
            {" "}
            <img
              src={doctor.attributes.patient.profile_photo}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                position: "acsolute",
                cursor: "pointer",
              }}
            />
          </div>
        ) : (
          <img src={avatar} alt="..." />
        )}
      </CardAvatar>
      <CardBody>
        <h6 className={classes.cardCategory} style={{ fontWeight: 600 }}>
          {doctor.attributes.patient.name}
        </h6>
        <br />
        <div className={classes.stats}>
          <DateRangeIcon />
          <span style={{ fontWeight: 600 }}>Age: </span>
          <span
            style={{
              fontWeight: 600,
              marginLeft: 5,
              color: "rgb(29, 96, 154)",
            }}
          >
            {ageCalculator(doctor.attributes.patient.dob)}
          </span>
        </div>{" "}
        <br />
        <div className={classes.stats}>
          <WcIcon />
          <span style={{ fontWeight: 600 }}>Gender: </span>
          <span
            style={{
              fontWeight: 600,
              marginLeft: 5,
              color: "rgb(29, 96, 154)",
            }}
          >
            {doctor.attributes.patient.gender}
          </span>
        </div>
        <br />
        <div className={classes.stats}>
          <PhoneAndroidIcon />
          <span style={{ fontWeight: 600 }}>Mobile: </span>
          <span
            style={{
              fontWeight: 600,
              marginLeft: 5,
              color: "rgb(29, 96, 154)",
            }}
          >
            {doctor.attributes.patient.mobile}
          </span>
        </div>
        <br />
        <div className={classes.stats}>
          <ScheduleIcon />
          <span style={{ fontWeight: 600 }}>Registered Time: </span>
          <span style={{ fontWeight: 600, marginLeft: 5, color: "#157304" }}>
            {moment
              .utc(doctor.attributes.sent_to_patient_pool_at)
              .local()
              .format("HH:mm")}
          </span>
        </div>
        <br />
        <div className={classes.stats}>
          <EventAvailableIcon />
          <span style={{ fontWeight: 600 }}>Consultation Date: </span>
          <span style={{ fontWeight: 600, marginLeft: 5, color: "#157304" }}>
            {moment
              .utc(doctor.attributes.visit_datetime)
              .local()
              .format("DD MMM YYYY")}
          </span>
        </div>
        <br />
        <div className={classes.stats}>
          <ScheduleIcon />
          <span style={{ fontWeight: 600 }}>Consultation Time: </span>
          <span style={{ fontWeight: 600, marginLeft: 5, color: "#157304" }}>
            {moment
              .utc(doctor.attributes.visit_datetime)
              .local()
              .format("HH:mm")}
          </span>
        </div>
        <br />
        <div className={classes.stats}>
          <StorefrontIcon />
          <span style={{ fontWeight: 600 }}>MSO : </span>
          <span
            style={{
              fontWeight: 600,
              marginLeft: 5,
              color:'rgb(29, 96, 154)'
            }}
          >
            {doctor.attributes.created_by.name} <span 
            style={{ 
              color:doctor.attributes.created_by.current_availability_status === 'available' ? "green" : 'rgb(203, 38, 38)' }}
              >
              ( {capitalizeFirstLetter(doctor.attributes.created_by?.current_availability_status ?? '')} )
              </span>
          </span>
        </div>
        <br />
        <div className={classes.stats}>
          <span style={{ fontWeight: 600 }}>Status : </span>
          <span
            style={{
              fontWeight: 600,
              marginLeft: 5,
              color: statusColors[doctor.attributes.flow_board.status],
            }}
          >
            {doctor.attributes.flow_board.status}
          </span>
        </div>
      </CardBody>
      <div className={classes.actions} style={{ marginTop: "-5%" }}>
        <Typography style={{ fontWeight: 500 }} variant="body2">
          Action
        </Typography>
        {doctor.attributes.doctor ? (
          <>
            {doctor.attributes.flow_board?.status_key === 10 ||
            doctor.attributes.flow_board?.status_key === 9 ||
            doctor.attributes.flow_board?.status_key === 8 
            ? (
              <div style={{ marginTop: "6%" }}>
                <Typography style={{ color: "#b53838", fontWeight: 400,fontSize:17 }}>
                  {doctor.attributes.flow_board?.status_key === 9
                    ? "Appointment Cancelled"
                    : doctor.attributes.flow_board?.status_key === 10 ?
                      "MSO unavailable" :  doctor.attributes.flow_board?.status_key === 8 &&
                      "Patient stepped out"
                      }
                </Typography>
              </div>
            ) : (
              <div style={{ display:'flex',justifyContent:'center' }}>
              <Link
                color="inherit"
                data-testid={`review-patient-${doctor.attributes.patient.name.toLowerCase()}-link`}
                component={RouterLink}
                to={`/patientdetail/${doctor.attributes.patient.id}/${doctor.attributes.id}`}
                variant="h6"
              >
                <Tooltip title="View Detail" aria-label="View Detail">
                  <Fab className={classes.fab1}>
                    <VisibilityIcon />
                  </Fab>
                </Tooltip>
              </Link>
              {doctor.attributes.flow_board.status_key === 7 && (
              <>
              {doctor.attributes.doctor_to_mso_feedback === null ?
                <Link
                  color="inherit"
                  style={{ marginLeft:16 }}
                  onClick={()=>handleInitialFeedbackOpen(doctor.attributes.id)}
                  data-testid={`feedback-mso-link`}
                  variant="h6"
                >
                  <Tooltip title="Feedback" aria-label="Feedback">
                    <Fab className={classes.fab1}>
                      <FeedbackIcon />
                    </Fab>
                  </Tooltip>
                </Link> : 
                <div style={{ display:'flex',alignItems:'center',marginLeft:'5%',color:'green' }}>
                <Tooltip title="Feedback Given" aria-label="Feedback Given">
                  <DoneOutlineIcon/>
                </Tooltip>
                </div>
                }
              </>
             )}
              </div>
            )}
          </>
        ) : null}
      </div>
    </Card>
    {initialFeedbackModalOpen &&( 
      <InitialFeedback
        onClose={handleInitialFeedbackClose}
        open={initialFeedbackModalOpen}
        visitId={patientVisitId}
        finalFeedbackModaOpen={handleFeedbackOpen}
        handleFeedbackCounter={handleFeedbackCounter}
      />
    )}
    {feedbackModalOpen &&( 
      <FeedBackModal
        onClose={handleFeedbackClose}
        open={feedbackModalOpen}
        visitId={patientVisitId}
        handleFeedbackCounter={handleFeedbackCounter}
      />
    )}
  </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.fetchpatient.loading,
    flowboardStatus: state.flowBoardStatus.status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    assignToSelfStart: (id) => dispatch(assignToSelfStart(id)),
    reviewRequestRequest: (patientId, visitId) =>
      dispatch(reviewRequestRequest(patientId, visitId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientCard);
