import * as actionTypes from "actions";
const userInitialState = {
  loading: false,
  interactionData: [],
  molecule:[],
  substance:[],
  dataLength:0,
  error: "",
};

const allInteractionReducer = (state = userInitialState,  action) => {
  switch (action.type) {
    case actionTypes.FETCH_ALLINTERACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_ALLINTERACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        interactionData: action.payload,
        dataLength:action.length,
        error: "",
      };
    case actionTypes.FETCH_ALLINTERACTION_FAILURE:
      return {
        ...state,
        loading: false,
        interactionData: [],
        error: action.payload,
      };
      case actionTypes.STORE_ALLERGY_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STORE_ALLERGY_SUCCESS:
      return {
        ...state,
        loading: false,
        molecule: action.molecule,
        substance:action.substance,
        error: "",
      };
    case actionTypes.STORE_ALLERGY_FAILURE:
      return {
        ...state,
        loading: false,
        molecule: [],
        substance:[],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default allInteractionReducer;
