import * as actionTypes from "actions";
const userInitialState = {
  isVideoCallSubscribed: false,
  isVideoCallStarted:false,
  error: "",
};

const vonageVideoCallReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case actionTypes.HANDLE_VIDEO_CALL_SUBSCRIBTION:
      return {
        ...state,
        isVideoCallSubscribed: action.payload,
        error: "",
      };
      case actionTypes.START_VIDEO_CALL:
        return {
          ...state,
          isVideoCallStarted: action.payload,
          error: "",
        };
    default:
      return state;
  }
};

export default vonageVideoCallReducer;
