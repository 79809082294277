import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useTable from "../../../../components/UseTable/useTable";
import { TableBody, TableRow, TableCell, Grid } from "@material-ui/core";
// import CardBody from '../../../components/Card/CardBody'
// import Card from '../../../components/Card/Card'

const styles = (theme) => ({});
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      // backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles(styles);

const headCells = [{ id: "Radiology Test", label: "Radiology Test" }];

const RadioNotesData = (props) => {
  const classes = useStyles();
  const { radiologyTest } = props;
  const { TblContainer, TblHead } = useTable(radiologyTest, headCells);

  return (
    <div>
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
          {/* <Card style={{ marginTop:1 }}> */}
          {/* <CardBody> */}
          {radiologyTest !== undefined ? (
            <div>
              <TblContainer>
                <TblHead style={{ backgroundColor: "white" }} />
                <TableBody>
                  {radiologyTest.attributes.radiology_note ===
                  null ? null : radiologyTest.attributes.radiology_note.hasOwnProperty(
                      "test_data"
                    ) ? (
                    <React.Fragment>
                      {radiologyTest.attributes.radiology_note.test_data.map(
                        (item) => (
                          <StyledTableRow key={item.id}>
                            <TableCell>{item.test_name}</TableCell>
                          </StyledTableRow>
                        )
                      )}
                    </React.Fragment>
                  ) : null}
                  {radiologyTest.attributes.radiology_note ===
                  null ? null : radiologyTest.attributes.radiology_note.hasOwnProperty(
                      "tests"
                    ) ? (
                    <React.Fragment>
                      {radiologyTest.attributes.radiology_note.tests.map(
                        (item) => (
                          <StyledTableRow key={item}>
                            <TableCell>{item}</TableCell>
                          </StyledTableRow>
                        )
                      )}
                    </React.Fragment>
                  ) : null}
                </TableBody>
              </TblContainer>
            </div>
          ) : null}
          {/* </CardBody> */}
          {/* </Card> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default RadioNotesData;
