import { takeEvery, call, put,takeLatest,delay } from "redux-saga/effects";
import { 
  START_CONSULTATION,
  END_CONSULTATION,
  SCHEDULE_ADD,
  FETCH_TODAY_SCHEDULE,
  FETCH_ALL_SCHEDULE,
  PRE_LOGOFF_CONSULTATION
} from "../actions";
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import moment from 'moment'
import { blockButton,errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherStartConsultationSaga() {
  yield takeLatest(START_CONSULTATION, workerStartConsultSaga);
  yield takeLatest(END_CONSULTATION, workerEndConsultSaga);
  yield takeLatest(SCHEDULE_ADD, workerAddScheduleSaga);
  yield takeEvery(FETCH_TODAY_SCHEDULE,workerFetchScheduleSaga);
  yield takeEvery(FETCH_ALL_SCHEDULE,workerFetchAllScheduleSaga)
  yield takeEvery(PRE_LOGOFF_CONSULTATION,workerPreLogoffConsultSaga)

}

function* workerStartConsultSaga(action) {
  yield put(actions.startConsultationStart());
  try {
    const res = yield call(doctorService.StartConsultation)
    if(res.status === 201){
    yield localStorage.setItem("threshold_time", res.data.data.threshold_time);
    yield localStorage.setItem("consultation_id", res.data.data.id);
    yield localStorage.setItem("isConsultationStarted", true);
    yield localStorage.setItem("gracePeriodCounter", 0);
    localStorage.setItem("extendTimeCounter", 0);
    yield localStorage.setItem("end_time", moment.utc(res.data.data.consultation_end_time)
      .local()
      .format("HH:mm"));
      yield delay(1000)
      if(action.slotsCheck === 'No slots'){
        yield put(actions.startConsultationSuccess(false,true));
      }else{
        yield put(actions.startConsultationSuccess(false,false));
      }
    yield put(actions.extendTimeCounter(1));
  }else{
    alert('Something Went Wrong !!!')
  }
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
       slackDebugger({
        actionType:"errorLogging",
        errorMessage:error.response.statusText,
        errorCode : error.response.status,
        endPoint:'doctor_consultations',
        method:'POST',
      })
  } 
    yield put(actions.startConsultationFail(error));
  }
}

function* workerEndConsultSaga(action) {
  yield put(actions.endConsultationStart());
  try {
    const res = yield call(doctorService.EndConsultation,action.time)
    alert(res.data.message)
    if(res.status === 200 && !action.check){
      yield put(actions.endConsultationSuccess(true));
      yield put(actions.isCloseExtendTimeMOdal(true));
      localStorage.removeItem('threshold_time');
      yield localStorage.setItem("isConsultationStarted", false);
      localStorage.removeItem('end_time');
      localStorage.removeItem('extendTimeCounter');
      yield put(actions.extendTimeCounter(0));
      localStorage.removeItem('gracePeriodCounter');
    }else{
      if(res.status === 200){
        if(action.isGrace && action.isGrace === 'yes'){
          let gracePeriodCounter = parseInt(localStorage.getItem("gracePeriodCounter")) + 1;
          localStorage.setItem("gracePeriodCounter", gracePeriodCounter);
        }
      let extendTimeCount = parseInt(localStorage.getItem("extendTimeCounter")) + 2;
      yield put(actions.extendTimeCounter(extendTimeCount));
      localStorage.setItem("extendTimeCounter", extendTimeCount);
      localStorage.setItem("end_time", moment(action.endTime).format("HH:mm"));
      yield put(actions.endConsultationSuccess(false));
      yield put(actions.isCloseExtendTimeMOdal(true));
      yield localStorage.setItem("isConsultationStarted", true);
      }
    }
  } catch (error) {
    alert(error.response.data.error);
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'end_consultation',
       method:'PUT',
     })
    }
    yield put(actions.endConsultationFail(error));
  }
}

function* workerPreLogoffConsultSaga(action) {
  yield put(actions.preLogOffConsultationStart());
  try {
    const res = yield call(doctorService.preLogOffConsultation,action.data)
    yield put(actions.preLogOffConsultationSuccess(true));
    // alert(res.data.message)
    if(res.status === 201){
      if(action.data.log_off_type === 'pre_logout'){
        localStorage.clear();
        yield put(actions.extendTimeCounter(0));
        yield put(actions.logoutSuccess(res.data.message,1))
      }else{
      localStorage.removeItem('threshold_time');
      localStorage.removeItem('end_time');
      yield localStorage.setItem("isConsultationStarted", false);
      localStorage.removeItem('extendTimeCounter');
      yield put(actions.extendTimeCounter(0));
      localStorage.removeItem('gracePeriodCounter');
      }
    }else{
      alert('Something Went Wrong !!!')
    }
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'doctor_pre_log_off_histories',
       method:'POST',
     })
    }
    yield put(actions.preLogOffConsultationFail(error));
  }
}

function* workerFetchScheduleSaga(action) {
  try {
    const res = yield call(doctorService.fetchTodaySchedule,action.slotParam)
    yield put(actions.fetchTodayScheduleSuccess(res.data.data));
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_doctor_slots',
       method:'GET',
     })
    }
    yield put(actions.fetchTodayScheduleFail(error));
  }
}

function* workerFetchAllScheduleSaga(action) {
  try {
    const res = yield call(doctorService.fetchTodaySchedule,action.slotParam)
    yield put(actions.fetchAllScheduleSuccess(res.data.data));

  } catch (error) {
    yield put(actions.fetchAllScheduleFail(error));
  }
}

function* workerAddScheduleSaga(action) {
  yield put(actions.scheduleAddStart());
  try {
    blockButton(action.id,true)
    let res = yield call(doctorService.SaveSchedule,action.formData)
    let user = yield res.json();
    if(errorResponsesArray.includes(parseInt(res.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:res.statusText,
       errorCode : res.status,
       endPoint:'save_schedule_slots',
       method:'POST',
     })
    }
    if (res.status === 201) {
    yield put(actions.scheduleAddSuccess(user.message));
    // alert(user.message)
    }else{
      alert(user.error)
    }
  } catch (error) {
    yield put(actions.scheduleAddFail(error));
    console.log(error.error)
  }
}










