import { takeEvery, call, put,delay } from "redux-saga/effects";
import { FETCH_CALENDER_COUNT_REQUEST,FETCH_CALENDER_APPOINTMENTS } from '../actions';
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherCalenderSaga() {
  yield takeEvery(FETCH_CALENDER_COUNT_REQUEST, calenderSaga);
  yield takeEvery(FETCH_CALENDER_APPOINTMENTS, calenderAppointmentSaga);

}

function* calenderSaga(action) {
  try {
    // yield delay(2000
    const response = yield call(doctorService.calenderCount,action.month);
    yield put( actions.fetchCalenderCountSuccess(response.data) );
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_doctor_appointments_count_by_month',
       method:'GET',
     })
    }
    yield put( actions.fetchCalenderCountFailure(error) );
  }
}

function* calenderAppointmentSaga(action) {
  yield put(actions.fetchCalenderAppointmentStart())
  try {
    yield delay(1000);
    const response = yield call(doctorService.calenderAppointment,action.date);
    yield put( actions.fetchCalenderAppointmentSuccess(response.data.data.data) );
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_doctor_appointments_by_date',
       method:'GET',
     })
    }
    yield put( actions.fetchCalenderAppointmentFailure(error) );
  }
}


