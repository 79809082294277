import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CardBody from "../../../components/Card/CardBody";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import axios from "../../../utils/axios1";
import Button from "../../../components/CustomButtons/Button";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import moment from "moment";
import CancelIcon from "@material-ui/icons/Cancel";
import Spinner from '../../../components/Spinner/Spinner'
import {
  TableBody,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TableHead
} from "@material-ui/core";
import { scheduleAdd,fetchAllSchedule } from "../../../actions";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { blockButton } from '../../../utils/helper'

import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "block",
    boxSizing: "border-box",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid black",
    padding: "14px 15px",
    marginTop: "9px",
    fontSize: "14px",
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ScheduleForm = (props) => {
  const { 
    hideEditScheduleHandle,
    fetchAllSchedule, 
    scheduleAdd,
    loader, 
    todaySlots
   } = props;
  const classes = useStyles();
  const [dayOfWeek, setDayOfWeek] = useState([]);
  const [slotDelete, setSlotDelete] = useState([]);


  useEffect(()=>{

    const encodeGetParams = (p) =>
    Object.entries(p)
      .map((kv) => kv.map(encodeURIComponent).join("="))
      .join("&");

    let slotParam ={
      id:localStorage.getItem("userId"),
      day_of_week:''
    }
    fetchAllSchedule(encodeGetParams(slotParam))
  },[])

  useEffect(() => {
    let mounted = true;

    const fetchDayOfWeek = () => {
      if (
        localStorage.getItem("jwt") !== "" ||
        localStorage.getItem("jwt") !== undefined
      ) {
        let token = "Bearer " + localStorage.getItem("jwt");
        axios
          .get(`/get_day_of_week_list `, {
            headers: { Authorization: token },
          })
          .then((response) => {
            if (mounted) {
              setDayOfWeek(response.data);
            }
          })
          .catch((error) => {
            if (error.response.data !== "") {
              alert(error.response.data.error);
            } else {
              alert(error.response.statusText);
            }
          });
      }
    };

    fetchDayOfWeek();

    return () => {
      mounted = false;
    };
  }, []);

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      schedule: [
        {
          id: "",
          day_of_week: "",
          slot_from: "",
          slot_to: "",
        },
      ],
    },
  });

  const {
    fields: scheduleFields,
    append: scheduleAppend,
    prepend: schedulePrepend,
    remove: scheduleRemove,
  } = useFieldArray({ control, name: "schedule" });

  const addExistingSlots = (item) => {
    schedulePrepend({
      id: item.attributes.id,
      day_of_week: item.attributes.day_of_week.key,
      slot_from: item.attributes.slot_from,
      slot_to: item.attributes.slot_to,
    });
  };

  useEffect(() => {
    if (todaySlots.length !== 0) {
      const timer = setTimeout(
        () => todaySlots.forEach((item) => addExistingSlots(item)),
        10
      );
      return () => clearTimeout(timer);
    }
  }, [todaySlots]);

  let idsArray = [];

  const deleteSlotMedicine = (id) => {
    if (slotDelete.includes(id)) {
      idsArray = slotDelete.filter((el) => el !== id);
    } else {
      idsArray = [...slotDelete, id];
    }
    setSlotDelete(idsArray);
  };

  const onSubmit = (data) => {
    console.log(data.schedule);

    var formData = new FormData();

    for (let i = 0; i < data.schedule.length; i++) {
      if (data.schedule[i].id === "" || isNaN(data.schedule[i].id)) {
        delete data.schedule[i].id;
      }
      if (data.schedule[i].day_of_week === "") {
         alert('Please select Day')
         return false
      }

      if (data.schedule[i].slot_from === "") {
        alert('Please select Slot from')
        return false
     }

     if (data.schedule[i].slot_to === "") {
      alert('Please select Slot to')
      return false
   }

   data.schedule[i].slotFromMs =Number(data.schedule[i].slot_from.split(':')[0]) * 60 * 60 * 1000 + Number(data.schedule[i].slot_from.split(':')[1]) * 60 * 1000
   data.schedule[i].slotToMs=Number(data.schedule[i].slot_to.split(':')[0]) * 60 * 60 * 1000 + Number(data.schedule[i].slot_from.split(':')[1]) * 60 * 1000

      if(data.schedule[i].slotFromMs>data.schedule[i].slotToMs){
        alert('Slot From time should not be greater than Slot To time')
        return false
      }
      if (slotDelete.includes(data.schedule[i].id)) {
        // data.schedule[i]._destroy = "1";
        data.schedule[i].status = 0;
      }
      delete data.schedule[i].slotFromMs;
      delete data.schedule[i].slotToMs;
      Object.keys(data.schedule[i]).forEach((key) => {
        formData.append(
          `doctor_schedules[${i}][${key}]`,
          data.schedule[i][key]
        );
      });
    }
    scheduleAdd(formData,'addschedule');
    hideEditScheduleHandle()
    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }
  };

  return (
    <div className={classes.root1}>
      <CardBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              {loader?<Spinner/> :
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                  <StyledTableRow>
                        <TableCell style={{ display:'none'}}></TableCell>
                        <TableCell
                          style={{ backgroundColor: "#6a7075", color: "white" }}
                        >
                          Day Of Week
                        </TableCell>
                        <TableCell
                          style={{ backgroundColor: "#6a7075", color: "white" }}
                        >
                          Slot From
                        </TableCell>
                        <TableCell
                          style={{ backgroundColor: "#6a7075", color: "white" }}
                        >
                          Slot To
                        </TableCell>
                        <TableCell
                          style={{ backgroundColor: "#6a7075", color: "white" }}
                        >
                          Delete
                        </TableCell>
                      </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {scheduleFields.map((item, index) => (
                      <StyledTableRow key={item.id}>
                        <TableCell style={{ display: "none" }}>
                          <Controller
                            as={<input />}
                            name={`schedule[${index}].id`}
                            control={control}
                            defaultValue={item.id}
                            type="hidden"
                          />
                        </TableCell>
                        <TableCell>
                          <FormControl style={{ width: 130 }}>
                            <InputLabel id="demo-simple-select-label">
                              Day Of Week
                            </InputLabel>
                            <Controller
                              as={
                                <Select>
                                  {dayOfWeek.map((option) => (
                                    <MenuItem
                                      key={option.key}
                                      value={option.key}
                                    >
                                      {option.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              }
                              name={`schedule[${index}].day_of_week`}
                              control={control}
                              defaultValue={item.day_of_week}
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <Controller
                            as={<TextField />}
                            name={`schedule[${index}].slot_from`}
                            label="Slot From"
                            style={{ width: 136, marginTop: 14 }}
                            type="time"
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                            control={control}
                            defaultValue={item.slot_from}
                          />
                        </TableCell>
                        <TableCell>
                          <Controller
                            as={<TextField />}
                            name={`schedule[${index}].slot_to`}
                            label="Slot To"
                            style={{ width: 136, marginTop: 14 }}
                            type="time"
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                            control={control}
                            defaultValue={item.slot_to}
                          />
                        </TableCell>
                        <TableCell>
                          {item.id === "" || isNaN(item.id) ? (
                            <HighlightOffIcon
                              className={classes.icon}
                              onClick={() => scheduleRemove(index)}
                            />
                          ) : (
                            <React.Fragment>
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={(id) =>
                                        deleteSlotMedicine(item.id)
                                      }
                                      value={item.id}
                                      color="primary"
                                    />
                                  }
                                />
                              </FormGroup>
                              {slotDelete.includes(item.id) ? (
                                <CancelIcon
                                  className={classes.icon}
                                  style={{ color: "red" }}
                                />
                              ) : null}
                            </React.Fragment>
                          )}
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>}
              <IconButton
                onClick={() =>
                  scheduleAppend({
                    day_of_week: "",
                    slot_from: "",
                    slot_to: "",
                  })
                }
              >
                <AddCircleIcon />
              </IconButton>
              <Button
                style={{ float: "right", width: 80 }}
                onClick={hideEditScheduleHandle}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                style={{
                  float: "right",
                  width: 80,
                  backgroundColor: "rgb(50, 123, 175)",
                }}
                onClick={handleSubmit}
                id="addschedule"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardBody>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    todaySlots: state.startConsultation.allSchedule,
    loader:state.startConsultation.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    scheduleAdd: (formData,id) => dispatch(scheduleAdd(formData,id)),
    fetchAllSchedule:(slotParam)=>dispatch(fetchAllSchedule(slotParam)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleForm);
