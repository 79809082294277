import { takeEvery, call, put,delay } from "redux-saga/effects";
import { FETCH_DIFFERENTIAL_REQUEST,DIFFERENTIAL_ADD,DIFFERENTIAL_ADD_BY_NAME } from '../actions';
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherDifferentialSaga() {
  yield takeEvery(FETCH_DIFFERENTIAL_REQUEST, differentialDiagnosisSaga);
  yield takeEvery(DIFFERENTIAL_ADD, workerdifferentialAddSaga);
  yield takeEvery(DIFFERENTIAL_ADD_BY_NAME, diseaseAddByNameSaga);


}

function* differentialDiagnosisSaga(action) {
  try {
    const response = yield call(doctorService.DifferentialDiagnosis,action.params);
    yield put( actions.fetchDifferentialSuccess(response.data) );
  } catch (error) {
    yield put( actions.fetchDifferentialFailure(error) );
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_icd10_differential_diagnosis',
       method:'GET',
     })
    }
  }
}


function* workerdifferentialAddSaga(action) {
  yield put(actions.DifferentialAddStart());
  try {

    yield put(actions.DifferentialAddSuccess('Disease Added Successfully',action.differential));
    yield  delay(2000)
    yield put(actions.DifferentialAddEmpty())

  } catch (error) {
    yield put(actions.DifferentialAddFail(error));
  }
}

function* diseaseAddByNameSaga(action) {
  yield put(actions.differentialAddByNameStart())
  try {
    yield put( actions.differentialAddByNameSuccess('Disease Added Successfully',action.diseaseName));
    yield  delay(1000)
    yield put(actions.DifferentialAddEmpty())
  } catch (error) {
    yield put( actions.differentialAddByNameFailure(error) );
  }
}


