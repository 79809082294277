import * as actionTypes from "actions";
const userInitialState = {
  loading:false,
  doctorNotes:'',
  instruction:''
};

const doctorNotesReducer = (state = userInitialState,  action) => {
  switch (action.type) {
    case actionTypes.SET_DOCTOR_NOTES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SET_DOCTOR_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        doctorNotes: action.payload,
        error: "",
      };
    case actionTypes.SET_DOCTOR_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        doctorNotes: '',
        error: action.payload,
      };
      case actionTypes.SET_INSTRUCTION_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.SET_INSTRUCTION_SUCCESS:
        return {
          ...state,
          loading: false,
          instruction: action.payload,
          error: "",
        };
      case actionTypes.SET_INSTRUCTION_FAILURE:
        return {
          ...state,
          loading: false,
          instruction: '',
          error: action.payload,
        };
    default:
      return state;
  }
};

export default doctorNotesReducer;
