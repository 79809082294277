import { takeEvery, call, put,delay } from "redux-saga/effects";
import { FETCH_PATHALOGY_TEST_REQUEST,FETCH_PATHALOGY_TEST_BY_NAME_REQUEST } from '../actions';
// import doctorService from "../services/doctorService";
import * as actions from "../actions";


export function* watcherPathologyTestSaga() {
  yield takeEvery(FETCH_PATHALOGY_TEST_REQUEST, pathologySelectedTestSaga);
  yield takeEvery(FETCH_PATHALOGY_TEST_BY_NAME_REQUEST, pathologySelectedTestByNameSaga);

}

function* pathologySelectedTestSaga(action) {
  try {
    yield put( actions.fetchPathologyTestSuccess(action.tests) );
  } catch (error) {
    yield put( actions.fetchPathologyTestFailure(error) );
  }
}

function* pathologySelectedTestByNameSaga(action) {
  try {
    yield put( actions.fetchPathologyTestByNameSuccess(action.testName) );
  } catch (error) {
    yield put( actions.fetchPathologyTestByNameFailure(error) );
  }
}
