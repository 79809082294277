import { takeEvery, call, put,delay } from "redux-saga/effects";
import { FETCH_RADIOLOGY_TEST_REQUEST,FETCH_RADIOLOGY_TEST_BY_NAME_REQUEST } from '../actions';
// import doctorService from "../services/doctorService";
import * as actions from "../actions";


export function* watcherradiologyTestSaga() {
  yield takeEvery(FETCH_RADIOLOGY_TEST_REQUEST, radiologySelectedTestSaga);
  yield takeEvery(FETCH_RADIOLOGY_TEST_BY_NAME_REQUEST, radiologySelectedTestByNameSaga);

}

function* radiologySelectedTestSaga(action) {
  try {
    yield put( actions.fetchRadiologyTestSuccess(action.tests,action.testName) );
  } catch (error) {
    yield put( actions.fetchRadiologyTestFailure(error) );
  }
}

function* radiologySelectedTestByNameSaga(action) {
  try {
    yield put( actions.fetchRadiologyTestByNameSuccess(action.testName) );
  } catch (error) {
    yield put( actions.fetchPathologyTestByNameFailure(error) );
  }
}
