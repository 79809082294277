import { takeEvery, call, put,delay } from "redux-saga/effects";
import { FETCH_VITAL_REQUEST,EDIT_VITALS } from '../actions';
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherVitalSaga() {
  yield takeEvery(FETCH_VITAL_REQUEST, vitalSaga);
  yield takeEvery(EDIT_VITALS, workerEditVitalSaga);


}

function* vitalSaga(action) {
  try {
    // yield delay(1000)
    let response = yield call(doctorService.fetchVital,action.patientId,action.visitId);
    yield put( actions.fetchVitalSuccess(response.data.data) );
    
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_patient_vitals_by_visit_id',
       method:'GET',
     })
    }
    yield put( actions.fetchVitalFailure(error) );
  }
}

function* workerEditVitalSaga(action) {
  yield put(actions.editVitalStart());

  try {
    const response = yield call(doctorService.editVital, action.formData,action.id);
    var updateVital = yield response.json();
    if(errorResponsesArray.includes(parseInt(response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:response.statusText,
       errorCode : response.status,
       endPoint:'save_patient_vitals',
       method:'PUT',
     })
    }
    if (response.status === 200) {
      yield put(actions.editVitalSuccess());
      // alert(updateVital.message)
      let res = yield call(doctorService.fetchVital,action.id,action.visitId);
      yield put( actions.fetchVitalSuccess(res.data.data) );
    }else{
      alert(updateVital.error)
    }

  } catch (error) {
    console.log(error);
    yield put(actions.editVitalFail(error));
  }
}



