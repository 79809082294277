import * as actionTypes from "actions";
const userInitialState = {
  loading: false,
  followUp: '',
  followUpType:'',
  followUpFor:'',
  error: "",
};

const followUpReducer = (state = userInitialState,  action) => {
  switch (action.type) {
    case actionTypes.SET_FOLLOW_UP_VALUES:
      return {
        ...state,
        loading: true,
      };
      case actionTypes.SET_FOLLOW_UP:
        return {
          ...state,
          loading: false,
          followUp: action.payload,
          error: "",
        };
    case actionTypes.SET_FOLLOW_UP_VALUES_EMPTY:
      return {
        ...state,
        loading: false,
        followUp: '',
        followUpType:'',
        followUpFor:'',
        error: "",
      };
      case actionTypes.SET_FOLLOW_UP_TYPE:
        return {
          ...state,
          loading: false,
          followUpType: action.payload,
          error: "",
        };
        case actionTypes.SET_FOLLOW_UP_FOR:
          return {
            ...state,
            loading: false,
            followUpFor: action.payload,
            error: "",
          };
    case actionTypes.SET_FOLLOW_UP_VALUES_FAILURE:
      return {
        ...state,
        loading: false,
        followUp: '',
        followUpFor:'',
        followUpType:'',
        error: action.payload,
      };
    default:
      return state;
  }
};

export default followUpReducer;
