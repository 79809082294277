import * as actionTypes from "actions";

const userInitialState = {
  loading: false,
  medicalProblem: [],
  error: "",
};

const medicalProblemReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MEDICALPROBLEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_MEDICALPROBLEM_SUCCESS:
      return {
        ...state,
        medicalProblem: action.payload,
        loading: false,
        error: "",
      };
    case actionTypes.FETCH_MEDICALPROBLEM_FAILURE:
      return {
        ...state,
        loading: false,
        medicalProblem: [],
        error: action.payload,
      };
      case actionTypes.EDIT_MEDICAL_START:
        return {
          ...state,
          ...{ loading: true, error: null },
        };
      case actionTypes.EDIT_MEDICAL_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case actionTypes.EDIT_MEDICAL_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
    default:
      return state;
  }
};

export default medicalProblemReducer;
