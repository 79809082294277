export const FETCH_VITAL_REQUEST = 'FETCH_VITAL_REQUEST';
export const FETCH_VITALS_SUCCESS = 'FETCH_VITALS_SUCCESS';
export const FETCH_VITALS_FAILURE = 'FETCH_VITALS_FAILURE';
export const EDIT_VITALS='EDIT_VITALS'
export const EDIT_VITALS_START='EDIT_VITALS_START'
export const EDIT_VITALS_SUCCESS='EDIT_VITALS_SUCCESS'
export const EDIT_VITALS_FAIL='EDIT_VITALS_FAIL'
export const EMPTY_LAST_PATIENT_VITALS='EMPTY_LAST_PATIENT_VITALS'

export const emptyLastPatientVital = () => {
  return {
    type: "EMPTY_LAST_PATIENT_VITALS",
  };
};

export const fetchVitalRequest = (patientId,visitId) => {
  return {
      type: FETCH_VITAL_REQUEST,
      patientId:patientId,
      visitId:visitId
  }
}

export const fetchVitalSuccess = vital => {
  return {
      type: FETCH_VITALS_SUCCESS,
      payload: vital
  }
}



export const fetchVitalFailure = error => {
  return {
      type: FETCH_VITALS_FAILURE,
      payload: error
  }
}
export const editVital = (formData,id,visitId) => {
  return {
      type: 'EDIT_VITALS',
      formData :formData,
      id:id,
      visitId:visitId
  }
};

export const editVitalStart = () => {
  return {
      type: 'EDIT_VITALS_START'
  }
};

export const editVitalSuccess = () => {
  return {
      type: 'EDIT_VITALS_SUCCESS',
  }
};

export const editVitalFail = (error) => {
  return {
      type: 'EDIT_VITALS_FAIL',
      error: error
  }
};
