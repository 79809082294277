import { takeEvery,takeLatest, call, put,delay } from "redux-saga/effects";
import { 
  FETCH_PATHOLOGY_SUGGESTION_REQUEST,
  FETCH_DIET_SUGGESTION_REQUEST,
  FETCH_RADIO_SUGGESTION_REQUEST,
  FETCH_EXERCISE_SUGGESTION_REQUEST 
} from '../actions';
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'


export function* watcherSuggestionSaga() {
  yield takeEvery(FETCH_RADIO_SUGGESTION_REQUEST, radioSuggestionSaga);
  yield takeLatest(FETCH_PATHOLOGY_SUGGESTION_REQUEST, pathologySuggestionSaga);
  yield takeLatest(FETCH_DIET_SUGGESTION_REQUEST, dietSuggestionSaga);
  yield takeLatest(FETCH_EXERCISE_SUGGESTION_REQUEST, exerciseSuggestionSaga);

}


function* pathologySuggestionSaga(action) {
  try {
    var response = yield call(doctorService.PathologySuggestion,action.params);
    yield put( actions.fetchPathologySuggestionSuccess(response.data,'Pathology Test') );
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_pathology_tests',
       method:'GET',
     })
    }
    yield put( actions.fetchPathologySuggestionFailure(error) );
  }
}

function* radioSuggestionSaga(action) {
  try {
    var response = yield call(doctorService.RadiologySuggestion,action.params);
    yield put( actions.fetchRadioSuggestionSuccess(response.data,'Radiology Test') );
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_radiology_tests',
       method:'GET',
     })
    }
    yield put( actions.fetchRadioSuggestionFailure(error) );
  }
}

function* dietSuggestionSaga(action) {
  try {
    var response = yield call(doctorService.DietSuggestion,action.params);
    yield put( actions.fetchDietSuggestionSuccess(response.data,'Diets') );
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_diet_notes',
       method:'GET',
     })
    }
    yield put( actions.fetchDietSuggestionFailure(error) );
  }
}


function* exerciseSuggestionSaga(action) {
  try {
    var response = yield call(doctorService.ExerciseSuggestion,action.params);
    yield put( actions.fetchExerciseSuggestionSuccess(response.data,'Exercises') );
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_exercises',
       method:'GET',
     })
    }
    yield put( actions.fetchExerciseSuggestionFailure(error) );
  }
}




