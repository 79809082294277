import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useTable from "../../../components/Table/useTable";
import { TableBody, TableRow, TableCell, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import Spinner from "../../../components/Spinner/Spinner";
const styles = (theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 7,
    marginTop: -30,
    float: "right",
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles(styles);

const headCells = [
  { id: "Patient Name", label: "Patient Name" },
  { id: "Appointment Date", label: "Appointment Date" },
  { id: "Appointment Time", label: "Appointment Time" },
];

const AppointmentTable = (props) => {
  const classes = useStyles();
  const { appointmentData, loading } = props;

  const { TblContainer, TblHead } = useTable(appointmentData, headCells);

  return (
    <div className={classes.root}>
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.results}>
            {loading ? (
              <Spinner />
            ) : (
              <TblContainer>
                <TblHead style={{ backgroundColor: "rgb(195, 191, 146)" }} />
                <TableBody>
                  {appointmentData.map((item) => (
                    <StyledTableRow key={item.id}>
                      <TableCell>{item.attributes.patient.name}</TableCell>
                      <TableCell>{item.attributes.appointment_date}</TableCell>
                      <TableCell>{item.attributes.appointment_time}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </TblContainer>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appointmentData: state.leave.appointmentData,
    loading: state.leave.loading,
  };
};

export default connect(mapStateToProps, null)(AppointmentTable);
