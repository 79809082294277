import React,{useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Calender from "../Dashboard/components/calender/Tabs";
import PatientPool from './components/PatientPool/PatientPool'
import { connect } from "react-redux";
import { 
  fetchPathologyTestRequest,
  resetMsoDetail,
  fetchPathologyTestByNameRequest,
  fetchRadiologyTestRequest,
  isReferredCase,
  fetchRadiologyTestByNameRequest,
  fetchDietTestRequest,
  fetchDietTestByName,
  fetchExerciseRequest,
  fetchExerciseByNameRequest,
  setDoctorNotesRequest,
  setInstructionRequest,
  DifferentialAdd,
  differentialAddByName,
  fetchUserInfoRequest,
  emptyInitialFeedback,
  resetFlowboardStatus,
  emptyLastVisitPrescription,
  resetPrescription,
  startVideoCall,
  emptyLastPatientVital
 } from '../../actions'

const styles = (theme) => ({
  root: {
  },
});
const useStyles = makeStyles(styles);

const Dashboard = (props) => {
  const { 
    fetchPathologyTestRequest,
    fetchPathologyTestByNameRequest,
    fetchRadiologyTestRequest,
    fetchRadiologyTestByNameRequest,
    fetchDietTestRequest,
    fetchDietTestByName,
    emptyInitialFeedback,
    isReferredCase,
    fetchExerciseRequest,
    fetchExerciseByNameRequest,
    setDoctorNotesRequest,
    setInstructionRequest,
    DifferentialAdd,
    differentialAddByName,
    fetchUserInfoRequest,
    resetFlowboardStatus,
    emptyLastVisitPrescription,
    resetMsoDetail,
    resetPrescription,
    emptyLastPatientVital,
    startVideoCall
   } = props;
  const classes = useStyles();
  useEffect(()=>{
    fetchPathologyTestRequest([])
    fetchPathologyTestByNameRequest([])
    fetchUserInfoRequest()
    isReferredCase(false)
    fetchRadiologyTestRequest([])
    fetchRadiologyTestByNameRequest([])
    fetchDietTestRequest([])
    fetchDietTestByName([])
    fetchExerciseRequest([])
    fetchExerciseByNameRequest([])
    emptyInitialFeedback()
    setDoctorNotesRequest([])
    setInstructionRequest([])
    DifferentialAdd([])
    differentialAddByName([])
    resetFlowboardStatus()
    emptyLastVisitPrescription([])
    emptyLastPatientVital()
    resetMsoDetail()
    resetPrescription()
    localStorage.removeItem("msoId");
    localStorage.removeItem("isVideoOn");
    startVideoCall(false)
  },[])
  return (
    <div className={classes.root} style={{ marginTop: "36px" }}>
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CustomTabs
          style={{ width: 332, padding: 2 }}
            headerColor="success"
            tabs={[
              {
                tabName: "Patient Pool",
                tabIcon: AssignmentIcon,
                tabContent: (
                  <React.Fragment>
                <PatientPool/>
                  </React.Fragment>
                ),
              },
              {
                tabName: "All Patient",
                tabIcon: CalendarTodayIcon,
                tabContent: (
                  <React.Fragment>
                    <div>
                      <Calender />
                    </div>
                  </React.Fragment>
                ),
              },
            ]}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = dispatch =>{
  return{
    fetchPathologyTestRequest :()=>dispatch(fetchPathologyTestRequest([])),
    fetchPathologyTestByNameRequest :()=>dispatch(fetchPathologyTestByNameRequest([])),
    fetchRadiologyTestRequest :()=>dispatch(fetchRadiologyTestRequest([])),
    fetchRadiologyTestByNameRequest: ()=>dispatch(fetchRadiologyTestByNameRequest([])),
    fetchDietTestRequest : ()=>dispatch(fetchDietTestRequest([])),
    fetchDietTestByName : ()=>dispatch(fetchDietTestByName([])),
    fetchExerciseRequest : ()=>dispatch(fetchExerciseRequest([])),
    fetchExerciseByNameRequest : ()=>dispatch(fetchExerciseByNameRequest([])),
    setDoctorNotesRequest : ()=>dispatch(setDoctorNotesRequest([])),
    setInstructionRequest : ()=>dispatch(setInstructionRequest([])),
    DifferentialAdd : ()=>dispatch(DifferentialAdd([])),
    differentialAddByName : ()=>dispatch(differentialAddByName([])),
    fetchUserInfoRequest: () => dispatch(fetchUserInfoRequest()),
    isReferredCase: (data) => dispatch(isReferredCase(data)),
    emptyInitialFeedback :()=>dispatch(emptyInitialFeedback()),
    resetFlowboardStatus :()=>dispatch(resetFlowboardStatus()),
    emptyLastVisitPrescription : ()=>dispatch(emptyLastVisitPrescription()),
    resetMsoDetail : ()=>dispatch(resetMsoDetail()),
    resetPrescription : ()=>dispatch(resetPrescription()),
    emptyLastPatientVital : ()=>dispatch(emptyLastPatientVital()), 
    startVideoCall: (data) =>
    dispatch(startVideoCall(data)),
  }
}

export default connect(null,mapDispatchToProps)(Dashboard);
