import * as actionTypes from "actions";
const userInitialState = {
  loading: false,
  message: '',
  status : 3,
  error: "",
};

const flowBoardStatusReducer = (state = userInitialState,  action) => {
  switch (action.type) {
    case actionTypes.REVIEW_PATIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.REVIEW_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload,
        error: "",
      };
    case actionTypes.REVIEW_PATIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.FETCH_FLOWBOARD_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_FLOWBOARD_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload,
        error: "",
      };
    case actionTypes.FETCH_FLOWBOARD_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        status: null,
        error: action.payload,
      };
    case actionTypes.FLOWBOARD_STATUS_CHANGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FLOWBOARD_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
        error: "",
      };
    case actionTypes.FLOWBOARD_STATUS_CHANGE_FAILURE:
      return {
        ...state,
        loading: false,
        message: '',
        error: action.payload,
      };
      case actionTypes.RESET_FLOWBOARD_STATUS:
        return {
          ...state,
          loading: false,
          message: '',
          status:3,
          error: '',
        };
    default:
      return state;
  }
};

export default flowBoardStatusReducer;
