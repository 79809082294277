export const FETCH_FAMILYHISTORY_REQUEST = 'FETCH_FAMILYHISTORY_REQUEST';
export const FETCH_FAMILYHISTORY_SUCCESS = 'FETCH_FAMILYHISTORY_SUCCESS';
export const FETCH_FAMILYHISTORY_FAILURE = 'FETCH_FAMILYHISTORY_FAILURE';

export const FETCH_FAMILYHISTORY_DATA_FOR_EDIT_REQUEST = 'FETCH_FAMILYHISTORY_DATA_FOR_EDIT_REQUEST';
export const FETCH_FAMILYHISTORY_DATA_FOR_EDIT_SUCCESS = 'FETCH_FAMILYHISTORY_DATA_FOR_EDIT_SUCCESS';
export const FETCH_FAMILYHISTORY_DATA_FOR_EDIT_FAILURE = 'FETCH_FAMILYHISTORY_DATA_FOR_EDIT_FAILURE';

export const EDIT_FAMILYHISTORY='EDIT_FAMILYHISTORY'
export const EDIT_FAMILYHISTORY_START='EDIT_FAMILYHISTORY_START'
export const EDIT_FAMILYHISTORY_SUCCESS='EDIT_FAMILYHISTORY_SUCCESS'
export const EDIT_FAMILYHISTORY_FAIL='EDIT_FAMILYHISTORY_FAIL'



export const fetchFamilyHistoryDataForEditRequest = (patientId) => {
  return {
      type: FETCH_FAMILYHISTORY_DATA_FOR_EDIT_REQUEST,
      patientId:patientId,
  }
}

export const fetchFamilyHistoryDataForEditSuccess = family => {
  return {
      type: FETCH_FAMILYHISTORY_DATA_FOR_EDIT_SUCCESS,
      payload: family
  }
}

export const fetchFamilyHistoryDataForEditFailure = error => {
  return {
      type: FETCH_FAMILYHISTORY_DATA_FOR_EDIT_FAILURE,
      payload: error
  }
}


export const fetchFamilyHistoryRequest = (patientId) => {
  return {
      type: FETCH_FAMILYHISTORY_REQUEST,
      patientId:patientId,
  }
}

export const fetchFamilyHistorySuccess = family => {
  return {
      type: FETCH_FAMILYHISTORY_SUCCESS,
      payload: family
  }
}

export const fetchFamilyHistoryFailure = error => {
  return {
      type: FETCH_FAMILYHISTORY_FAILURE,
      payload: error
  }
}

export const editFamilyHistory = (formData,id) => {
  return {
      type: 'EDIT_FAMILYHISTORY',
      formData :formData,
      id:id
  }
};

export const editFamilyHistoryStart = () => {
  return {
      type: 'EDIT_FAMILYHISTORY_START'
  }
};

export const editFamilyHistorySuccess = () => {
  return {
      type: 'EDIT_FAMILYHISTORY_SUCCESS',
  }
};

export const editFamilyHistoryFail = (error) => {
  return {
      type: 'EDIT_FAMILYHISTORY_FAIL',
      error: error
  }
};
