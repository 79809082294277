import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
// import "../../PatientDetail/component/Scroll.css";

import { Dialog, Grid, Typography } from "@material-ui/core";
import Controls from "../../../../../components/controls/Controls";
import Slide from "@material-ui/core/Slide";
import PrescriptionToSend from '../PrescriptionData/Prescription'
import Button from '../../../../../components/CustomButtons/Button'
import { connect } from "react-redux";
import moment from 'moment'




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  header: {
    margin: "0 auto",
    padding: 12,
    color: "blue",
  },
  content: {
    padding: theme.spacing(2),
    margin: "0 auto",
    marginTop:-50
  },
}));

const PrescriptionModal = (props) => {
  const { 
    open, 
    onClose,
    prescriptionRecord
   } = props;

  const classes = useStyles();
 const [prescriptionKey, setPrescriptionKey] = useState();
 const [prescription, setPrescription] = useState([]);
 
 var presShow = []
  const handlePrescription = (value) => {
    console.log(value);
    setPrescriptionKey(value);
    presShow = prescriptionRecord.filter(el=>el.id === value)
    console.log(presShow)
    setPrescription(presShow);
  };

  return (
    <Dialog
      onClose={onClose}
      TransitionComponent={Transition}
      open={open}
      fullWidth={true}
      maxWidth={"md"}
    >
      <div className={classes.root1} style={{ backgroundColor: "#f0f0d7" }}>
        <div className={classes.header}>
          <Typography align="center" gutterBottom variant="h5">
            Prescription
          </Typography>
        </div>
        <div className={classes.content}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} md={8}>
                    {prescriptionRecord.length === 0 ?
                    <Typography>No Prescription Found</Typography>:
                    <Controls.Select
                    name="prescriptionKey"
                    label="Prescription"
                    style={{ width:220 }}
                    value={prescriptionKey || ""}
                    onChange={(e)=>handlePrescription(e.target.value)}
                    options={prescriptionRecord.map((e) => ({
                      title: moment(e.attributes.created_at).format('YYYY-MM-DD'),
                      value: e,
                      id: e.id,
                    }))
                  }
          
                  /> 
                  
                  }
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Button style={{ marginLeft:'60%' }} onClick={onClose}>Cancel</Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {prescription.length !== 0 ?
                    <PrescriptionToSend  prescriptionToSend={prescription}/>:null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        </div>
      </div>
    </Dialog>
  );
};

PrescriptionModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

const mapStateToProps = state =>{
  return{
    prescriptionRecord: state.fetchpatient.searchPrescription,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionModal);
