export const FETCH_RADIOLOGY_TEST_REQUEST = 'FETCH_RADIOLOGY_TEST_REQUEST';
export const FETCH_RADIOLOGY_TEST_SUCCESS = 'FETCH_RADIOLOGY_TEST_SUCCESS';
export const FETCH_RADIOLOGY_TEST_FAILURE = 'FETCH_RADIOLOGY_TEST_FAILURE';

export const FETCH_RADIOLOGY_TEST_BY_NAME_REQUEST = 'FETCH_RADIOLOGY_TEST_BY_NAME_REQUEST';
export const FETCH_RADIOLOGY_TEST_BY_NAME_SUCCESS = 'FETCH_RADIOLOGY_TEST_BY_NAME_SUCCESS';
export const FETCH_RADIOLOGY_TEST_BY_NAME_FAILURE = 'FETCH_RADIOLOGY_TEST_BY_NAME_FAILURE';


export const fetchRadiologyTestRequest = (tests) => {
  console.log(tests)
  return {
      type: FETCH_RADIOLOGY_TEST_REQUEST,
      tests:tests,
  }
}

export const fetchRadiologyTestSuccess = (tests) => {
  return {
      type: FETCH_RADIOLOGY_TEST_SUCCESS,
      payload: tests,
  }
}

export const fetchRadiologyTestFailure = error => {
  return {
      type: FETCH_RADIOLOGY_TEST_FAILURE,
      payload: error
  }
}

export const fetchRadiologyTestByNameRequest = (testName) => {
  console.log(testName)
  return {
      type: FETCH_RADIOLOGY_TEST_BY_NAME_REQUEST,
      testName:testName,
  }
}

export const fetchRadiologyTestByNameSuccess = (testName) => {
  return {
      type: FETCH_RADIOLOGY_TEST_BY_NAME_SUCCESS,
      payload: testName,
  }
}

export const fetchPathologyTestByNameFailure = error => {
  return {
      type: FETCH_RADIOLOGY_TEST_BY_NAME_FAILURE,
      payload: error
  }
}
