import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";

import CardBody from "../../../components/Card/CardBody";
import Card from "../../../components/Card/Card";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Button from "../../../components/CustomButtons/Button";
import { fetchTodaySchedule } from "../../../actions";
import ReminderData from "./ReminderData";
import AddReminder from "./AddReminder";


const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "block",
    boxSizing: "border-box",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid black",
    padding: "14px 15px",
    marginTop: "9px",
    fontSize: "14px",
  },
}));

const Reminder = (props) => {
  const { fetchTodaySchedule } = props;
  const classes = useStyles();
  const [reminderModalOpen, setReminderModalOpen] = useState(false);
  const [count , setCount] = useState(0)

  const handleReminderOpen = () => {
    setReminderModalOpen(true);
  };

  const handleReminderClose = () => {
    setReminderModalOpen(false);
  };

  const IncCount = ()=>{
    setCount(count + 1 )
  }
console.log(count)
  return (
    <div className={classes.root1}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Card style={{ marginTop: 1, backgroundColor: "#fffff2" }}>
            <Grid item xs={12} sm={12} md={12}>
              <Button
                style={{ float: "right", backgroundColor: "rgb(50, 123, 175)" }}
                onClick={handleReminderOpen}
              >
                Add
              </Button>
              <AddReminder
                onClose={handleReminderClose}
                open={reminderModalOpen}
                IncCount={IncCount}
              />
            </Grid>
            <CardBody>
              <ReminderData IncCount={IncCount} count={count} />
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTodaySchedule: (slotParam) => dispatch(fetchTodaySchedule(slotParam)),
  };
};

export default connect(null, mapDispatchToProps)(Reminder);
