import * as actionTypes from "actions";
const doctorDesignation = localStorage.getItem('designation');

const INITIAL_STATE = {
  userId: null,
  error: null,
  goTo:0,
  logout:0,
  loading: false,
  status:null,
  designation:doctorDesignation
};

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_START:
      return {
        ...state,
        ...{ loading: true, error: null },
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        userId: action.userId,
        logout:0,
        goTo:1,
        loading: false,
        designation:action.designation ?? doctorDesignation
      };
    case actionTypes.LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
      case actionTypes.USER_LOGOUT_START:
        return {
          ...state,
          ...{ loading: true, error: null },
          // logout:1
        };
      case actionTypes.USER_LOGOUT_SUCCESS:
        return {
          ...state,
          logout:action.val,
          goTo:0,
          loading: false,
        };
      case actionTypes.USER_LOGOUT_FAIL:
        return {
          ...state,
          loading: false,
          logout:0,
          error: action.error,
        };
      case actionTypes.LOGIN_CHECK_TIMEOUT:
        return {
          ...state,
          loading: false,
          status:action.status,
          error: action.error,
        };
    default:
      return state;
  }
};

export default loginReducer;
