export const START_CONSULTATION = 'START_CONSULTATION';
export const START_CONSULTATION_START = 'START_CONSULTATION_START';
export const START_CONSULTATION_SUCCESS = 'START_CONSULTATION_SUCCESS';
export const START_CONSULTATION_FAILURE = 'START_CONSULTATION_FAILURE';

export const END_CONSULTATION = 'END_CONSULTATION';
export const END_CONSULTATION_START = 'END_CONSULTATION_START';
export const END_CONSULTATION_SUCCESS = 'END_CONSULTATION_SUCCESS';
export const END_CONSULTATION_FAILURE = 'END_CONSULTATION_FAILURE';

export const SCHEDULE_ADD = 'SCHEDULE_ADD';
export const SCHEDULE_ADD_START = 'SCHEDULE_ADD_START';
export const SCHEDULE_ADD_SUCCESS = 'SCHEDULE_ADD_SUCCESS';
export const SCHEDULE_ADD_FAILURE = 'SCHEDULE_ADD_FAILURE';

export const FETCH_TODAY_SCHEDULE = 'FETCH_TODAY_SCHEDULE';
export const FETCH_TODAY_SUCCESS = 'FETCH_TODAY_SUCCESS';
export const FETCH_TODAY_FAILURE = 'FETCH_TODAY_FAILURE';

export const FETCH_ALL_SCHEDULE = 'FETCH_ALL_SCHEDULE';
export const FETCH_ALL_SCHEDULE_SUCCESS = 'FETCH_ALL_SCHEDULE_SUCCESS';
export const FETCH_ALL_SCHEDULE_FAILURE = 'FETCH_ALL_SCHEDULE_FAILURE';
export const FETCH_ALL_SCHEDULE_EMPTY = 'FETCH_ALL_SCHEDULE_EMPTY';

export const PRE_LOGOFF_CONSULTATION = 'PRE_LOGOFF_CONSULTATION';
export const PRE_LOGOFF_CONSULTATION_START = 'PRE_LOGOFF_CONSULTATION_START';
export const PRE_LOGOFF_CONSULTATION_SUCCESS = 'PRE_LOGOFF_CONSULTATION_SUCCESS';
export const PRE_LOGOFF_CONSULTATION_FAILURE = 'PRE_LOGOFF_CONSULTATION_FAILURE';

export const EXTEND_TIME_COUNTER = 'EXTEND_TIME_COUNTER';
export const IS_CLOSE_EXTEND_TIME_MODAL = 'IS_CLOSE_EXTEND_TIME_MODAL';

export const IS_START_CONSULTATION_ON_SLOT_CHECK = 'IS_START_CONSULTATION_ON_SLOT_CHECK';

export const isStartConsultationOnSlotCheck = (data) => {
  return {
      type: 'IS_START_CONSULTATION_ON_SLOT_CHECK',
      payload:data
  }
};

export const extendTimeCounter = (count) => {
  return {
      type: 'EXTEND_TIME_COUNTER',
      count:count
  }
};

export const isCloseExtendTimeMOdal = (data) => {
  return {
      type: 'IS_CLOSE_EXTEND_TIME_MODAL',
      data:data
  }
};

export const fetchAllSchedule = (slotParam) => {
  return {
      type: 'FETCH_ALL_SCHEDULE',
      slotParam:slotParam
  }
};


export const fetchAllScheduleSuccess = (data) => {
  return {
      type: 'FETCH_ALL_SCHEDULE_SUCCESS',
      payload: data,
  }
};


export const fetchAllScheduleFail = (error) => {
  return {
      type: 'FETCH_ALL_SCHEDULE_FAILURE',
      error: error
  }
};


export const fetchTodaySchedule = (slotParam) => {
  return {
      type: 'FETCH_TODAY_SCHEDULE',
      slotParam:slotParam
  }
};


export const fetchTodayScheduleSuccess = (data) => {
  return {
      type: 'FETCH_TODAY_SUCCESS',
      payload: data,
  }
};


export const fetchTodayScheduleFail = (error) => {
  return {
      type: 'FETCH_TODAY_FAILURE',
      error: error
  }
};


export const scheduleAdd = (formData,id) => {
  return {
      type: 'SCHEDULE_ADD',
      formData:formData,
      id:id
  }
};

export const scheduleAddStart = () => {
  return {
      type: 'SCHEDULE_ADD_START'
  }
};

export const scheduleAddSuccess = (data) => {
  return {
      type: 'SCHEDULE_ADD_SUCCESS',
      payload: data,
  }
};


export const scheduleAddFail = (error) => {
  return {
      type: 'SCHEDULE_ADD_FAILURE',
      error: error
  }
};




export const startConsultation = (slotsCheck) => {
  return {
      type: 'START_CONSULTATION',
      slotsCheck:slotsCheck
  }
};

export const startConsultationStart = () => {
  return {
      type: 'START_CONSULTATION_START'
  }
};

export const startConsultationSuccess = (data,isSlot) => {
  return {
      type: 'START_CONSULTATION_SUCCESS',
      payload: data,
      isSlot:isSlot
  }
};


export const startConsultationFail = (error) => {
  return {
      type: 'START_CONSULTATION_FAILURE',
      error: error
  }
};

export const endConsultation = (time,check,endTime,isGrace) => {
  return {
      type: 'END_CONSULTATION',
      time:time,
      check:check,
      endTime:endTime,
      isGrace:isGrace
  }
};

export const endConsultationStart = () => {
  return {
      type: 'END_CONSULTATION_START'
  }
};

export const endConsultationSuccess = (data) => {
  return {
      type: 'END_CONSULTATION_SUCCESS',
      payload: data,
  }
};


export const endConsultationFail = (error) => {
  return {
      type: 'END_CONSULTATION_FAILURE',
      error: error
  }
};

export const preLogOffConsultation = (data) => {
  return {
      type: 'PRE_LOGOFF_CONSULTATION',
      data:data
  }
};

export const preLogOffConsultationStart = () => {
  return {
      type: 'PRE_LOGOFF_CONSULTATION_START'
  }
};

export const preLogOffConsultationSuccess = (data) => {
  return {
      type: 'PRE_LOGOFF_CONSULTATION_SUCCESS',
      payload: data,
  }
};


export const preLogOffConsultationFail = (error) => {
  return {
      type: 'PRE_LOGOFF_CONSULTATION_FAILURE',
      error: error
  }
};