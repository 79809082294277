import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useTable from "../../components/Table/useTable";
import { TableBody, TableRow, TableCell, Grid,colors } from "@material-ui/core";
import { connect } from "react-redux";
import { leaveDelete, fetchLeaveRequest } from "../../actions";
import Controls from '../../components/controls/Controls'
import DeleteIcon from "@material-ui/icons/Delete";
import Spinner from "../../components/Spinner/Spinner";
import moment from 'moment'
const styles = (theme) => ({
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles(styles);

const headCells = [
  { id: "Leave Type", label: "Leave Type" },
  { id: "Start Date", label: "Start Date" },
  { id: "End Date", label: "End Date" },
  { id: "Approval Status", label: "Approval Status" },
  { id: "Status", label: "Status" },
  { id: "Action", label: "Action" },
];

const LeaveData = (props) => {
  const classes = useStyles();
  const { leaves, fetchLeaveRequest, leaveDelete, loading } = props;
  const [count, setCount] = useState(0);
  const [filterBy,setFilterBy]=useState()
  const { TblContainer, TblHead } = useTable(leaves, headCells);

  const statusColors = {
    Pending: colors.orange[600],
    Cancelled: colors.red[600],
    Rejected : colors.red[600],
    Approved: colors.green[600],
    Active: colors.green[600],
  };

  useEffect(() => {
    const encodeGetParams = (p) =>
      Object.entries(p)
        .map((kv) => kv.map(encodeURIComponent).join("="))
        .join("&");
    const param = {
      approval_status: "",
    };
    fetchLeaveRequest(encodeGetParams(param));
  }, [count]);
  const deleteLeave = (id) => {
    if (window.confirm("Do you want to cancel this Leave ?")) {
      console.log(id);
      leaveDelete(id);
      setCount(count + 1);
      const encodeGetParams = (p) =>
        Object.entries(p)
          .map((kv) => kv.map(encodeURIComponent).join("="))
          .join("&");
      const param = {
        approval_status: "",
      };
      fetchLeaveRequest(encodeGetParams(param));
    }
  };
  const handleFilterBy =(value)=>{
    console.log(value)
  }
  console.log(count);
  return (
    <div className={classes.root}>
      <Grid>

      <Grid item xs={12} sm={12} md={12}>
      <div style={{ float:'right' }}>
      <Controls.Select
        name="filter_by"
        label="Filter By"
        style={{ float:'right',width:170 }}
        value={filterBy || ""}
        options={filterOption.map((e) => ({
          title: e.title,
          value: e.id,
          id: e.id,
        }))}
        onChange={(e)=>handleFilterBy(e.target.value)}
      />
            </div>
        </Grid>
  
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.results}>
            {loading ? (
              <Spinner />
            ) : (
              <TblContainer>
                <TblHead style={{ backgroundColor: "rgb(76, 75, 70)" }} />
                <TableBody>
                  {leaves.map((item) => (
                    <StyledTableRow key={item.id}>
                      <TableCell>{item.attributes.leave_type}</TableCell>
                      <TableCell>
                        {moment.utc(item.attributes.leave_start_time)
                      .local()
                      .format(`${item.attributes.leave_type === 'day' || item.attributes.leave_type === 'period' ? 
                      'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'}`)}
                      </TableCell>
                      <TableCell> 
                        {item.attributes.leave_end_time ? moment.utc(item.attributes.leave_end_time)
                      .local()
                      .format(`${item.attributes.leave_type === 'day' || item.attributes.leave_type === 'period' ? 
                      'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'}`) : ''}
                      </TableCell>
                      <TableCell style={{  color: statusColors[item.attributes.approval_status.value] }}>
                        {item.attributes.approval_status.value}
                      </TableCell>
                      <TableCell style={{  color: statusColors[item.attributes.status.value] }}>{item.attributes.status.value}</TableCell>
                      {item.attributes.status.value === 'Active' &&item.attributes.approval_status.value === 'Pending' ? (
                        <TableCell>
                          <DeleteIcon
                            style={{
                              width: 60,
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={(id) => deleteLeave(item.attributes.id)}
                          />
                        </TableCell>
                      ) : null}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </TblContainer>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    leaves: state.leave.leaves,
    loading: state.leave.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLeaveRequest: (url) => dispatch(fetchLeaveRequest(url)),
    leaveDelete: (id) => dispatch(leaveDelete(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaveData);

const filterOption = [
  {id:'all',title:'All'},
  {id:'pending',title:'Pending'},
  {id:'approved',title:'Approved'},
  {id:'rejected',title:'Rejected'},
]