import * as actionTypes from "actions";
const userInitialState = {
  loading: false,
  vital: [],
  // showForm:null,
  error: "",
  // noshow:null
};

const vitalReducer = (state = userInitialState,  action) => {
  switch (action.type) {
    case actionTypes.FETCH_VITAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_VITALS_SUCCESS:
      return {
        ...state,
        loading: false,
        vital: action.payload,
        error: "",
      };
    case actionTypes.FETCH_VITALS_FAILURE:
      return {
        ...state,
        loading: false,
        vital: [],
        error: action.payload,
      };
      case actionTypes.EDIT_VITALS_START:
        return {
          ...state,
          ...{ loading: true, error: null },
        };
      case actionTypes.EDIT_VITALS_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case actionTypes.EDIT_VITALS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
        case actionTypes.EMPTY_LAST_PATIENT_VITALS:
          return {
            ...state,
            loading: false,
            vital: [],
            error: "",
          };
    default:
      return state;
  }
};

export default vitalReducer;
