export const FETCH_LEAVE_REQUEST = 'FETCH_LEAVE_REQUEST';
export const FETCH_LEAVE_SUCCESS = 'FETCH_LEAVE_SUCCESS';
export const FETCH_LEAVE_FAILURE = 'FETCH_LEAVE_FAILURE';

export const LEAVE_ADD = 'LEAVE_ADD';
export const LEAVE_ADD_START = 'LEAVE_ADD_START';
export const LEAVE_ADD_SUCCESS = 'LEAVE_ADD_SUCCESS';
export const LEAVE_ADD_FAILURE = 'LEAVE_ADD_FAILURE';

export const LEAVE_DELETE = 'LEAVE_DELETE';
export const LEAVE_DELETE_START = 'LEAVE_DELETE_START';
export const LEAVE_DELETE_SUCCESS = 'LEAVE_DELETE_SUCCESS';
export const LEAVE_DELETE_FAILURE = 'LEAVE_DELETE_FAILURE';

export const FETCH_PATIENT_APPOINTMENTS = 'FETCH_PATIENT_APPOINTMENTS';
export const FETCH_PATIENT_APPOINTMENTS_SUCCESS = 'FETCH_PATIENT_APPOINTMENTS_SUCCESS';
export const FETCH_PATIENT_APPOINTMENTS_FAILURE = 'FETCH_PATIENT_APPOINTMENTS_FAILURE';



export const fetchPatientAppointmentRequest = (url) => {
  return {
      type: FETCH_PATIENT_APPOINTMENTS,
      url:url
  }
}

export const fetchPatientAppointmentSuccess = data => {
  return {
      type: FETCH_PATIENT_APPOINTMENTS_SUCCESS,
      payload: data
  }
}

export const fetchPatientAppointmentFailure = error => {
  return {
      type: FETCH_PATIENT_APPOINTMENTS_FAILURE,
      payload: error
  }
}



export const fetchLeaveRequest = (url) => {
  return {
      type: FETCH_LEAVE_REQUEST,
      url:url
  }
}

export const fetchLeaveSuccess = leaves => {
  return {
      type: FETCH_LEAVE_SUCCESS,
      payload: leaves
  }
}

export const fetchLeaveFailure = error => {
  return {
      type: FETCH_LEAVE_FAILURE,
      payload: error
  }
}



export const leaveAdd = (doctor_leave,id) => {
  return {
      type: 'LEAVE_ADD',
      doctor_leave:doctor_leave,
      id:id
  }
};

export const leaveAddStart = () => {
  return {
      type: 'LEAVE_ADD_START'
  }
};

export const leaveAddSuccess = (data) => {
  return {
      type: 'LEAVE_ADD_SUCCESS',
      payload: data,
  }
};


export const leaveAddFail = (error) => {
  return {
      type: 'LEAVE_ADD_FAILURE',
      error: error
  }
};


export const leaveDelete = (id) => {
  return {
      type: 'LEAVE_DELETE',
      id:id
  }
};

export const leaveDeleteStart = () => {
  return {
      type: 'LEAVE_DELETE_START'
  }
};

export const leaveDeleteSuccess = (data) => {
  return {
      type: 'LEAVE_DELETE_SUCCESS',
      payload: data,
  }
};


export const leaveDeleteFail = (error) => {
  return {
      type: 'LEAVE_DELETE_FAILURE',
      error: error
  }
};


