import { takeEvery, call, put,takeLatest } from "redux-saga/effects";
import { ALLERGY_ADD,FETCH_ALLERGY_REQUEST,FETCH_FORM_ALLERGY_REQUEST } from "../actions";
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherAllergySaga() {
  yield takeLatest(ALLERGY_ADD, workerAllergyAddSaga);
  yield takeLatest(FETCH_ALLERGY_REQUEST,workerFetchAllergySaga)
  yield takeEvery(FETCH_FORM_ALLERGY_REQUEST,workerFetchFormAllergySaga)
}

function* workerFetchAllergySaga(action) {
  try {
    const res = yield call(doctorService.fetchAllergy,action.patientId)
    // console.log(response.data)
    yield put(actions.fetchAllergySuccess(res.data.data));

  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_patient_allergies_by_patient_id',
       method:'GET',
     })
 } 
    yield put(actions.fetchAllergyFailure(error));
  }
}

function* workerFetchFormAllergySaga(action) {
  try {
    const res = yield call(doctorService.fetchAllergy,action.patientId)
    // console.log(response.data)
    yield put(actions.fetchFormAllergySuccess(res.data.data));

  } catch (error) {
    yield put(actions.fetchFormAllergyFailure(error));
  }
}

function* workerAllergyAddSaga(action) {
  yield put(actions.allergyAddStart());
  try {
    const res = yield call(doctorService.SaveAllergy,action.id,action.formData)
    var allergy = yield res.json();
    if(errorResponsesArray.includes(parseInt(res.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:res.statusText,
       errorCode : res.status,
       endPoint:'save_patient_allergies',
       method:'PUT',
     })
    }
    if (res.status === 200) {
      yield put(actions.allergyAddSuccess());
      alert(allergy.message)
    }else{
      alert(allergy.error)
    }

  } catch (error) {
    yield put(actions.allergyAddFail(error));
  }
}





