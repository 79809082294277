import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useTable from "../../../../components/Table/useTable";
import { TableBody, TableRow, TableCell, Grid,Typography } from "@material-ui/core";
import { connect } from "react-redux";
import Spinner from '../../../../components/Spinner/Spinner'
import moment from 'moment';

const styles = (theme) => ({
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles(styles);

const headCells = [
  { id: "Time", label: "Time" },
  { id: "Patient", label: "Patient" },
];

const AppointmentList = (props) => {
  const { list,listLoader,date } = props;
  const classes = useStyles();
  const [records, setRecords] = useState([]);

  const { TblContainer, TblHead } = useTable(records, headCells);
  return (
    <div className={classes.root}>
      {listLoader ?<Spinner/>:
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
         <Typography style={{ color:'rgb(58, 62, 58)',fontWeight:500,textAlign:'center' }}>
            {moment(date).format('DD MMM YYYY')}
            </Typography>  
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.results}>
            <TblContainer>
              <TblHead />
              <TableBody>
              {list.map((doc) => (
                  <StyledTableRow hover key={doc.id}>
                    <TableCell>
                      {moment.utc(doc.attributes.appointment_datetime)
                      .local()
                      .format("HH:mm")}
                    </TableCell>
                    <TableCell>{doc.attributes.patient.name}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </TblContainer>
          </div>
        </Grid>
      </Grid>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.calender.appointments,
    listLoader:state.calender.loading,
    date:state.calender.date

  };
};

export default connect(mapStateToProps, null)(AppointmentList);
