import React, { useState} from "react";
import { Grid, TextField, Dialog, Typography,Button } from "@material-ui/core";

import CardBody from "../../../components/Card/CardBody";
// import Button from "../../../components/CustomButtons/Button";
import PropTypes from "prop-types";

import { reminderAdd } from "../../../actions";
import { connect } from "react-redux";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { blockButton } from '../../../utils/helper'

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "block",
    boxSizing: "border-box",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid black",
    padding: "14px 15px",
    marginTop: "9px",
    fontSize: "14px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddReminder = (props) => {
  const { onClose, open, reminderAdd, IncCount } = props;
  const [startDate, setStartDate] = useState("");
  const [reminderText, setReminderText] = useState("");

  const classes = useStyles();

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <input
        onClick={props.onClick}
        value={props.value}
        className={classes.input}
        type="text"
        placeholder="Select Start Date Time "
        readOnly={true}
      />
    );
  });

  const handleReminderText = (value) => {
    setReminderText(value);
  };

  const handleStartDate = (value) => {
    setStartDate(value);
    console.log(value);
  };

  const handleSubmit = () => {
    if(!startDate){
      alert("Please select start date time");
      return false;
    }
    if(!reminderText){
      alert("Please Type Reminder");
      return false;
    }
    let d1 = new Date();
    if (new Date(d1).getTime() <= new Date(startDate).getTime()) {
    } else {
      alert("You are selecting past start time.Please Select Valid Time");
      return false;
    }
    let date = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
    let newStartDate = new Date(date).toISOString();

    const doctor_reminder = {
      reminder_text: reminderText,
      reminder_datetime: newStartDate,
    };
    reminderAdd(doctor_reminder,'addreminder');
    IncCount();
    onClose();
    console.log(doctor_reminder);
  };

  return (
    <Dialog
      onClose={onClose}
      TransitionComponent={Transition}
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      
    >
      <div>
        <div className={classes.header}>
          <Typography
            align="center"
            gutterBottom
            variant="h5"
            style={{ color: "blue",marginTop:18,fontSize:18 }}
          >
            Add Reminder
          </Typography>
        </div>
        <CardBody>
          <Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                  <ReactDatePicker
                    valueName="selected"
                    onChange={(date) => handleStartDate(date)}
                    selected={startDate}
                    name="start_datetime"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    timeCaption="time"
                    dateFormat="yyyy-MM-dd HH:mm "
                    minDate={moment().toDate()}
                    isClearable
                    customInput={<CustomInput />}
                    popperPlacement="auto"
                    popperModifiers={{
                      flip: {
                        // enabled: false,
                      },
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Reminder"
                    name="reminder_text"
                    onChange={(e) => handleReminderText(e.target.value)}
                    value={reminderText || ""}
                    multiline
                    style={{ width:'100%',marginTop:20 }}
                    rows={4}
                    // defaultValue=""
                    variant="outlined"
                  />
                </Grid>
               
                <Grid item xs={12} sm={12} md={12} >
                  <div style={{ marginTop: 60,display:'flex',justifyContent:'center',marginBottom:24,gap:6  }}>
                  <Button
                    onClick={handleSubmit}
                    id="addreminder"
                    style={{
                      backgroundColor: "rgb(50, 123, 175)",
                      color:'white'
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    onClick={onClose}
                    
                  >
                    Cancel
                  </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardBody>
      </div>
    </Dialog>
  );
};

AddReminder.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    reminderAdd: (doctor_reminder,id) => dispatch(reminderAdd(doctor_reminder,id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddReminder);
