import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, InputAdornment,TablePagination,Typography,Divider } from "@material-ui/core";
import Controls from "../../../../components/controls/Controls";
import PatientCard from "./PatientCard/PatientCard";
import { Search } from "@material-ui/icons";
import baseUrl from "../../../../utils/baseUrl";
import { fetchPatientRequest,emptyLastPatientVital } from "../../../../actions";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { connect } from "react-redux";
import Spinner from "../../../../components/Loader/Loader";

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 7,
    marginTop: -70,
    float: "right",
  },
  result: {
    marginTop: 30,
  },
}));

const PatientPool = (props) => {
  const classes = useStyles();
  const { fetchPatientRequest, loading,patient,rowCount,patientCounter,emptyLastPatientVital } = props;
  const [patientPool, setPool] = useState(null);
  const [poolOptions, setPoolOptions] = useState([]);
  const [filteredPatient, setFilteredPatient] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue,setFilterValue] = useState('All')
  const [feedbackCounter ,setFeedbackCounter] = useState(0)

  useEffect(() => {
    let jwt = localStorage.getItem("jwt")
    if (jwt) {
      fetchPatientRequest("individual");
      emptyLastPatientVital()
      return () => {
        setPoolOptions([]);
      };
    }
  }, [filterValue,patientCounter,feedbackCounter]);

  useEffect(() => {
    if(search !== ''){
      setFilteredPatient(
        patient.filter((patient) => {
          return patient.attributes.patient.name
            .toLowerCase()
            .includes(search.toLowerCase());
        })
      );
    }else if(filterValue === 'All'){
      setFilteredPatient(
        patient.filter((patient) => {
          return patient.attributes.patient.name
            .toLowerCase()
            .includes(search.toLowerCase());
        })
      );
    }else if(filterValue === 'Available Appointment'){
      setFilteredPatient(
        patient.filter((patient) => {
          return patient.attributes.flow_board.status_key !== 10 &&
          patient.attributes.flow_board.status_key !== 9 &&
          patient.attributes.flow_board.status_key !== 8 
        })
      );
    }

  }, [search, patient,filterValue]);

  const handleFeedbackCounter = ()=>{
    setFeedbackCounter(feedbackCounter + 1)
  }

  const handleChangePageOfPatient = (event, page) => {
    console.log(patientPool)
    setPage(page);
    const encodeGetParams = (p) =>
      Object.entries(p)
        .map((kv) => kv.map(encodeURIComponent).join("="))
        .join("&");

    const params = {
      page: page + 1,
      items: rowsPerPage,
    };
    if(patientPool === 'individual' || patientPool === null){
      fetchPatientRequest("individual",encodeGetParams(params));
    }else{
      fetchPatientRequest(patientPool,encodeGetParams(params))
    }
  };

  const handleChangeRowsPerPageOfPatient = (event) => {
    console.log(patientPool)
    setRowsPerPage(event.target.value);

    const encodeGetParams = (p) =>
      Object.entries(p)
        .map((kv) => kv.map(encodeURIComponent).join("="))
        .join("&");

    const params = {
      page: page + 1,
      items: event.target.value,
    };
    if(patientPool === 'individual' || patientPool === null){
      fetchPatientRequest("individual",encodeGetParams(params));
    }else{
      fetchPatientRequest(patientPool,encodeGetParams(params))
    }

  };

  const filterOptionComponent = ()=>{
    return(

      <Controls.RadioGroup
        name="filterPatient"
        value={filterValue}
        style={{ color:'rgb(33 156 55)' }}
        onChange={(e)=>setFilterValue(e.target.value)}
        items={filterPatientOption}
        />
    )
  }

  return (
    <div className={classes.root}>
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.header}>
            <Controls.Input
              label="Search Patient"
              className={classes.searchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </Grid>
        <div>
        <Grid container>
            <Grid container direction="row"  item xs={12} sm={12} md={12}>
            <Typography variant="h6" style={{ fontSize:14 }}>Filter Patient:</Typography>
            <div style={{ marginLeft:5,marginTop:-9 }}>
            {filterOptionComponent()}
            </div>
          </Grid>
          <Divider style={{ width: "28%" }} />
          </Grid>
          <div className={classes.result}>
            <Grid container spacing={1}>
              {loading ? <Spinner/>:
              <React.Fragment>
              {filteredPatient.map((doctor) => (
                <Grid item key={doctor.id} md={3} sm={3} xs={12}>
                  <PatientCard 
                  setPage={setPage}
                  setPool={setPool} 
                  setRowsPerPage={setRowsPerPage} 
                  handleFeedbackCounter={handleFeedbackCounter}
                  doctor={doctor} 
                  />
                </Grid>
              ))}
              </React.Fragment>
              }
            </Grid>
            <div className={classes.paginate}>
            <TablePagination
              component="div"
              count={parseInt(rowCount)}
              onChangePage={handleChangePageOfPatient}
              onChangeRowsPerPage={
                handleChangeRowsPerPageOfPatient
              }
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[2,5, 10, 25, 50]}
            />
          </div>
          </div>
        </div>
      </Grid>
    </div>
  );
};

const mapsStateToProps = (state) => {
  return {
    loading: state.fetchpatient.loading,
    patient:state.fetchpatient.patients,
    rowCount:state.fetchpatient.rowCount,
    patientCounter:state.fetchpatient.patientCounter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPatientRequest: (selectedPatient,url) =>
      dispatch(fetchPatientRequest(selectedPatient,url)),
      emptyLastPatientVital : ()=>dispatch(emptyLastPatientVital()), 
  };
};

export default connect(mapsStateToProps, mapDispatchToProps)(PatientPool);

const filterPatientOption = [
  {id:'All',title:'All'},
  {id :'Available Appointment',title:'Available Appointment'}
]
