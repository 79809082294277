export const FORMULARY_SEARCH = 'FORMULARY_SEARCH';
export const FORMULARY_SEARCH_START = 'FORMULARY_SEARCH_START';
export const FORMULARY_SEARCH_SUCCESS = 'FORMULARY_SEARCH_SUCCESS';
export const FETCH_FORMULARY_EMPTY = 'FETCH_FORMULARY_EMPTY';
export const FORMULARY_SEARCH_FAIL = 'FORMULARY_SEARCH_FAIL';

export const FORMULARY_FORMULARY_BRANDDETAIL = 'FORMULARY_FORMULARY_BRANDDETAIL';
export const FETCH_FORMULARY_BRANDDETAIL_START = 'FETCH_FORMULARY_BRANDDETAIL_START';
export const FETCH_FORMULARY_BRANDDETAIL_SUCCESS = 'FETCH_FORMULARY_BRANDDETAIL_SUCCESS';
export const FETCH_FORMULARY_BRANDDETAIL_FAILURE = 'FETCH_FORMULARY_BRANDDETAIL_FAILURE';

export const FORMULARY_ADD = 'FORMULARY_ADD';
export const FORMULARY_ADD_START = 'FORMULARY_ADD_START';
export const FORMULARY_ADD_SUCCESS = 'FORMULARY_ADD_SUCCESS';
export const FORMULARY_ADD_EMPTY = 'FORMULARY_ADD_EMPTY';
export const FORMULARY_ADD_FAILURE = 'FORMULARY_ADD_FAILURE';

export const FORMULARY_TEMP_SEARCH = 'FORMULARY_TEMP_SEARCH';
export const FORMULARY_TEMP_SEARCH_START = 'FORMULARY_TEMP_SEARCH_START';
export const FORMULARY_TEMP_SEARCH_SUCCESS = 'FORMULARY_TEMP_SEARCH_SUCCESS';
export const FORMULARY_TEMP_SEARCH_FAIL = 'FORMULARY_TEMP_SEARCH_FAIL';

export const FORMULARY_TEMP_SEARCH_ADD = 'FORMULARY_TEMP_SEARCH_ADD';
export const FORMULARY_TEMP_SEARCH_ADD_START = 'FORMULARY_TEMP_SEARCH_ADD_START';
export const FORMULARY_TEMP_SEARCH_ADD_SUCCESS = 'FORMULARY_TEMP_SEARCH_ADD_SUCCESS';
export const FORMULARY_TEMP_SEARCH_ADD_FAIL = 'FORMULARY_TEMP_SEARCH_ADD_FAIL';




export const formularySearch = (formulary) => {
  return {
      type: 'FORMULARY_SEARCH',
      act:formulary.act,
      term: formulary.term,
  }
};

export const formularySearchStart = () => {
  return {
      type: 'FORMULARY_SEARCH_START'
  }
};

export const formularySearchSuccess = (data) => {
  return {
      type: 'FORMULARY_SEARCH_SUCCESS',
      payload: data,
  }
};

export const formularyTempSearch = (tempFormulary) => {
  return {
      type: 'FORMULARY_TEMP_SEARCH',
      tempFormulary:tempFormulary
  }
};

export const formularyTempSearchStart = () => {
  return {
      type: 'FORMULARY_TEMP_SEARCH_START'
  }
};

export const formularyTempSearchSuccess = (data) => {
  return {
      type: 'FORMULARY_TEMP_SEARCH_SUCCESS',
      payload: data,
  }
};

export const formularyTempSearchFail = (error) => {
  return {
      type: 'FORMULARY_TEMP_SEARCH_FAIL',
      error: error
  }
};


export const formularyEmpty = () => {
  return {
      type: 'FETCH_FORMULARY_EMPTY',
  }
};

export const formularySearchFail = (error) => {
  return {
      type: 'FORMULARY_SEARCH_FAIL',
      error: error
  }
};


export const formularyBrandDetail = (bradDetail) => {
  return {
      type: 'FORMULARY_FORMULARY_BRANDDETAIL',
      drug:bradDetail,
  }
};

export const formularyBrandDetailStart = () => {
  return {
      type: 'FETCH_FORMULARY_BRANDDETAIL_START'
  }
};

export const formularyBrandDetailSuccess = (data) => {
  return {
      type: 'FETCH_FORMULARY_BRANDDETAIL_SUCCESS',
      payload: data,
  }
};



export const formularyBrandDetailFail = (error) => {
  return {
      type: 'FETCH_FORMULARY_BRANDDETAIL_FAILURE',
      error: error
  }
};

export const formularyAdd = (drug_formulary) => {
  return {
      type: 'FORMULARY_ADD',
      drug_formulary:drug_formulary
  }
};

export const formularyAddStart = () => {
  return {
      type: 'FORMULARY_ADD_START'
  }
};

export const formularyAddSuccess = (data) => {
  return {
      type: 'FORMULARY_ADD_SUCCESS',
      payload: data,
  }
};

export const formularyAddEmpty = () => {
  return {
      type: 'FORMULARY_ADD_EMPTY',
  }
};

export const formularyAddFail = (error) => {
  return {
      type: 'FORMULARY_ADD_FAILURE',
      error: error
  }
};

export const formularyTempAdd = (drug_formulary) => {
  return {
      type: 'FORMULARY_TEMP_SEARCH_ADD',
      drug_formulary:drug_formulary
  }
};

export const formularyTempAddStart = () => {
  return {
      type: 'FORMULARY_TEMP_SEARCH_ADD_START'
  }
};

export const formularyTempAddSuccess = (data) => {
  return {
      type: 'FORMULARY_TEMP_SEARCH_ADD_SUCCESS',
      payload: data,
  }
};


export const formularyTempAddFail = (error) => {
  return {
      type: 'FORMULARY_TEMP_SEARCH_ADD_FAIL',
      error: error
  }
};
