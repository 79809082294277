export const FETCH_ALLERGY_REQUEST = 'FETCH_ALLERGY_REQUEST';
export const FETCH_ALLERGY_SUCCESS = 'FETCH_ALLERGY_SUCCESS';
export const FETCH_ALLERGY_FAILURE = 'FETCH_ALLERGY_FAILURE';

export const ALLERGY_ADD = 'ALLERGY_ADD';
export const ALLERGY_ADD_START = 'ALLERGY_ADD_START';
export const ALLERGY_ADD_SUCCESS = 'ALLERGY_ADD_SUCCESS';
export const ALLERGY_ADD_EMPTY = 'ALLERGY_ADD_EMPTY';
export const ALLERGY_ADD_FAILURE = 'ALLERGY_ADD_FAILURE';

export const FETCH_FORM_ALLERGY_REQUEST = 'FETCH_FORM_ALLERGY_REQUEST';
export const FETCH_FORM_ALLERGY_SUCCESS = 'FETCH_FORM_ALLERGY_SUCCESS';
export const FETCH_FORM_ALLERGY_FAILURE = 'FETCH_FORM_ALLERGY_FAILURE';


export const fetchAllergyRequest = (patientId) => {
  return {
      type: FETCH_ALLERGY_REQUEST,
      patientId:patientId,
  }
}

export const fetchAllergySuccess = (data) => {
  return {
      type: FETCH_ALLERGY_SUCCESS,
      payload: data
  }
}

export const fetchAllergyFailure = error => {
  return {
      type: FETCH_ALLERGY_FAILURE,
      payload: error
  }
}

export const fetchFormAllergyRequest = (patientId) => {
  return {
      type: FETCH_FORM_ALLERGY_REQUEST,
      patientId:patientId,
  }
}

export const fetchFormAllergySuccess = (data) => {
  return {
      type: FETCH_FORM_ALLERGY_SUCCESS,
      payload: data
  }
}

export const fetchFormAllergyFailure = error => {
  return {
      type: FETCH_FORM_ALLERGY_FAILURE,
      payload: error
  }
}




export const allergyAdd = (id,formData) => {
  return {
      type: ALLERGY_ADD,
      id:id,
      formData:formData
  }
};

export const allergyAddStart = () => {
  return {
      type: ALLERGY_ADD_START
  }
};

export const allergyAddSuccess = () => {
  return {
      type: ALLERGY_ADD_SUCCESS,
  }
};
export const allergyAddEmpty = () => {
  return {
      type: ALLERGY_ADD_EMPTY,
  }
};

export const allergyAddFail = (error) => {
  return {
      type: ALLERGY_ADD_FAILURE,
      error: error
  }
};
