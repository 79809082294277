import * as actionTypes from "actions";

const INITIAL_STATE = {
  error: null,
  drug: [],
  message: "",
  prescriptionData: [],
  prescription: [],
  isShow: false,
  loading: false,
  check: false,
  initialFeedBackValue : 0,
  isShowFinalFeedbackModal:false,
  isCimsDataCheckMessage:'',
  lastVisitPrescription:[],
  callFlowboard: 0,
  isReferredCase:false,
  isShowPrescriptionToSent: false,
  msoDetails:null,
  isUpdatePrescription:false,
  msoAvailability:true
};

const prescriptionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PRESCRIPTION_BY_ID:
      return {
        ...state,
        ...{ loading: true, error: null },
      };
    case actionTypes.FETCH_PRESCRIPTION_BY_ID_SUCCESS:
      return {
        ...state,
        prescriptionData: action.payload,
        loading: false,
      };
    case actionTypes.FETCH_PRESCRIPTION_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        prescriptionData: [],
        error: action.error,
      };
    case actionTypes.SEARCH_DRUG_IN_FORMULARY_START:
      return {
        ...state,
        ...{ loading: true, error: null },
      };
    case actionTypes.SEARCH_DRUG_IN_FORMULARY_SUCCESS:
      return {
        ...state,
        drug: action.payload,
        loading: false,
      };
    case actionTypes.SEARCH_DRUG_IN_FORMULARY_FAIL:
      return {
        ...state,
        loading: false,
        drug: [],
        error: action.error,
      };
    case actionTypes.ADD_PRESCRIPTION_START:
      return {
        ...state,
        ...{ loading: true, error: null },
      };
    case actionTypes.ADD_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        prescription: action.payload,
        loading: false,
      };
    case actionTypes.ADD_PRESCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        prescription: [],
        error: action.error,
      };
    case actionTypes.POST_PRESCRIPTION_START:
      return {
        ...state,
        ...{ loading: true, error: null },
      };
    case actionTypes.POST_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        // prescription: action.payload,
        isShow: action.payload,
        // check:action.check,
        loading: false,
      };
    case actionTypes.POST_PRESCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        // prescription:[],
        error: action.error,
      };
    case actionTypes.IS_SHOW_FEEDBACK_MODAL_SUCCESS:
      return {
        ...state,
        loading: false,
        check: action.payload,
        error: "",
      };
    case actionTypes.IS_SHOW_FEEDBACK_MODAL_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.IS_SHOW_PRESCRIPTION_TO_SENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isShowPrescriptionToSent: action.payload,
        error: "",
      };
    case actionTypes.IS_SHOW_PRESCRIPTION_TO_SENT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
      case actionTypes.POST_FEEDBACK_START:
        return {
          ...state,
          ...{ loading: true, error: null },
        };
    case actionTypes.POST_FEEDBACK_INITIAL:
      return {
        ...state,
        loading: false,
        initialFeedBackValue: action.payload,
        isShowFinalFeedbackModal:true,
        error: "",
      };
      case actionTypes.EMPTY_FEEDBACK_INITIAL:
        return {
          ...state,
          loading: false,
          initialFeedBackValue: 0,
          isShowFinalFeedbackModal:false,
          error: "",
        };
    case actionTypes.POST_FEEDBACK_SUCCESS:
      return {
        ...state,
        // prescription: action.payload,
        check: action.check,
        isShow: action.payload,
        loading: false,
      };
    case actionTypes.POST_FEEDBACK_FAIL:
      return {
        ...state,
        loading: false,
        // prescription:[],
        error: action.error,
      };
    case actionTypes.UPDATE_PRESCRIPTION_START:
      return {
        ...state,
        ...{ loading: true, error: null },
      };
    case actionTypes.UPDATE_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_PRESCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        // prescription:[],
        error: action.error,
      };
    case actionTypes.SEND_TO_MSO_PRESCRIPTION_START:
      return {
        ...state,
        ...{ loading: true, error: null },
      };
    case actionTypes.SEND_TO_MSO_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        message: action.payload,
        callFlowboard: action.status,
        loading: false,
      };
    case actionTypes.SEND_TO_MSO_PRESCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        message: "",
        error: action.error,
      };
      case actionTypes.IS_REFERRED_CASE:
        return {
          ...state,
          isReferredCase: action.payload,
          loading: false,
        };
          case actionTypes.LAST_VISIT_PRESCRIPTION_SUCCESS:
            return {
              ...state,
              lastVisitPrescription: action.payload,
              isCimsDataCheckMessage:action.message,
              loading: false,
            };
          case actionTypes.LAST_VISIT_PRESCRIPTION_FAIL:
            return {
              ...state,
              loading: false,
              lastVisitPrescription:[],
              isCimsDataCheckMessage:'',
              error: action.error,
            };
            case actionTypes.EMPTY_LAST_VISIT_PRESCRIPTION:
              return {
                ...state,
                loading: false,
                lastVisitPrescription:[],
                isCimsDataCheckMessage:'',
                error: action.error,
              };
              case actionTypes.FETCH_MSO_DETAILS_SUCCESS:
                return {
                  ...state,
                  msoDetails: action.payload,
                  loading: false,
                };
              case actionTypes.FETCH_MSO_DETAILS_FAIL:
                return {
                  ...state,
                  loading: false,
                  msoDetails:null,
                  error: action.error,
                };
                case actionTypes.RESET_MSO_DETAILS:
                  return {
                    ...state,
                    loading: false,
                    msoDetails:null,
                    error: '',
                  };
                  case actionTypes.IS_UPDATE_PRESCRIPTION:
                    return {
                      ...state,
                      loading: false,
                      isUpdatePrescription:action.isUpdatePres,
                    };
                  case actionTypes.EMPTY_LAST_PRESCRIPTION:
                    return {
                      ...state,
                      loading: false,
                      lastVisitPrescription:[],
                    };
                    case actionTypes.RESET_PRESCRIPTION_DATA:
                      return {
                        error: null,
                        drug: [],
                        message: "",
                        prescriptionData: [],
                        prescription: [],
                        isShow: false,
                        loading: false,
                        check: false,
                        isShowFinalFeedbackModal:false,
                        isCimsDataCheckMessage:'',
                        lastVisitPrescription:[],
                        callFlowboard: 0,
                        isReferredCase:false,
                        isShowPrescriptionToSent: false,
                        msoDetails:null,
                        isUpdatePrescription:false,
                        msoAvailability:true
                      };
                      case actionTypes.MSO_UNAVAILABLE:
                        return {
                          ...state,
                          loading: false,
                          msoAvailability:false,
                        };
                        case actionTypes.MSO_AVAILABLE:
                          return {
                            ...state,
                            loading: false,
                            msoAvailability:true,
                          };
    default:
      return state;
  }
};

export default prescriptionReducer;
