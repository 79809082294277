import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

// core components
import Card from "../../../../components/Card/Card";
import Controls from "../../../../components/controls/Controls";
import { useForm, Form } from "../../../../components/Form/useForm";
import CardBody from "../../../../components/Card/CardBody";
import CardFooter from "../../../../components/Card/CardFooter";
import SearchIcon from "@material-ui/icons/Search";
import { fetchSearchPatientRequest } from "../../../../actions";
import PatientCard from "./PatientCard";
import { TextField, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import NoData from "../../../../assets/img/nodata.png";


const styles = (theme) => ({
  root: {
    padding: "4px",
  },
  resut: {
    marginTop:-20
  }
});
const useStyles = makeStyles(styles);

const SearchPatient = (props) => {
  const { fetchSearchPatientRequest,searchPatient } = props;

  const [isSent, setIsSent] = useState(false);
  // const [patients, setPatients] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { values, errors, handleInputChange } = useForm(true);

  const classes = useStyles();

  const PatientDetail = () => {
    return (
      <Grid item xs={12} sm={12} md={12}>
        <div className={classes.results}>
          {/* <Typography color="textSecondary" gutterBottom variant="body2">
            {searchPatient.length} Records found. Page {page + 1} of{" "}
            {Math.ceil(searchPatient.length / rowsPerPage)}
          </Typography> */}
           {searchPatient.length === 0 && (
              <div style={{ marginLeft: "30%" }}>
                <img src={NoData} alt="nodata" />
              </div>
            )}
          <Grid container spacing={1}>
          {searchPatient.map((doctor) => (
          <Grid item key={doctor.id} md={3} sm={3} xs={12}>
          <PatientCard doctor={doctor} diagnosis = {values.diagnosis} />
          </Grid>
          ))}
         </Grid>
        </div>
      </Grid>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const encodeGetParams = (p) =>
      Object.entries(p)
        .map((kv) => kv.map(encodeURIComponent).join("="))
        .join("&");

    const params = {};
    if(values.name !== undefined && values.diagnosis !== undefined ){
      params.name = values.name;
      params.diagnosis = values.diagnosis;
    }else if(values.diagnosis !== undefined){
      params.diagnosis = values.diagnosis
    }else{
      params.name = values.name;
    }
    console.log(encodeGetParams(params));
    fetchSearchPatientRequest(encodeGetParams(params));
    setIsSent(true);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={8}>
          {/* <Card style={{ marginTop:2,marginLeft:-10,width:'90%' }}> */}
            <CardBody style={{ marginTop: -18 }}>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={2} md={2}>
                  <Typography style={{ marginTop:32 }}>Search By :</Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Controls.Input
                      name="name"
                      label="Name"
                      value={values.name || ""}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "white",marginLeft:-15}}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Controls.Input
                      name="diagnosis"
                      label="Diagnosis"
                      value={values.diagnosis || ""}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "white",marginLeft:-15}}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <CardFooter style={{ float: "right"}}>
                      <Button
                        variant="contained"
                        className={classes.button}
                        style={{ backgroundColor: "#118bc5", width:120,height:48}}
                        type="submit"
                        startIcon={<SearchIcon />}
                      >
                        Search
                      </Button>
                    </CardFooter>
                  </Grid>
                </Grid>
              </Form>
            </CardBody>
          {/* </Card> */}
        </Grid>

        {isSent ? PatientDetail() : null}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.fetchpatient.loading,
    searchPatient:state.fetchpatient.searchPatient
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSearchPatientRequest: (url) =>
      dispatch(fetchSearchPatientRequest(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPatient);
