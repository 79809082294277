export const SET_DOCTOR_NOTES_REQUEST = 'SET_DOCTOR_NOTES_REQUEST';
export const SET_DOCTOR_NOTES_SUCCESS = 'SET_DOCTOR_NOTES_SUCCESS';
export const SET_DOCTOR_NOTES_FAILURE = 'SET_DOCTOR_NOTES_FAILURE';

export const SET_INSTRUCTION_REQUEST = 'SET_INSTRUCTION_REQUEST';
export const SET_INSTRUCTION_SUCCESS = 'SET_INSTRUCTION_SUCCESS';
export const SET_INSTRUCTION_FAILURE = 'SET_INSTRUCTION_FAILURE';



export const setDoctorNotesRequest = (notes) => {
  return {
      type: SET_DOCTOR_NOTES_REQUEST,
      notes:notes,
  }
}

export const setDoctorNotesSuccess = (notes) => {
  return {
      type: SET_DOCTOR_NOTES_SUCCESS,
      payload: notes,
  }
}

export const setDoctorNotesFailure = error => {
  return {
      type: SET_DOCTOR_NOTES_FAILURE,
      payload: error
  }
}

export const setInstructionRequest = (instruction) => {
  return {
      type: SET_INSTRUCTION_REQUEST,
      instruction:instruction,
  }
}

export const setInstructionSuccess = (instruction) => {
  return {
      type: SET_INSTRUCTION_SUCCESS,
      payload: instruction,
  }
}

export const setInstructionFailure = error => {
  return {
      type: SET_INSTRUCTION_FAILURE,
      payload: error
  }
}
