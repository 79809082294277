import * as actionTypes from "actions";

const INITIAL_STATE = {
  error: null,
  message:'',
  leaves:[],
  appointmentData:[],
  loading: false,
};

const leaveReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LEAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        leaves: action.payload,
        error: "",
      };
    case actionTypes.FETCH_LEAVE_FAILURE:
      return {
        ...state,
        loading: false,
        leaves: [],
        error: action.payload,
      };
      case actionTypes.FETCH_PATIENT_APPOINTMENTS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_PATIENT_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        appointmentData: action.payload,
        error: "",
      };
    case actionTypes.FETCH_PATIENT_APPOINTMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        appointmentData: [],
        error: action.payload,
      };
      case actionTypes.LEAVE_ADD_START:
        return {
          ...state,
          ...{ loading: true, error: null },
        };
      case actionTypes.LEAVE_ADD_SUCCESS:
        return {
          ...state,
          message: action.payload,
          loading: false,
        };
        case actionTypes.LEAVE_ADD_FAILURE:
          return {
            ...state,
            message:'',
            loading: false,
          };
          case actionTypes.LEAVE_DELETE_START:
            return {
              ...state,
              ...{ loading: true, error: null },
            };
          case actionTypes.LEAVE_DELETE_SUCCESS:
            return {
              ...state,
              message: action.payload,
              loading: false,
            };
            case actionTypes.LEAVE_DELETE_FAILURE:
              return {
                ...state,
                message:'',
                loading: false,
              };
    default:
      return state;
  }
};

export default leaveReducer;
