import React, { useState } from "react";
import { Dialog, Typography, Grid } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import CardBody from "../../../components/Card/CardBody";
import { makeStyles } from "@material-ui/styles";
import { Rating } from "@material-ui/lab";
import { postFeedback, postInitialFeedback } from "../../../actions";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  feedbackDialogPaper: {
    height: "20vh",
    width: "125vh",
    backgroundColor: "beige",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InitialFeedback = ({
  open,
  onClose,
  postFeedback,
  postInitialFeedback,
  visitId,
  finalFeedbackModaOpen,
  handleFeedbackCounter
}) => {
  const classes = useStyles();
  const [msoRatingValue, setMsoRatingValue] = useState(0);

  const handleMsoRating = (event, newValue) => {
    setMsoRatingValue(newValue);
    if (newValue === 5) {
      const doctor_to_mso_feedback = {
        visit_id: visitId,
        mso_records: 5,
        mso_assistance: 5,
        video_quality: 5,
        remark: "",
      };
      postFeedback(doctor_to_mso_feedback);
      handleFeedbackCounter()
      onClose();
    } else {
      postInitialFeedback(newValue);
      onClose();
      finalFeedbackModaOpen()
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.feedbackDialogPaper }}
      TransitionComponent={Transition}
      onClose={onClose}
      open={open}
    >
      <Grid container>
        <Grid
          container
          item
          xs={12}
          md={12}
          sm={12}
          justify="center"
          direction="column"
          alignItems="center"
        >
          <Typography
            align="center"
            gutterBottom
            variant="h5"
            style={{ marginTop: "4%", fontSize: "20px", color: "#2b2a29" }}
          >
            How was your experience with the call and the Medicine Store ?
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={12}
          sm={12}
          justify="center"
          direction="column"
          alignItems="center"
        >
          <CardBody>
            <Rating
              name="rating"
              size="large"
              value={msoRatingValue}
              onChange={handleMsoRating}
              data_cy={`rating-input`}
            />
          </CardBody>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    postFeedback: (formData) => dispatch(postFeedback(formData)),
    postInitialFeedback: (ratingData) =>
      dispatch(postInitialFeedback(ratingData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InitialFeedback);
