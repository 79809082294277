import * as actionTypes from "actions";

const INITIAL_STATE = {
  error: null,
  allergy:[],
  formData:[],
  loading: false,
};

const allergyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ALLERGY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_ALLERGY_SUCCESS:
      return {
        ...state,
        loading: false,
        allergy: action.payload,
        formData:[],
        error: "",
      };
    case actionTypes.FETCH_ALLERGY_FAILURE:
      return {
        ...state,
        loading: false,
        allergy: [],
        error: action.payload,
      };
      case actionTypes.FETCH_FORM_ALLERGY_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.FETCH_FORM_ALLERGY_SUCCESS:
        return {
          ...state,
          loading: false,
          formData: action.payload,
          error: "",
        };
      case actionTypes.FETCH_FORM_ALLERGY_FAILURE:
        return {
          ...state,
          loading: false,
          formData: [],
          error: action.payload,
        };
      case actionTypes.ALLERGY_ADD_START:
        return {
          ...state,
          ...{ loading: true, error: null },
        };
      case actionTypes.ALLERGY_ADD_SUCCESS:
        return {
          ...state,
          loading: false,
        };
        case actionTypes.ALLERGY_ADD_FAILURE:
          return {
            ...state,
            loading: false,
          };
    default:
      return state;
  }
};

export default allergyReducer;
