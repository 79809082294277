export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';


// export const LOGIN_INITIATE_LOGOUT = 'LOGIN_INITIATE_LOGOUT';
// export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_START = 'USER_LOGOUT_START';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL';


export const LOGIN_CHECK_TIMEOUT = 'LOGIN_CHECK_TIMEOUT';



export const login = (auth) => {
  return {
      type: 'LOGIN_USER',
      email:auth.email,
      password: auth.password,
      role: auth.role,
  }
};

export const loginStart = () => {
  return {
      type: 'LOGIN_START'
  }
};

export const loginSuccess = (userId,goTo,designation) => {
  return {
      type: 'LOGIN_SUCCESS',
      userId: userId,
      goTo:goTo,
      designation
  }
};

export const authFail = (error) => {
  return {
      type: 'LOGIN_FAIL',
      error: error
  }
};

// export const logout = () => {
//     return {
//         type: 'LOGIN_INITIATE_LOGOUT'
//     }
// };

// export const logoutSucceed = (logout) => {
//     return {
//         type: 'USER_LOGOUT',
//         logout:logout
//     }
// };


export const logout = () => {
  return {
      type: 'USER_LOGOUT',

  }
};

export const logoutStart = () => {
  return {
      type: 'USER_LOGOUT_START'
  }
};

export const logoutSuccess = (data,val) => {
  return {
      type: 'USER_LOGOUT_SUCCESS',
      payload: data,
      val:val
  }
};


export const logoutFail = (error) => {
  return {
      type: 'USER_LOGOUT_FAIL',
      error: error
  }
};


export const checkAuthTimeout = (status) => {
    return {
        type: 'LOGIN_CHECK_TIMEOUT',
        status:status
    }
};