export const FETCH_PATHOLOGY_SUGGESTION_REQUEST = 'FETCH_PATHOLOGY_SUGGESTION_REQUEST';
export const FETCH_PATHOLOGY_SUGGESTION_SUCCESS = 'FETCH_PATHOLOGY_SUGGESTION_SUCCESS';
export const FETCH_PATHOLOGY_SUGGESTION_FAILURE = 'FETCH_PATHOLOGY_SUGGESTION_FAILURE';

export const FETCH_RADIO_SUGGESTION_REQUEST = 'FETCH_RADIO_SUGGESTION_REQUEST';
export const FETCH_RADIO_SUGGESTION_SUCCESS = 'FETCH_RADIO_SUGGESTION_SUCCESS';
export const FETCH_RADIO_SUGGESTION_FAILURE = 'FETCH_RADIO_SUGGESTION_FAILURE';

export const FETCH_DIET_SUGGESTION_REQUEST = 'FETCH_DIET_SUGGESTION_REQUEST';
export const FETCH_DIET_SUGGESTION_SUCCESS = 'FETCH_DIET_SUGGESTION_SUCCESS';
export const FETCH_DIET_SUGGESTION_FAILURE = 'FETCH_DIET_SUGGESTION_FAILURE';

export const FETCH_EXERCISE_SUGGESTION_REQUEST = 'FETCH_EXERCISE_SUGGESTION_REQUEST';
export const FETCH_EXERCISE_SUGGESTION_SUCCESS = 'FETCH_EXERCISE_SUGGESTION_SUCCESS';
export const FETCH_EXERCISE_SUGGESTION_FAILURE = 'FETCH_EXERCISE_SUGGESTION_FAILURE';


export const fetchPathologySuggestionRequest = (params) => {
  return {
      type: FETCH_PATHOLOGY_SUGGESTION_REQUEST,
      params:params

  }
}

export const fetchPathologySuggestionSuccess = (pathology,title) => {
  return {
      type: FETCH_PATHOLOGY_SUGGESTION_SUCCESS,
      payload: pathology,
      title:title
  }
}

export const fetchPathologySuggestionFailure = error => {
  return {
      type: FETCH_PATHOLOGY_SUGGESTION_FAILURE,
      payload: error
  }
}

export const fetchRadioSuggestionRequest = (params) => {
  return {
      type: FETCH_RADIO_SUGGESTION_REQUEST,
      params:params

  }
}

export const fetchRadioSuggestionSuccess = (radio,title) => {
  return {
      type: FETCH_RADIO_SUGGESTION_SUCCESS,
      payload: radio,
      title:title
  }
}

export const fetchRadioSuggestionFailure = error => {
  return {
      type: FETCH_RADIO_SUGGESTION_FAILURE,
      payload: error
  }
}

export const fetchDietSuggestionRequest = (params) => {
  return {
      type: FETCH_DIET_SUGGESTION_REQUEST,
      params:params

  }
}

export const fetchDietSuggestionSuccess = (diet,title) => {
  return {
      type: FETCH_DIET_SUGGESTION_SUCCESS,
      payload: diet,
      title:title
  }
}

export const fetchDietSuggestionFailure = error => {
  return {
      type: FETCH_DIET_SUGGESTION_FAILURE,
      payload: error
  }
}

export const fetchExerciseSuggestionRequest = (params) => {
  return {
      type: FETCH_EXERCISE_SUGGESTION_REQUEST,
      params:params

  }
}

export const fetchExerciseSuggestionSuccess = (exercise,title) => {
  return {
      type: FETCH_EXERCISE_SUGGESTION_SUCCESS,
      payload: exercise,
      title:title
  }
}

export const fetchExerciseSuggestionFailure = error => {
  return {
      type: FETCH_EXERCISE_SUGGESTION_FAILURE,
      payload: error
  }
}

