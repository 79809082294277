import { all } from "redux-saga/effects";
import { watcherLoginSaga } from "../sagas/loginSaga";
import { watcherPatientSaga } from "../sagas/fetchPatientSaga";
import { watcherReviewSaga } from "../sagas/reviewDetailSaga";
import { watcherCurrentSaga } from "../sagas/currentMedicationSaga";
import { watcherVitalSaga } from "./vitalSaga";
import { watcherLifestyleSaga } from "./lifestyleSaga";
import { watcherFamilyHistorySaga } from "./familyHistorySaga";
import { watcherDocumentSaga } from "./documentSaga";
import { watcherCalenderSaga } from "./calenderSaga";
import { watcherTimelineSaga } from "./timelineSaga";
import { watcherTimelineDataSaga } from "./timelineDataSaga";
import { watcherPatientDetailSaga } from "./patientDetailByIdSaga";
import { watcherFormularySaga } from "./formulary";
import { watcherAllInteractionSaga } from "./allInteraction";
import { watcherSuggestionSaga } from "./suggestionSaga";
import { watcherPathologyTestSaga } from "./pathologyTestSaga";
import { watcherradiologyTestSaga } from "./radiologyTestSaga";
import { watcherDietSaga } from "./dietSaga";
import { watcherExerciseSaga } from "./exerciseSaga";
import { watcherDifferentialSaga } from "./differentialSaga";
import { watcherAllergySaga } from "./allergySaga";
import { watcherSearchDrugInFormularySaga } from "./prescriptionSaga";
import { watcherFlowBoardSaga } from "./flowBoardStatus";
import { watcherFollowupSaga } from "./followUpSaga";
import { watcherDoctorNotesSaga } from "./doctorNotesSaga";
import { watcherLeaveSaga } from "./leaveSaga";
import { watcherStartConsultationSaga } from "./StartConsultationSaga";
import { watcherReminderSaga } from "./reminderSaga";
import { watcherUserProfileSaga } from "./userProfile";

export default function* rootSaga() {
  yield all([
    watcherLoginSaga(),
    watcherPatientSaga(),
    watcherReviewSaga(),
    watcherCurrentSaga(),
    watcherVitalSaga(),
    watcherLifestyleSaga(),
    watcherFamilyHistorySaga(),
    watcherDocumentSaga(),
    watcherCalenderSaga(),
    watcherTimelineSaga(),
    watcherTimelineDataSaga(),
    watcherPatientDetailSaga(),
    watcherFormularySaga(),
    watcherAllInteractionSaga(),
    watcherSuggestionSaga(),
    watcherPathologyTestSaga(),
    watcherradiologyTestSaga(),
    watcherDietSaga(),
    watcherExerciseSaga(),
    watcherDifferentialSaga(),
    watcherAllergySaga(),
    watcherSearchDrugInFormularySaga(),
    watcherFlowBoardSaga(),
    watcherFollowupSaga(),
    watcherDoctorNotesSaga(),
    watcherLeaveSaga(),
    watcherStartConsultationSaga(),
    watcherReminderSaga(),
    watcherUserProfileSaga(),
  ]);
}
