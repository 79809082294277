import React, { useState, useEffect,useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Button from "../../../components/CustomButtons/Button";
import Card from "../../../components/Card/Card";
import baseUrl from "../../../utils/baseUrl";
import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
import CardAvatar from "../../../components/Card/CardAvatar";
import { DirectUpload } from "@rails/activestorage";
import MultiSelect from "react-multi-select-component";
import TextField from "@material-ui/core/TextField";
import { Alert, AlertTitle } from "@material-ui/lab";
import WcIcon from "@material-ui/icons/Wc";
import TodayIcon from "@material-ui/icons/Today";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LanguageIcon from "@material-ui/icons/Language";
import { useForm, Controller } from "react-hook-form";
import {
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getRequest } from "../../../api/Api";

import { fetchUserInfoRequest, profileUpdate } from "../../../actions";
import { connect } from "react-redux";
import { checkFileExtension } from '../../../utils/helper'

const styles = (theme) => ({
  profileRoot: {
    padding: "12px",
    marginTop: "-3%",
  },
  card: {
    boxShadow: "0 2px 8px rgba(0,0,0,0.30), 0 10px 12px rgba(0,0,0,0.22)",
  },
  cardCategory: {
    color: "black",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    fontWeight: 400,
    marginBottom: "0",
  },
  doctorInfoStyle: {
    color: "#352e2e",
    display: "inline-flex",
    fontSize: "14px",
    lineHeight: "26px",
    marginLeft: "-10px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "5px",
    },
  },
  input: {
    display: "block",
    boxSizing: "border-box",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid black",
    padding: "10px 15px",
    marginBottom: "2px",
    fontSize: "14px",
    marginTop: 9,
  },
});

const useStyles = makeStyles(styles);

const UserProfile = (props) => {
  const { fetchUserInfoRequest, user, profileUpdate,onClose } = props;
  const {
    control,
    handleSubmit,
    errors,
    getValues,
    watch,
    setValue,
    register
  } = useForm();

  const classes = useStyles();
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const [cities, setCities] = useState([]);
  const [states, setState] = useState([]);
  const [defLanguage, setDefLanguage] = useState([]);
  const [tempUser, setTempUser] = useState([]);
  const [consultationLanguage,setConsultationLanguage] = useState([])
  const mobile = watch("mobile");
  const pincode = watch("pincode");
  const address = watch("address");
  const dob = watch("dob");
  const uploadedSignImage = useRef(null);

  const loadStates = () => {
    getRequest("get_states").then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setState(data);
      }
    });
  };
  const loadDefaultLanguage = () => {
    getRequest("get_default_languages").then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setDefLanguage(data);
      }
    });
  };

  const fetchTempUser = () => {
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest("get_current_temp_user_details", token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setTempUser(data.data);
      }
    });
  };

  const getCity = (state_id) => {
    const cityEndpoint = "get_cities";
    const token = "Bearer " + localStorage.getItem("jwt");
    let param = [state_id];
    getRequest(cityEndpoint, token, param).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setCities(data);
      }
    });
  };

  useEffect(() => {
    loadStates();
    loadDefaultLanguage();
    fetchUserInfoRequest();
    fetchTempUser();
  }, []);

  useEffect(() => {
    if (user) {
      setValue("name", user.attributes.name || "");
      if (user.attributes.dob !== null) {
        setValue("dob", moment(user.attributes.dob).toDate() || "");
      }
      setValue("mobile", user.attributes.mobile || "");

      setValue("experience", user.attributes.work_experience || "");

      setValue(
        "state_of_registration",
        user.attributes.registration_state || ""
      );

      setValue(
        "medical_registration_no",
        user.attributes.medical_registration_no || ""
      );

      setValue("year_of_registration", user.attributes.registration_year || "");

      setValue("email", user.attributes.email || "");
      if (user.attributes.gender === "male") {
        setValue("gender", "Male" || "");
      } else {
        setValue("gender", "Female" || "");
      }
      setValue("address", user.attributes.address || "");
      if (user.attributes.pincode !== null) {
        setValue("pincode", user.attributes.pincode || "");
      }
      if (user.attributes.state !== null) {
        setValue("state_id", user.attributes.state.id || "");
        getCity(user.attributes.state.id);
      }
      if (user.attributes.city !== null) {
        setValue("city_id", user.attributes.city.id || "");
      }

      setValue(
        "awards_and_achievement",
        user.attributes.awards_n_achievements || ""
      );
      setValue(
        "detailed_experience",
        user.attributes.detailed_experience || ""
      );
      setValue("fee", user.attributes.fee || "");
      setValue("summary", user.attributes.summary || "");

      setValue("follow_up_time", user.attributes.follow_up_time || "");
      if (user.attributes.default_language !== null) {
        setValue(
          "default_language",
          user.attributes.default_language.key || ""
        );
      }
      let selectedLanguage = [];
      if (user.attributes.consulting_languages) {
        for (
          let i = 0;
          i < user.attributes.consulting_languages.length;
          i++
        ) {
          selectedLanguage.push({
            label:user.attributes.consulting_languages[i].language_value,
            value: user.attributes.consulting_languages[i].language,
            status:user.attributes.consulting_languages[i].status,
            user_consulting_language_id:user.attributes.consulting_languages[i].id,
            id : tempUser && tempUser.attributes && tempUser.attributes.approval_status?.key === 0 ? tempUser.attributes.consulting_languages[i]?.id : ''
          });
        }
      }
      setConsultationLanguage(selectedLanguage);
    }
  }, [setValue, user,tempUser]);


  const handleImage = (e) => {
    const [file] = e.target.files;

    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const promises = [];
  const handleImageUpload = (image) => {
    if (image.length !== 0) {
      promises.push(uploadFile(image[0]));
    }
  };

  const uploadFile = (file) => {
    return new Promise((resolve, reject) => {
      const URL = `${baseUrl}/rails/active_storage/direct_uploads`;
      const upload = new DirectUpload(file, URL);
      upload.create((error, blob) => {
        if (error) {
          reject(error);
        } else {
          const hiddenField = document.createElement("input");
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("value", blob.signed_id);
          hiddenField.id = `profile_pic`;
          document.querySelector("form").appendChild(hiddenField);
          resolve("Success");
        }
      });
    });
  };

  const handleConsultationLanguage = (value)=>{
    console.log(value)
    setConsultationLanguage(value)
  }

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <input
        onClick={props.onClick}
        value={props.value}
        className={classes.input}
        type="text"
        data-testid={`mso-profile-dob-${moment(dob).format(
          "YYYY-MM-DD"
        )}-input`}
        placeholder="Select DOB"
        readOnly={true}
      />
    );
  });


  const handleSignUpload = (image) => {
    if (image.length !== 0) {
      promises.push(uploadSignFile(image[0]));
    }
  };

  const uploadSignFile = (file) => {
    return new Promise((resolve, reject) => {
      const URL = `${baseUrl}/rails/active_storage/direct_uploads`;
      const upload = new DirectUpload(file, URL);
      upload.create((error, blob) => {
        if (error) {
          reject(error);
        } else {
          const hiddenField = document.createElement("input");
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("value", blob.signed_id);
          hiddenField.id = `sign_key`;
          document.querySelector("form").appendChild(hiddenField);
          resolve("Success");
        }
      });
    });
  };

  const handleSignImage = (e) => {
    const [file] = e.target.files;

    if (file) {
      const reader = new FileReader();
      const { current } = uploadedSignImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmit = (data) => {
  if(data.signature.length !== 0){
    let isSignFormatAllowed = checkFileExtension(data.signature)
    if(!isSignFormatAllowed.isAllowed){
     alert(isSignFormatAllowed.message)
     return false;
    }
    if (data.signature[0].size > 4000000) {
      alert(`Signature file exceeds the 4 MB size limit.`);
      return false;
    } 
  }
    handleImageUpload(imageUploader.current.files);
    handleSignUpload(data.signature)

    Promise.all(promises)
      .then(() => {
        var formData = new FormData();
        if (data.email) {
          formData.append("temp_user[email]", data.email);
        }
        if (data.mobile) {
          formData.append("temp_user[mobile]", data.mobile);
        }
        // formData.append("temp_user[gender]", data.gender);
        if (data.address) {
          formData.append("temp_user[address]", data.address);
        }
        if (data.city_id) {
          formData.append("temp_user[city_id]", data.city_id);
        }
        if (data.state_id) {
          formData.append("temp_user[state_id]", data.state_id);
        }

        if (data.dob) {
          formData.append(
            "temp_user[dob]",
            moment(data.dob).format("YYYY-MM-DD")
          );
        }
        if (data.pincode) {
          formData.append("temp_user[pincode]", data.pincode);
        }
        if (data.default_language) {
          formData.append("temp_user[default_language]", data.default_language);
        }
        if (user.attributes.role !== null) {
          formData.append("temp_user[role]", user.attributes.role.role);
        }
        formData.append("temp_user[user_id]", user.attributes.id);
        formData.append("temp_user[country]", user.attributes.country?.id);
        formData.append("temp_user[follow_up_time]", data.follow_up_time);
        formData.append(
          "temp_user[medical_registration_no]",
          data.medical_registration_no
        );
        formData.append(
          "temp_user[registration_state]",
          data.state_of_registration
        );
        formData.append(
          "temp_user[registration_year]",
          data.year_of_registration
        );
        formData.append("temp_user[work_experience]", data.experience);
        formData.append(
          "temp_user[detailed_experience]",
          data.detailed_experience
        );
        if(data.signature.length !== 0){
          formData.append(
            "temp_user[sign]",
            document.getElementById(`sign_key`).value
          );
          }
        formData.append(
          "temp_user[awards_n_achievements]",
          data.awards_and_achievement
        );
        formData.append("temp_user[summary]", data.summary);
        formData.append("temp_user[doctor_category_id]", 4);
        formData.append("temp_user[fee]", data.fee);

        if (imageUploader.current.files.length !== 0) {
          formData.append(
            "temp_user[profile_photo]",
            document.getElementById(`profile_pic`).value
          );
        }
        let res
      if(tempUser && tempUser.attributes && tempUser.attributes.approval_status?.key === 0 ){
        res = tempUser.attributes.consulting_languages.filter(
          (item1) => !consultationLanguage.some((item2) => item2.value === item1.language)
        );
      }else{
       res = user.attributes.consulting_languages.filter(
          (item1) => !consultationLanguage.some((item2) => item2.value === item1.language)
        );
      }

        console.log(res)
        if(res.length){
          for(let j = 0 ; j< res.length ; j++){
            res[j].status = 0;
            if(tempUser && tempUser.attributes && tempUser.attributes.approval_status?.key === 1 ){
            res[j].user_consulting_language_id = res[j].id
            delete res[j].id
            }
            delete res[j].language_value
          }
        }
        for(let k = 0 ; k< consultationLanguage.length ; k++){
          consultationLanguage[k].language = consultationLanguage[k].value
          if(!consultationLanguage[k].user_consulting_language_id){
            delete consultationLanguage[k].user_consulting_language_id
          }
          if(!consultationLanguage[k].id){
            delete consultationLanguage[k].id
          }
          delete consultationLanguage[k].label
          delete consultationLanguage[k].value
        }
        let finalArray = [...consultationLanguage,...res]
        console.log(finalArray)

        for (let m = 0; m < finalArray.length; m++) {
          Object.keys(finalArray[m]).forEach((key) => {
            formData.append(
              `temp_user[temp_user_consulting_languages_attributes][${m}][${key}]`,
              finalArray[m][key]
            );
          });
        }

        for (let pair of formData.entries()) {
          console.log(pair[0] + ": " + pair[1]);
        }
        profileUpdate(formData);
        onClose()
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className={classes.profileRoot}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} md={8}>
            <Card>
              <CardBody>
                <Grid container spacing={2}>
                  {tempUser !== null && tempUser.attributes !== undefined ? (
                    <Grid item xs={12} sm={12} md={12}>
                      <div style={{ float: "right" }}>
                        {tempUser.attributes.approval_status.key === 0 ? (
                          <Alert severity="warning">
                            Profile is yet to be Approved
                          </Alert>
                        ) : tempUser.attributes.approval_status.key === 2 ? (
                          <Alert severity="error">
                            <AlertTitle style={{ color: "brown" }}>
                              Oops Profile is Rejected !
                            </AlertTitle>
                            <h4 style={{ fontWeight: 500 }}>
                              {tempUser.attributes.remark}
                            </h4>
                          </Alert>
                        ) : (
                          <Alert severity="success">Profile is Approved</Alert>
                        )}
                      </div>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      as={<TextField />}
                      name="name"
                      control={control}
                      defaultValue=""
                      label="Full Name"
                      type="text"
                      fullWidth
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      as={<TextField />}
                      name="mobile"
                      error={Boolean(errors.mobile)}
                      control={control}
                      defaultValue=""
                      label="Mobile"
                      data-testid={`doctor-profile-mobile-${mobile}-input`}
                      rules={{
                        required: "Mobile Number is required",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Only Numbers are allowed",
                        },
                        minLength: 10,
                      }}
                      type="text"
                      helperText={errors.mobile && errors.mobile.message}
                      fullWidth
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                    {errors.mobile && errors.mobile.type === "minLength" && (
                      <span style={{ color: "red" }}>
                        Number length should be 10
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      as={<TextField />}
                      name="email"
                      control={control}
                      defaultValue=""
                      label="Email"
                      type="email"
                      fullWidth
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Controller
                      as={<ReactDatePicker />}
                      error={Boolean(errors.dob)}
                      control={control}
                      valueName="selected"
                      onChange={([selected]) => selected}
                      name="dob"
                      data-testid="doctor-profile-dob-datepicker"
                      rules={{ required: "DOB is required" }}
                      filterDate={(date) => {
                        return moment() > date;
                      }}
                      isClearable
                      customInput={<CustomInput />}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dateFormat="yyyy/MM/dd "
                      dropdownMode="select"
                      popperPlacement="bottom-start"
                    />
                    {errors.dob && (
                      <div style={{ color: "red" }}>DOB is required</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Controller
                      as={<TextField />}
                      name="gender"
                      control={control}
                      defaultValue=""
                      label="Gender"
                      type="text"
                      fullWidth
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.address)}
                      name="address"
                      rules={{ required: "Address is required" }}
                      control={control}
                      data-testid={`doctor-profile-address-${
                        address !== undefined ? address.toLowerCase() : ""
                      }-input`}
                      defaultValue=""
                      label="Address"
                      type="text"
                      helperText={errors.address && errors.address.message}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl
                      style={{ minWidth: 170 }}
                      error={Boolean(errors.state_id)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        State
                      </InputLabel>
                      <Controller
                        as={
                          <Select>
                            {states.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.state_name}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        name="state_id"
                        rules={{ required: "State is required" }}
                        control={control}
                        onChange={([selected]) => {
                          getCity(selected.target.value);
                          return selected;
                        }}
                        defaultValue=""
                      />
                      <FormHelperText>
                        {errors.state_id && errors.state_id.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl
                      style={{ minWidth: 170 }}
                      error={Boolean(errors.city_id)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        City
                      </InputLabel>
                      <Controller
                        as={
                          <Select>
                            {cities.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.city_name}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        name="city_id"
                        rules={{ required: "City is required" }}
                        control={control}
                        defaultValue=""
                      />
                      <FormHelperText>
                        {errors.city_id && errors.city_id.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.pincode)}
                      name="pincode"
                      rules={{
                        required: "Pincode is required",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Only Numbers are allowed",
                        },
                        minLength: 6,
                      }}
                      control={control}
                      defaultValue=""
                      label="Pincode"
                      data-testid={`doctor-profile-pincode-${pincode}-input`}
                      type="text"
                      helperText={errors.pincode && errors.pincode.message}
                      fullWidth
                      // onKeyDown={Alpha}
                      inputProps={{
                        maxLength: 6,
                      }}
                    />

                    {errors.pincode && errors.pincode.type === "minLength" && (
                      <span style={{ color: "red" }}>
                        Pincode length should be 6
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl
                      style={{ minWidth: 150 }}
                      error={Boolean(errors.default_language)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Default Language
                      </InputLabel>

                      <Controller
                        as={
                          <Select>
                            {defLanguage.map((option) => (
                              <MenuItem key={option.value} value={option.key}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        name="default_language"
                        rules={{ required: "Default Language is required" }}
                        control={control}
                        defaultValue=""
                      />
                      <FormHelperText>
                        {errors.default_language &&
                          errors.default_language.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} container direction="row">
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.follow_up_time)}
                      name="follow_up_time"
                      rules={{
                        required: "Follow up time is required",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Only Numbers are allowed",
                        },
                      }}
                      control={control}
                      defaultValue={7}
                      style={{ width: "35%" }}
                      label="Follow up time"
                      helperText={
                        errors.follow_up_time && errors.follow_up_time.message
                      }
                      fullWidth
                    />
                    <Typography style={{ marginTop: "5%", marginLeft: "1%" }}>
                      Days
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.medical_registration_no)}
                      name="medical_registration_no"
                      rules={{
                        required: "Medical Registration No is required",
                      }}
                      control={control}
                      defaultValue=""
                      label="Medical Registration No"
                      type="text"
                      helperText={
                        errors.medical_registration_no &&
                        errors.medical_registration_no.message
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.state_of_registration)}
                      name="state_of_registration"
                      rules={{ required: "State of Registration is required" }}
                      control={control}
                      defaultValue=""
                      label="State of Registration"
                      type="text"
                      helperText={
                        errors.state_of_registration &&
                        errors.state_of_registration.message
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.year_of_registration)}
                      name="year_of_registration"
                      rules={{ required: "Year of Registration is required" }}
                      control={control}
                      defaultValue=""
                      label="Year of Registration"
                      type="text"
                      helperText={
                        errors.year_of_registration &&
                        errors.year_of_registration.message
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.experience)}
                      name="experience"
                      rules={{ required: "Experience is required" }}
                      control={control}
                      defaultValue=""
                      label="Experience"
                      type="text"
                      helperText={
                        errors.experience && errors.experience.message
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.detailed_experience)}
                      name="detailed_experience"
                      // rules={{ required: "Detailed Experience is required" }}
                      control={control}
                      defaultValue=""
                      label="Detailed Experience"
                      type="text"
                      multiline
                      rows={4}
                      variant="outlined"
                      // helperText={errors.detailed_experience && errors.detailed_experience.message}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.awards_and_achievement)}
                      name="awards_and_achievement"
                      // rules={{ required: "Awards and Achievements are required" }}
                      control={control}
                      defaultValue=""
                      label="Awards and Achievement"
                      type="text"
                      multiline
                      rows={4}
                      variant="outlined"
                      // helperText={errors.awards_and_achievement && errors.awards_and_achievement.message}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.summary)}
                      name="summary"
                      // rules={{ required: "Summary is required" }}
                      control={control}
                      defaultValue=""
                      label="Summary"
                      type="text"
                      multiline
                      rows={4}
                      variant="outlined"
                      // helperText={errors.summary && errors.summary.message}
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={7}
                    md={7}
                    style={{ marginTop: 9, marginLeft: -3 }}
                  >
                    <div style={{ width:278 }}>
                    <label>Consultation Language</label>
                    <MultiSelect
                      required
                      options={defLanguage.map((e) => ({
                        label: e.value,
                        value: e.key,
                        status:1,
                        user_consulting_language_id:''
                      }))}
                      value={consultationLanguage}
                      hasSelectAll={false}
                      getOptionLabel={(option) => option.value}
                      onChange={handleConsultationLanguage}
                    />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.fee)}
                      name="fee"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Fee is required",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Only Numbers are allowed",
                        },
                        minLength: 1,
                      }}
                      inputProps={{
                        step: 1,
                        min: 0 
                      }}
                      label="Fee"
                      type="text"
                      helperText={errors.fee && errors.fee.message}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <CardFooter style={{ float: "right" }}>
                  <Button type="submit">Update</Button>
                </CardFooter>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            {user ? (
              <Card className={classes.card}>
                <CardAvatar profile style={{ margin: "-44px auto 0px" }}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImage}
                    ref={imageUploader}
                    // value={patient.profile_photo}
                    style={{
                      display: "none",
                    }}
                  />
                  <div
                    style={{
                      height: "132px",
                      width: "147px",
                      backgroundColor: "#489a9c",
                    }}
                    onClick={() => imageUploader.current.click()}
                  >
                    {user.attributes !== undefined ? (
                      <img
                        ref={uploadedImage}
                        // value={patient.profile_photo}
                        src={user.attributes.profile_photo}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "acsolute",
                          cursor: "pointer",
                        }}
                      />
                    ) : null}
                  </div>
                </CardAvatar>
                <CardBody>
                  <h6
                    className={classes.cardCategory}
                    style={{ textAlign: "center", fontWeight: 600 }}
                  >
                    {user.attributes.name}
                  </h6>
                  <br />
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.doctorInfoStyle}>
                        <TodayIcon />
                        <span style={{ fontWeight: 600 }}>Dob:</span>
                        <span style={{ marginLeft: 5 }}>
                          {user.attributes.dob}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.doctorInfoStyle}>
                        <WcIcon />
                        <span style={{ fontWeight: 600 }}>Gender:</span>
                        {user.attributes.gender === "male" ? (
                          <span style={{ marginLeft: 5 }}>Male</span>
                        ) : (
                          <span style={{ marginLeft: 5 }}>Female</span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.doctorInfoStyle}>
                        <LanguageIcon />
                        <span style={{ fontWeight: 600 }}>Language:</span>
                        <span style={{ marginLeft: 5 }}>
                          {user.attributes.default_language.value}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.doctorInfoStyle}>
                        <LocationOnIcon />
                        <span style={{ fontWeight: 600 }}>Role:</span>
                        <span style={{ marginLeft: 5 }}>
                          {user.attributes.role.role_title}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.doctorInfoStyle}>
                        <PhoneAndroidIcon />
                        <span style={{ fontWeight: 600 }}>Mobile:</span>
                        <span style={{ marginLeft: 5 }}>
                          {user.attributes.mobile}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.doctorInfoStyle}>
                        <EmailIcon />
                        <span style={{ fontWeight: 600 }}>Email:</span>
                        <span style={{ marginLeft: 5 }}>
                          {user.attributes.email}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            ) : null}
              <Card className={classes.card}>
                <CardBody>
                  <h6
                    className={classes.cardCategory}
                    style={{ textAlign: "center", fontWeight: 600 }}
                  >
                    Upload Sign
                  </h6>
                  <br />
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12}>
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      // ref={register({ required: true })}
                      onChange={handleSignImage}
                      ref={register()}
                      name="signature"
                    />
                    </Grid>
                   
                    <Grid item xs={12} sm={12} md={12}                 
                      style={{
                        display:'flex',
                        justifyContent:"center"
                      }}>
                    <div
                    style={{
                      height: "132px",
                      width: "147px",
                      border: "1px dashed #bfbfbf",
                      marginTop:'11%',
                      display:'flex',
                      justifyContent:"center"
                    }}
                  >
                      <img
                        src={user.attributes.sign}
                        ref={uploadedSignImage}
                        alt=""
                        style={{
                          width: "70%",
                          height: "70%",
                          marginTop:"12%"
                        }}
                      />
                  </div>
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userProfile.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserInfoRequest: () => dispatch(fetchUserInfoRequest()),
    profileUpdate: (formData) => dispatch(profileUpdate(formData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
