export const FETCH_PATHALOGY_TEST_REQUEST = 'FETCH_PATHALOGY_TEST_REQUEST';
export const FETCH_PATHALOGY_TEST_SUCCESS = 'FETCH_PATHALOGY_TEST_SUCCESS';
export const FETCH_PATHALOGY_TEST_FAILURE = 'FETCH_PATHALOGY_TEST_FAILURE';

export const FETCH_PATHALOGY_TEST_BY_NAME_REQUEST = 'FETCH_PATHALOGY_TEST_BY_NAME_REQUEST';
export const FETCH_PATHALOGY_TEST_BY_NAME_SUCCESS = 'FETCH_PATHALOGY_TEST_BY_NAME_SUCCESS';
export const FETCH_PATHALOGY_TEST_BY_NAME_FAILURE = 'FETCH_PATHALOGY_TEST_BY_NAME_FAILURE';


export const fetchPathologyTestRequest = (tests) => {
  console.log(tests)
  return {
      type: FETCH_PATHALOGY_TEST_REQUEST,
      tests:tests,
  }
}

export const fetchPathologyTestSuccess = (tests) => {
  return {
      type: FETCH_PATHALOGY_TEST_SUCCESS,
      payload: tests,
  }
}

export const fetchPathologyTestFailure = error => {
  return {
      type: FETCH_PATHALOGY_TEST_FAILURE,
      payload: error
  }
}

export const fetchPathologyTestByNameRequest = (testName) => {
  console.log(testName)
  return {
      type: FETCH_PATHALOGY_TEST_BY_NAME_REQUEST,
      testName:testName,
  }
}

export const fetchPathologyTestByNameSuccess = (testName) => {
  return {
      type: FETCH_PATHALOGY_TEST_BY_NAME_SUCCESS,
      payload: testName,
  }
}

export const fetchPathologyTestByNameFailure = error => {
  return {
      type: FETCH_PATHALOGY_TEST_BY_NAME_FAILURE,
      payload: error
  }
}
