import { takeEvery, call, put, takeLatest, delay } from "redux-saga/effects";
import { FETCH_USER_INFO_REQUEST, PROFILE_UPDATE } from "../actions";
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherUserProfileSaga() {
  yield takeEvery(FETCH_USER_INFO_REQUEST, workerFetchUserProfileDataSaga);
  yield takeLatest(PROFILE_UPDATE, workerUserProfileEditSaga);
}

function* workerFetchUserProfileDataSaga(action) {
  try {
    const res = yield call(doctorService.fetchUserProfileData);
    yield put(actions.fetchUserInfoSuccess(res.data.data));
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'current_user',
       method:'GET',
     })
    }
    yield put(actions.fetchUserInfoFailure(error));
  }
}

function* workerUserProfileEditSaga(action) {
  yield put(actions.reminderUpdateStart());
  try {
    const res = yield call(
      doctorService.userProfileUpdate,
      action.profileUpdateData
    );
    let profileResult = yield res.json();
    if(errorResponsesArray.includes(parseInt(res.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:res.statusText,
       errorCode : res.status,
       endPoint:'edit_user_profile',
       method:'POST',
     })
    }
    if (res.status === 201) {
      yield put(actions.profileUpdateSuccess(profileResult.message));
      // alert(profileResult.message);
    } else {
      alert(profileResult.error);
    }
  } catch (error) {
    yield put(actions.profileUpdateFail(error.response.data.error));
    alert(error.response.data.error);
  }
}
