import { takeEvery, call, put,takeLatest, delay } from "redux-saga/effects";
import { 
  LEAVE_ADD,
  FETCH_LEAVE_REQUEST,
  LEAVE_DELETE,
  FETCH_PATIENT_APPOINTMENTS
} from "../actions";
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { blockButton,errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherLeaveSaga() {
  yield takeLatest(LEAVE_ADD, workerLeaveAddSaga);
  yield takeEvery(FETCH_LEAVE_REQUEST, getLeavesSaga);
  yield takeLatest(LEAVE_DELETE, deleteLeavesSaga);
  yield takeEvery(FETCH_PATIENT_APPOINTMENTS, getAppointmentsSaga);

}

function* workerLeaveAddSaga(action) {
  yield put(actions.leaveAddStart());
  try {
    console.log(action.id)
    blockButton(action.id,true)
    const res = yield call(doctorService.leaveAdd,action.doctor_leave)
    if(res.status === 201){
    yield put(actions.leaveAddSuccess(res.data.message));
    }else{
      alert('Something went wrong !!')
    }
    // alert(res.data.message)
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'doctor_leaves',
       method:'POST',
     })
    }
    alert(error.response.data.error)
    yield put(actions.leaveAddFail(error.response.data.error));
  }
}

function* getLeavesSaga(action) {
  try {
    yield delay(1000)
    const response = yield call(doctorService.fetchLeave,action.url);
    yield put( actions.fetchLeaveSuccess(response.data.data));
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_doctor_leaves',
       method:'GET',
     })
    }
    yield put( actions.fetchLeaveFailure(error.response.data.error) );
  }
}

function* getAppointmentsSaga(action) {
  try {
    const response = yield call(doctorService.leaveAppointments,action.url);
    yield put( actions.fetchPatientAppointmentSuccess(response.data.data) );
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_appointments_in_duration',
       method:'GET',
     })
    }
    yield put( actions.fetchPatientAppointmentFailure(error) );
  }
}

function* deleteLeavesSaga(action) {
  yield put(actions.leaveDeleteStart());
  try {
    const response = yield call(doctorService.deleteLeaveId,action.id);
    yield put( actions.leaveDeleteSuccess(response.data.message) );
    // alert(response.data.message)
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'cancel_leave',
       method:'PUT',
     })
    }
    yield put( actions.leaveDeleteFail(error.response.data.error));
    alert(error.response.data.error)
  }
}







