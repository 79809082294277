import { takeEvery, call, put,delay } from "redux-saga/effects";
import { FETCH_MEDICALPROBLEM_REQUEST,EDIT_MEDICAL } from '../actions';
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherReviewSaga() {
 
  yield takeEvery(FETCH_MEDICALPROBLEM_REQUEST, workerSaga);
  yield takeEvery(EDIT_MEDICAL, workerEditMedicalSaga);


}

function* workerSaga(action) {
  try {
    const response = yield call(doctorService.fetchMedicalProblem,action.patientId,action.visitId);
    yield put( actions.fetchMedicalSuccess(response.data.data) );
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_patient_medical_problems_by_visit_id',
       method:'GET',
     })
    }
    yield put( actions.fetchMedicalFailure(error) );
  }
}

function* workerEditMedicalSaga(action) {
  yield put(actions.editMedicalStart());
  try {
    const response = yield call(doctorService.editMedicalProblem, action.formData,action.id);
    var updateMedication = yield response.json();
    if(errorResponsesArray.includes(parseInt(response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:response.statusText,
       errorCode : response.status,
       endPoint:'save_patient_medical_problems',
       method:'PUT',
     })
    }
    if (response.status === 200) {
      yield put(actions.editMedicalSuccess());
    // alert(updateMedication.message)
    const res = yield call(doctorService.fetchMedicalProblem,action.id,action.visitId);
    yield put( actions.fetchMedicalSuccess(res.data.data) );
    }else{
      alert(updateMedication.error)
    }
  } catch (error) {
    yield put(actions.editMedicalFail(error));
  }
}



