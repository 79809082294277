import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useTable from "../../../components/Table/useTable";
import { TableBody, TableRow, TableCell, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { fetchReminderRequest,reminderDelete,fetchReminderRequestById } from "../../../actions";
import EditIcon from "@material-ui/icons/Edit";
import Spinner from "../../../components/Spinner/Spinner";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditReminder from './EditReminder'
import moment from 'moment'

import DeleteIcon from "@material-ui/icons/Delete";
const styles = (theme) => ({
  fab: {
    margin: 2,
    backgroundColor: "#66a668",
    width: 50,
    height: 42,
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles(styles);

const headCells = [
  { id: "Reminder", label: "Reminder" },
  { id: "Reminder Date Time", label: "Reminder Date Time" },
  { id: "Action", label: "Action" },
];

const ReminderData = (props) => {
  const classes = useStyles();
  const { 
    reminder,
    fetchReminderRequest,
    count,
    reminderDelete,
    IncCount,
    loader,
    fetchReminderRequestById
   } = props;
  const { TblContainer, TblHead } = useTable(reminder, headCells);
  const [reminderEditModalOpen, setEditReminderModalOpen] = React.useState(false);
  const [reminderId,setReminderId] = React.useState('')

  useEffect(() => {
    const encodeGetParams = (p) =>
      Object.entries(p)
        .map((kv) => kv.map(encodeURIComponent).join("="))
        .join("&");

    let url = {
      is_notification_sent: 0,
    };
    fetchReminderRequest(encodeGetParams(url));
  }, [count]);

  const deleteReminder= (id) => {
    if (window.confirm("Do you want to Delete this Reminder ?")) {
      reminderDelete(id);
      IncCount()
      const encodeGetParams = (p) =>
      Object.entries(p)
        .map((kv) => kv.map(encodeURIComponent).join("="))
        .join("&");

    let url = {
      is_notification_sent: 0,
    };
    fetchReminderRequest(encodeGetParams(url));
    }
  };

  const handleEditReminderOpen = (id) => {
    setEditReminderModalOpen(true);
    console.log(id)
    setReminderId(id)
    fetchReminderRequestById(id)
  };

  const handleEditReminderClose = () => {
    setEditReminderModalOpen(false);
  };

  return (
    <div className={classes.root}>
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
          {loader ? <Spinner/>:
          <div className={classes.results}>
            <TblContainer>
              <TblHead style={{ backgroundColor: "rgb(76, 75, 70)" }} />
              <TableBody>
                {reminder.map((item) => (
                  <StyledTableRow key={item.id}>
                    <TableCell>{item.attributes.reminder_text}</TableCell>
                    <TableCell>
                      {moment.utc(item.attributes.reminder_datetime)
                      .local()
                      .format("YYYY-MM-DD HH:mm")}
                      </TableCell>
                    <TableCell>
                      <Tooltip
                        id="tooltip-top"
                        title="Edit"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label="Edit"
                          className={classes.tableActionButton}
                          onClick= {()=>handleEditReminderOpen(item.attributes.id)}
                        >
                          <EditIcon style={{ color: "blueviolet" }}
                          
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        id="tooltip-top"
                        title="Delete"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label="Delete"
                          className={classes.tableActionButton}
                          onClick={()=>deleteReminder(item.attributes.id)}
                        >
                          <DeleteIcon style={{ color: "#bd4a4a" }} 
                           />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <EditReminder 
                IncCount={IncCount}
                onClose={handleEditReminderClose}
                open={reminderEditModalOpen}
                reminderId={reminderId}

              />
            </TblContainer>
          </div>}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    reminder: state.reminder.reminder,
    loader: state.reminder.loading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchReminderRequest: (url) => dispatch(fetchReminderRequest(url)),
    reminderDelete : (id) =>dispatch(reminderDelete(id)),
    fetchReminderRequestById :(id)=>dispatch(fetchReminderRequestById(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReminderData);
