import * as actionTypes from "actions";

export const userInitialState = {
  loading: false,
  patients: [],
  searchPatient:[],
  searchPrescription:[],
  rowCount:'',
  patientCounter:0,
  error: "",
};

const fetchPatientReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PATIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        patients: action.payload,
        rowCount:action.rowCount,
        error: "",
      };
    case actionTypes.FETCH_PATIENT_FAILURE:
      return {
        ...state,
        loading: false,
        patients: [],
        rowCount:'',
        error: action.payload,
      };
      case actionTypes.FETCH_SEARCH_PATIENT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.FETCH_SEARCH_PATIENT_SUCCESS:
        return {
          ...state,
          loading: false,
          searchPatient: action.payload,
          error: "",
        };
      case actionTypes.FETCH_SEARCH_PATIENT_FAILURE:
        return {
          ...state,
          loading: false,
          searchPatient: [],
          error: action.payload,
        };
        case actionTypes.FETCH_SEARCH_PATIENT_BY_DIAGNOSIS_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case actionTypes.FETCH_SEARCH_PATIENT_BY_DIAGNOSIS_SUCCESS:
          return {
            ...state,
            loading: false,
            searchPrescription: action.payload,
            error: "",
          };
        case actionTypes.FETCH_SEARCH_PATIENT_BY_DIAGNOSIS_FAILURE:
          return {
            ...state,
            loading: false,
            searchPrescription: [],
            error: action.payload,
          };
    case actionTypes.SELF_ASSIGN_START:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case actionTypes.SELF_ASSIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case actionTypes.SELF_ASSIGN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case actionTypes.PATIENT_DATA_COUNTER:
        return {
          ...state,
          loading: false,
          patientCounter:state.patientCounter+1,
          error: '',
        };
    default:
      return state;
  }
};

export default fetchPatientReducer;
