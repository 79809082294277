import { takeEvery, call, put,delay } from "redux-saga/effects";
import { FETCH_DIET_TEST_REQUEST,FETCH_DIET_TEST_BY_NAME } from '../actions';
// import doctorService from "../services/doctorService";
import * as actions from "../actions";


export function* watcherDietSaga() {
  yield takeEvery(FETCH_DIET_TEST_REQUEST, dietSelectedSaga);
  yield takeEvery(FETCH_DIET_TEST_BY_NAME, dietSelectedByNameSaga);

}

function* dietSelectedSaga(action) {
  try {
    yield put( actions.fetchDietTestSuccess(action.tests) );
  } catch (error) {
    yield put( actions.fetchDietTestFailure(error) );
  }
}

function* dietSelectedByNameSaga(action) {
  actions.fetchDietTestByNameStart()
  try {
    yield put( actions.fetchDietTestByNameSuccess(action.testName) );
  } catch (error) {
    yield put( actions.fetchDietTestByNameFailure(error) );
  }
}
