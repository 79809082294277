import { takeEvery, call, put } from "redux-saga/effects";
import { FETCH_PATIENTDETAIL_BY_ID_REQUEST } from '../actions';
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherPatientDetailSaga() {
  yield takeEvery(FETCH_PATIENTDETAIL_BY_ID_REQUEST, patientDetailSaga);
}

function* patientDetailSaga(action) {
  try {
    const response = yield call(doctorService.fetchPatientDetail,action.patientId);
    yield put( actions.fetchPatientDetailByIdSuccess(response.data.data) );
  } catch (error) {
    yield put( actions.fetchPatientDetailByIdFailure(error) );
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'patients',
       method:'GET',
     })
    }
  }
}


