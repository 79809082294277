import { takeEvery, call, put,delay } from "redux-saga/effects";
import { SET_DOCTOR_NOTES_REQUEST ,SET_INSTRUCTION_REQUEST} from '../actions';
import doctorService from "../services/doctorService";
import * as actions from "../actions";


export function* watcherDoctorNotesSaga() {
  yield takeEvery(SET_DOCTOR_NOTES_REQUEST, doctorNotesSaga);
  yield takeEvery(SET_INSTRUCTION_REQUEST, InstructionSaga);

}

function* doctorNotesSaga(action) {
  try {
    yield put( actions.setDoctorNotesSuccess(action.notes) );
  } catch (error) {
    yield put( actions.setDoctorNotesFailure(error) );
  }
}

function* InstructionSaga(action) {
  try {
    yield put( actions.setInstructionSuccess(action.instruction) );
  } catch (error) {
    yield put( actions.setInstructionFailure(error) );
  }
}

