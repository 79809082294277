import { takeEvery, call, put,delay } from "redux-saga/effects";
import { SET_FOLLOW_UP_VALUES } from '../actions';
import doctorService from "../services/doctorService";
import * as actions from "../actions";


export function* watcherFollowupSaga() {
  yield takeEvery(SET_FOLLOW_UP_VALUES, setFollowupValuesSaga);
}

function* setFollowupValuesSaga(action) {
  try {
    if(action.follow.follow !== ''){
    yield put( actions.setFollowUp(action.follow.follow) );
    }else if(action.follow.follow_type !== ''){
      yield put(actions.setFollowUpType(action.follow.follow_type))
    }else if(action.follow.follow_for !== ''){
      yield put(actions.setFollowUpFor(action.follow.follow_for))
    }
  } catch (error) {
    yield put( actions.setFollowUpValuesFailure(error) );
  }
}


