import { takeEvery, call, put,takeLatest,delay } from "redux-saga/effects";
import { 
  REMINDER_ADD,
  FETCH_REMINDER_REQUEST,
  REMINDER_DELETE,
  FETCH_REMINDER_REQUEST_BY_ID,
  REMINDER_UPDATE
} from "../actions";
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { blockButton,errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherReminderSaga() {
  yield takeEvery(REMINDER_ADD, workerReminderAddSaga);
  yield takeEvery(FETCH_REMINDER_REQUEST, workerFetchReminderSaga);
  yield takeLatest(REMINDER_DELETE, deleteReminderSaga);
  yield takeLatest(FETCH_REMINDER_REQUEST_BY_ID, workerFetchReminderByIdSaga);
  yield takeLatest(REMINDER_UPDATE, workerReminderEditSaga);

}

function* workerFetchReminderSaga(action) {
  try {
    yield delay(1000)
    const res = yield call(doctorService.fetchReminder,action.url);
    yield put(actions.fetchReminderSuccess(res.data.data));

  } catch (error) {
    yield put(actions.fetchReminderFailure(error));
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_doctor_reminders',
       method:'GET',
     })
    }
  }
}

function* workerFetchReminderByIdSaga(action) {
  try {
    const res = yield call(doctorService.fetchReminderById,action.id);
    yield put(actions.fetchReminderSuccessById(res.data.data));

  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'doctor_reminders',
       method:'GET',
     })
    }
    yield put(actions.fetchReminderFailureById(error));
  }
}

function* workerReminderAddSaga(action) {
  yield put(actions.reminderAddStart());
  try {
    blockButton(action.id,true)
    const res = yield call(doctorService.reminderAdd,action.doctor_reminder);
    if(res.status === 201){
    yield put(actions.reminderAddSuccess(res.data.message));
    }else{
      alert('Something went wrong !!!')
    }
    // alert(res.data.message)
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'doctor_reminders',
       method:'POST',
     })
    }
    yield put(actions.reminderAddFail(error.response.data.error));
    alert(error.response.data.error)
  }
}

function* workerReminderEditSaga(action) {
  yield put(actions.reminderUpdateStart());
  try {
    const res = yield call(doctorService.reminderEdit,action.doctor_reminder,action.id);
    yield put(actions.reminderUpdateSuccess(res.data.message));
    // alert(res.data.message)
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'doctor_reminders',
       method:'PUT',
     })
    }
    yield put(actions.reminderUpdateFail(error.response.data.error));
    alert(error.response.data.error)
  }
}

function* deleteReminderSaga(action) {
  yield put(actions.reminderDeleteStart());
  try {
    const response = yield call(doctorService.deleteReminder,action.id);
    if(response.status === 204){
     yield put( actions.reminderDeleteSuccess(response.data.message));
    }else{
      alert('Something went wrong !!!')
    }
    // alert(response.data.message)
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'doctor_reminders',
       method:'DELETE',
     })
    }
    yield put( actions.reminderDeleteFail(error.response.data.error));
    alert(error.response.data.error)
  }
}





