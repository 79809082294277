import * as actionTypes from "actions";
const userInitialState = {
  loading: false,
  diagnosis: [],
  message:'',
  count : 0,
  diagnosisTypeValue:'Provisional Diagnosis',
  selectedDisease:[],
  diseaseName:[],
  error: "",
};

const differentialReducer = (state = userInitialState,  action) => {
  switch (action.type) {
    case actionTypes.DIFFERENTIAL_TYPE_ADD:
      return {
        ...state,
        loading: false,
        diagnosisTypeValue:action.value
      };
      case actionTypes.DIFFERENTIAL_COUNT:
        return {
          ...state,
          loading: false,
          count:state.count + 1
        };
    case actionTypes.FETCH_DIFFERENTIAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_DIFFERENTIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        message:'',
        // selectedDisease:[],
        diagnosis: action.payload,
        error: "",
      };
    case actionTypes.FETCH_DIFFERENTIAL_FAILURE:
      return {
        ...state,
        loading: false,
        diagnosis: [],
        message:'',
        error: action.payload,
      };
      case actionTypes.DIFFERENTIAL_ADD_START:
        return {
          ...state,
          ...{ loading: true, error: null },
        };
      case actionTypes.DIFFERENTIAL_ADD_SUCCESS:
        return {
          ...state,
          message: action.message,
          selectedDisease:action.payload,
          diagnosis:[],
          loading: false,
        };
        case actionTypes.DIFFERENTIAL_ADD_EMPTY:
          return {
            ...state,
            message:'',
            diagnosis:[],
            loading: false,
          };
        case actionTypes.DIFFERENTIAL_ADD_FAILURE:
          return {
            ...state,
            message:'',
            selectedDisease:[],
            loading: false,
          };
          case actionTypes.DIFFERENTIAL_ADD_BY_NAME_START:
            return {
              ...state,
              loading: true,
            };
          case actionTypes.DIFFERENTIAL_ADD_BY_NAME_SUCCESS:
            return {
              ...state,
              loading: false,
              message: action.message,
              diseaseName:action.payload,
              error: "",
            };
          case actionTypes.DIFFERENTIAL_ADD_BY_NAME_FAILURE:
            return {
              ...state,
              loading: false,
              message:'',
              diseaseName:[],
              error: action.payload,
            };
    default:
      return state;
  }
};

export default differentialReducer;
