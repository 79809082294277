import { takeEvery, call, put } from "redux-saga/effects";
import { FETCH_PATIENT_REQUEST, SELF_ASSIGN_START,FETCH_SEARCH_PATIENT_REQUEST,FETCH_SEARCH_PATIENT_BY_DIAGNOSIS_REQUEST } from '../actions';
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherPatientSaga() {
  yield takeEvery(FETCH_PATIENT_REQUEST, workerSaga);
  yield takeEvery(SELF_ASSIGN_START, selfAssign);
  yield takeEvery(FETCH_SEARCH_PATIENT_REQUEST, searchPatientSaga);
  yield takeEvery(FETCH_SEARCH_PATIENT_BY_DIAGNOSIS_REQUEST,searchPatientByDiagnosisSaga)
}

function* workerSaga(action) {
  try {
    if(action.val === 'individual'){
    const response = yield call(doctorService.fetchIndividualPatient,action.url);
    yield put(actions.fetchPatientSuccess(response.data.data.data,response.data.total_rows));
    }else{
      const response = yield call(doctorService.fetchCategoryPatient,action.val,action.url);
      yield put( actions.fetchPatientSuccess(response.data.data.data,response.data.total_rows) );
    }
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_doctor_today_visits',
       method:'GET',
     })
    }
    yield put( actions.fetchPatientFailure(error) );
  }
}

function* searchPatientSaga(action) {
  try {
    const response = yield call(doctorService.searchPatient,action.url);
    yield put( actions.fetchSearchPatientSuccess(response.data.data.data));
  } catch (error) {
    console.log(error.response.statusText)
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_doctor_patients',
       method:'GET',
     })
    }
    yield put( actions.fetchSearchPatientFailure(error) );
  }
}

function* searchPatientByDiagnosisSaga(action) {
  try {
    const response = yield call(doctorService.searchPatientByDiagnosis,action.patientId,action.diagnosis);
    yield put( actions.fetchSearchPatientByDiagnosisSuccess(response.data.data));
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_patient_prescriptions_by_diagnosis',
       method:'GET',
     })
    }
    yield put( actions.fetchSearchPatientByDiagnosisFailure(error) );
  }
}

function* selfAssign(action) {
 try{
  const response = yield call(doctorService.selfAssignPatient, action.id);
  console.log(response)
  yield put(actions.assignToSelfSuccess())
  const res = yield call(doctorService.fetchIndividualPatient);
  yield put( actions.fetchPatientSuccess(res.data.data.data,res.data.total_rows) );
 }catch(error){
  if(errorResponsesArray.includes(parseInt(error.response.status))){
    slackDebugger({
     actionType:"errorLogging",
     errorMessage:error.response.statusText,
     errorCode : error.response.status,
     endPoint:'assign_visit_to_doctor',
     method:'PUT',
   })
  }
  alert(error.response.data.error)
  yield put(actions.assignToSelfFail())
 }
}

