import React, { useState, useEffect } from "react";
import Notification from "./Sounds/Notification.wav";

const NotificationSound = ({ notificationType }) => {
  const [audio, setAudio] = useState();
  const [playingSound, setPlayingSound] = useState(false);

  useEffect(() => {
    setAudio(new Audio(Notification));
    setPlayingSound(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationType]);

  useEffect(() => {
    if (playingSound) {
      PlaySound();
      setTimeout(() => {
        PauseSound();
      }, 2000);
    }
  }, [playingSound]);

  const PauseSound = () => {
    console.log("Inside pause block");
    audio.pause();
    setPlayingSound(false);
    audio.addEventListener("ended", () => setPlayingSound(false));
    return () => {
      audio.removeEventListener("ended", () => setPlayingSound(false));
    };
  };

  const PlaySound = () => {
    if (playingSound) {
      audio
        .play()
        .then(() => {
          console.log("Playback resumed successfully");
        })
        .catch((err) => console.log(err));
      audio.addEventListener("ended", () => setPlayingSound(false));
      return () => {
        audio.removeEventListener("ended", () => setPlayingSound(false));
      };
    }
  };

  return <div></div>;
};

export default NotificationSound;
