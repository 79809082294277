import * as actionTypes from "actions";
const userInitialState = {
  loading: false,
  pathology: [],
  radio:[],
  diet:[],
  exercise:[],
  title:'',
  error: "",
};

const suggestionReducer = (state = userInitialState,  action) => {
  switch (action.type) {
    case actionTypes.FETCH_PATHOLOGY_SUGGESTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_PATHOLOGY_SUGGESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        pathology: action.payload,
        title:action.title,
        radio:[],
        exercise:[],
        diet:[],
        error: "",
      };
    case actionTypes.FETCH_PATHOLOGY_SUGGESTION_FAILURE:
      return {
        ...state,
        loading: false,
        pathology: [],
        error: action.payload,
      };
      case actionTypes.FETCH_RADIO_SUGGESTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_RADIO_SUGGESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        radio: action.payload,
        title:action.title,
        pathology:[],
        exercise:[],
        diet:[],
        error: "",
      };
    case actionTypes.FETCH_RADIO_SUGGESTION_FAILURE:
      return {
        ...state,
        loading: false,
        radio: [],
        error: action.payload,
      };
      case actionTypes.FETCH_DIET_SUGGESTION_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.FETCH_DIET_SUGGESTION_SUCCESS:
        return {
          ...state,
          loading: false,
          diet: action.payload,
          title:action.title,
          exercise:[],
          radio:[],
          pathology:[],
          error: "",
        };
      case actionTypes.FETCH_DIET_SUGGESTION_FAILURE:
        return {
          ...state,
          loading: false,
          diet: [],
          error: action.payload,
        };
        case actionTypes.FETCH_EXERCISE_SUGGESTION_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case actionTypes.FETCH_EXERCISE_SUGGESTION_SUCCESS:
          return {
            ...state,
            loading: false,
            exercise: action.payload,
            title:action.title,
            radio:[],
            diet:[],
            pathology:[],
            error: "",
          };
        case actionTypes.FETCH_EXERCISE_SUGGESTION_FAILURE:
          return {
            ...state,
            loading: false,
            exercise: [],
            error: action.payload,
          };
          // case actionTypes.FETCH_EMPTY_TITLE_REQUEST:
          //   return {
          //     ...state,
          //     loading: true,
          //   };
          case actionTypes.FETCH_EMPTY_TITLE_SUCCESS:
            return {
              ...state,
              loading: false,
              pathology: [],
              title:action.data,
              radio:[],
              exercise:[],
              diet:[],
              error: "",
            };
          case actionTypes.FETCH_EMPTY_TITLE_FAILURE:
            return {
              ...state,
              loading: false,
              pathology: [],
              error: action.payload,
            };
    default:
      return state;
  }
};

export default suggestionReducer;
