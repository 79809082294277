import * as actionTypes from "actions";
const userInitialState = {
  loading: false,
  timeline: [],
  error: "",
};

const timelineReducer = (state = userInitialState,  action) => {
  switch (action.type) {
    case actionTypes.FETCH_TIMELINE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_TIMELINE_SUCCESS:
      return {
        ...state,
        loading: false,
        timeline: action.payload,
        error: "",
      };
    case actionTypes.FETCH_TIMELINE_FAILURE:
      return {
        ...state,
        loading: false,
        timeline: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default timelineReducer;
