import { takeEvery, call, put, takeLatest } from "redux-saga/effects";
import { FETCH_TIMELINE_DATA_REQUEST } from "../actions";
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherTimelineDataSaga() {
  yield takeLatest(FETCH_TIMELINE_DATA_REQUEST, timelineVitalDataSaga);
  yield takeLatest(FETCH_TIMELINE_DATA_REQUEST, timelineMedicalProblemDataSaga);
  yield takeLatest(FETCH_TIMELINE_DATA_REQUEST, timelinePrescriptionDataSaga);
}

function* timelineVitalDataSaga(action) {
  try {
    let response = yield call(
      doctorService.fetchVital,
      action.patientId,
      action.visitId
    );
    yield put(actions.fetchTimelineVitalSuccess(response.data.data));
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_patient_vitals_by_visit_id',
       method:'GET',
     })
    }
    yield put(actions.fetchTimelineVitalFailure(error));
  }
}

function* timelineMedicalProblemDataSaga(action) {
  try {
    const response = yield call(
      doctorService.fetchMedicalProblem,
      action.patientId,
      action.visitId
    );
    console.log(response.data.data);
    yield put(actions.fetchTimelineMedicalSuccess(response.data.data));
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_patient_medical_problems_by_visit_id',
       method:'GET',
     })
    }
    yield put(actions.fetchTimelineMedicalFailure(error));
  }
}

function* timelinePrescriptionDataSaga(action) {
  try {
    const res = yield call(
      doctorService.fetchPrescriptionById,
      action.patientId,
      action.visitId
    );
    let timelinePrescription = [];
    timelinePrescription.push(res.data.data);
    yield put(actions.fetchTimelinePrescriptionSuccess(timelinePrescription));
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_prescription_by_visit_id',
       method:'GET',
     })
    }
    yield put(actions.fetchTimelinePrescriptionFailure(error));
  }
}
