export const REMINDER_ADD = 'REMINDER_ADD';
export const REMINDER_ADD_START = 'REMINDER_ADD_START';
export const REMINDER_ADD_SUCCESS = 'REMINDER_ADD_SUCCESS';
export const REMINDER_ADD_FAILURE = 'REMINDER_ADD_FAILURE';

export const REMINDER_UPDATE = 'REMINDER_UPDATE';
export const REMINDER_UPDATE_START = 'REMINDER_UPDATE_START';
export const REMINDER_UPDATE_SUCCESS = 'REMINDER_UPDATE_SUCCESS';
export const REMINDER_UPDATE_FAILURE = 'REMINDER_UPDATE_FAILURE';

export const FETCH_REMINDER_REQUEST = 'FETCH_REMINDER_REQUEST';
export const FETCH_REMINDER_SUCCESS = 'FETCH_REMINDER_SUCCESS';
export const FETCH_REMINDER_FAILURE = 'FETCH_REMINDER_FAILURE';

export const REMINDER_DELETE = 'REMINDER_DELETE';
export const REMINDER_DELETE_START = 'REMINDER_DELETE_START';
export const REMINDER_DELETE_SUCCESS = 'REMINDER_DELETE_SUCCESS';
export const REMINDER_DELETE_FAILURE = 'REMINDER_DELETE_FAILURE';

export const FETCH_REMINDER_REQUEST_BY_ID = 'FETCH_REMINDER_REQUEST_BY_ID';
export const FETCH_REMINDER_SUCCESS_BY_ID = 'FETCH_REMINDER_SUCCESS_BY_ID';
export const FETCH_REMINDER_FAILURE_BY_ID = 'FETCH_REMINDER_FAILURE_BY_ID';


export const fetchReminderRequestById = (id) => {
  return {
      type: FETCH_REMINDER_REQUEST_BY_ID,
      id:id

  }
}

export const fetchReminderSuccessById = reminder => {
  return {
      type: FETCH_REMINDER_SUCCESS_BY_ID,
      payload: reminder
  }
}

export const fetchReminderFailureById = error => {
  return {
      type: FETCH_REMINDER_FAILURE_BY_ID,
      payload: error
  }
}


export const reminderDelete = (id) => {
  return {
      type: 'REMINDER_DELETE',
      id:id
  }
};

export const reminderDeleteStart = () => {
  return {
      type: 'REMINDER_DELETE_START'
  }
};

export const reminderDeleteSuccess = (data) => {
  return {
      type: 'REMINDER_DELETE_SUCCESS',
      payload: data,
  }
};


export const reminderDeleteFail = (error) => {
  return {
      type: 'REMINDER_DELETE_FAILURE',
      error: error
  }
};


export const fetchReminderRequest = (url) => {
  return {
      type: FETCH_REMINDER_REQUEST,
      url:url

  }
}

export const fetchReminderSuccess = reminder => {
  return {
      type: FETCH_REMINDER_SUCCESS,
      payload: reminder
  }
}

export const fetchReminderFailure = error => {
  return {
      type: FETCH_REMINDER_FAILURE,
      payload: error
  }
}



export const reminderAdd = (doctor_reminder,id) => {
  return {
      type: 'REMINDER_ADD',
      doctor_reminder:doctor_reminder,
      id:id
  }
};

export const reminderAddStart = () => {
  return {
      type: 'REMINDER_ADD_START'
  }
};

export const reminderAddSuccess = (data) => {
  return {
      type: 'REMINDER_ADD_SUCCESS',
      payload: data,
  }
};


export const reminderAddFail = (error) => {
  return {
      type: 'REMINDER_ADD_FAILURE',
      error: error
  }
};


export const reminderUpdate = (doctor_reminder,id) => {
  return {
      type: 'REMINDER_UPDATE',
      doctor_reminder:doctor_reminder,
      id:id
  }
};

export const reminderUpdateStart = () => {
  return {
      type: 'REMINDER_UPDATE_START'
  }
};

export const reminderUpdateSuccess = (data) => {
  return {
      type: 'REMINDER_UPDATE_SUCCESS',
      payload: data,
  }
};


export const reminderUpdateFail = (error) => {
  return {
      type: 'REMINDER_UPDATE_FAILURE',
      error: error
  }
};