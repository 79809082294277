import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import CardBody from "../../components/Card/CardBody";
import CustomButton from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import Controls from "../../components/controls/Controls";
// import { Typography } from "@material-ui/core";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Form } from "../../components/Form/useForm";
import moment from "moment";
import { connect } from "react-redux";
import { fetchPatientAppointmentRequest } from "../../actions";
import LeaveData from "./LeaveData";
import AppointmentData from "./Appointment/AppointmentData";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "block",
    boxSizing: "border-box",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid black",
    padding: "14px 15px",
    marginTop: "9px",
    fontSize: "14px",
  },
}));

const Leave = (props) => {
  const { fetchPatientAppointmentRequest } = props;
  const classes = useStyles();
  const { values, errors, setErrors, handleInputChange } = useForm(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false);
  const [leaveData, setLeaveData] = useState({});

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <input
        onClick={props.onClick}
        value={props.value}
        className={classes.input}
        type="text"
        placeholder="Select Start Date Time "
        readOnly={true}
      />
    );
  });

  const CustomInputEnd = React.forwardRef((props, ref) => {
    return (
      <input
        onClick={props.onClick}
        value={props.value}
        className={classes.input}
        type="text"
        placeholder="Select End Date Time "
        readOnly={true}
      />
    );
  });

  const handleStartDate = (value) => {
    setStartDate(value);
    console.log(value);
  };

  const handleEndDate = (value) => {
    setEndDate(value);
  };

  const handleAppointmentOpen = () => {
    setAppointmentModalOpen(true);
  };

  const handleAppointmentClose = () => {
    setAppointmentModalOpen(false);
  };

  const EndDate = () => {
    return (
      <Grid item xs={12} sm={3} md={3}>
        <ReactDatePicker
          valueName="selected" // DateSelect value's name is selected
          onChange={(date) => handleEndDate(date)}
          name="end_datetime"
          selected={endDate}
          dateFormat="yyyy-MM-dd"
          minDate={moment().toDate()}
          isClearable
          customInput={<CustomInputEnd />}
          popperPlacement="bottom-start"
          popperModifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
            },
          }}
        />
      </Grid>
    );
  };

  const StartDate = () => {
    return (
      <Grid item xs={12} sm={3} md={3}>
        <ReactDatePicker
          valueName="selected" // DateSelect value's name is selected
          onChange={(date) => handleStartDate(date)}
          selected={startDate}
          name="start_datetime"
          dateFormat="yyyy-MM-dd"
          minDate={moment().toDate()}
          isClearable
          customInput={<CustomInput />}
          popperPlacement="bottom-start"
          popperModifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
            },
          }}
        />
      </Grid>
    );
  };
  const EndDateTime = () => {
    return (
      <Grid item xs={12} sm={3} md={3}>
        <ReactDatePicker
          valueName="selected" // DateSelect value's name is selected
          onChange={(date) => handleEndDate(date)}
          name="end_datetime"
          selected={endDate}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={10}
          timeCaption="time"
          dateFormat="yyyy-MM-dd HH:mm "
          minDate={moment().toDate()}
          isClearable
          customInput={<CustomInputEnd />}
          popperPlacement="bottom-start"
          popperModifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
            },
          }}
        />
      </Grid>
    );
  };

  const StartDateTime = () => {
    return (
      <Grid item xs={12} sm={3} md={3}>
        <ReactDatePicker
          valueName="selected" // DateSelect value's name is selected
          onChange={(date) => handleStartDate(date)}
          selected={startDate}
          name="start_datetime"
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={10}
          timeCaption="time"
          dateFormat="yyyy-MM-dd HH:mm "
          minDate={moment().toDate()}
          isClearable
          customInput={<CustomInput />}
          popperPlacement="bottom-start"
          popperModifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
            },
          }}
        />
      </Grid>
    );
  };

  const handleSubmit = (e) => {
    let date;
    let enddate;
    let newStartDate;
    let newEndDate;
    if (values.leave_type === "period") {
      if(!startDate){
        alert("Please select start date");
        return false;
      }
      if(!endDate){
        alert("Please select end date");
        return false;
      }
      if (
        new Date(moment(startDate).format("YYYY-MM-DD")).getTime() <=
        new Date(moment(endDate).format("YYYY-MM-DD")).getTime()
      ) {
      } else {
        alert("Start date should not be greater than end date");
        return false;
      }
      date = moment(startDate).format("YYYY-MM-DD");
      enddate = moment(endDate).format("YYYY-MM-DD");
      newStartDate = new Date(date).toISOString();
      newEndDate = new Date(enddate).toISOString();
    } else if (values.leave_type === "day") {
      if(!startDate){
        alert("Please select start date");
        return false;
      }
      date = moment(startDate).format("YYYY-MM-DD");
      newStartDate = new Date(date).toISOString();
      newEndDate = "";
    } else if (values.leave_type === "hours") {
      if(!startDate){
        alert("Please select start date");
        return false;
      }
      if(!endDate){
        alert("Please select end date");
        return false;
      }
      let d1 = new Date();
      if (new Date(d1).getTime() <= new Date(startDate).getTime()) {
      } else {
        alert("You are selecting past start time.Please Select Valid Time");
        return false;
      }
      let d2 = new Date();
      if (new Date(d2).getTime() <= new Date(endDate).getTime()) {
      } else {
        alert("You are selecting past end time.Please Select Valid Time");
        return false;
      }
      date = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
      enddate = moment(endDate).format("YYYY-MM-DD HH:mm:ss");
      newStartDate = new Date(date).toISOString();
      newEndDate = new Date(enddate).toISOString();
    }
    let doctor_leave = {
      leave_type: values.leave_type,
      leave_start_time: newStartDate,
      leave_end_time: newEndDate,
    };
    setLeaveData(doctor_leave);
    const encodeGetParams = (p) =>
      Object.entries(p)
        .map((kv) => kv.map(encodeURIComponent).join("="))
        .join("&");
    console.log(doctor_leave);
    fetchPatientAppointmentRequest(encodeGetParams(doctor_leave));
    handleAppointmentOpen();
  };

  return (
    <div className={classes.root1}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Card style={{ marginTop: 1, backgroundColor: "#fffff2" }}>
            <CardBody>
              <Form>
                <Grid container>
                  <Grid item xs={12} sm={3} md={3}>
                    <Controls.Select
                      name="leave_type"
                      label="Leave Type"
                      value={values.leave_type || ""}
                      options={leaveType.map((e) => ({
                        title: e.title,
                        value: e.id,
                        id: e.id,
                      }))}
                      // style={{ margin:-6 }}
                      onChange={handleInputChange}
                      onClick={()=>{setStartDate();setEndDate()}}
                      error={errors.leave_type}
                    />
                  </Grid>
                  {values.leave_type === "day" ? (
                    <React.Fragment>{StartDate()}</React.Fragment>
                  ) : null}
                  {values.leave_type === "period" ? (
                    <React.Fragment>
                      {StartDate()}
                      {EndDate()}
                    </React.Fragment>
                  ) : null}
                  {values.leave_type === "hours" ? (
                    <React.Fragment>
                      {StartDateTime()}
                      {EndDateTime()}
                    </React.Fragment>
                  ) : null}
                  <Grid item xs={12} sm={3} md={3}>
                    <CustomButton
                      onClick={handleSubmit}
                      style={{
                        width: 72,
                        marginTop: 12,
                        backgroundColor: "rgb(50, 123, 175)",
                      }}
                    >
                      Submit
                    </CustomButton>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <LeaveData />
                  </Grid>
                  <AppointmentData
                    onClose={handleAppointmentClose}
                    open={appointmentModalOpen}
                    leaveData={leaveData}
                  />
                </Grid>
              </Form>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPatientAppointmentRequest: (url) =>
      dispatch(fetchPatientAppointmentRequest(url)),
  };
};

export default connect(null, mapDispatchToProps)(Leave);

const leaveType = [
  { id: "hours", title: "Hours" },
  { id: "day", title: "Day" },
  { id: "period", title: "Extended Period" },
];
