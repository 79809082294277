import * as actionTypes from "actions";

const INITIAL_STATE = {
  error: null,
  data:[],
  brandData:'',
  loader:false,
  message:'',
  tempData:[],
  loading: false,
};

const formularyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FORMULARY_SEARCH_START:
      return {
        ...state,
        ...{ loading: true, error: null },
      };
    case actionTypes.FORMULARY_SEARCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
      case actionTypes.FETCH_FORMULARY_EMPTY:
        return {
          ...state,
          data: [],
          tempData:[],
          brandData:'',
          loading: false,
        };
    case actionTypes.FORMULARY_SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        data:[],
        error: action.error,
      };
      case actionTypes.FORMULARY_TEMP_SEARCH_START:
        return {
          ...state,
          ...{  error: null },
        };
      case actionTypes.FORMULARY_TEMP_SEARCH_SUCCESS:
        return {
          ...state,
          tempData: action.payload,
          // loading: false,
        };
      case actionTypes.FORMULARY_TEMP_SEARCH_FAIL:
        return {
          ...state,
          // loading: false,
          tempData:[],
          error: action.error,
        };
      case actionTypes.FETCH_FORMULARY_BRANDDETAIL_START:
      return {
        ...state,
        ...{ loading: true, error: null },
      };
    case actionTypes.FETCH_FORMULARY_BRANDDETAIL_SUCCESS:
      return {
        ...state,
        brandData: action.payload,
        loading: false,
      };
      
    case actionTypes.FETCH_FORMULARY_BRANDDETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        brandData:'',
        error: action.error,
      };
      case actionTypes.FORMULARY_ADD_START:
        return {
          ...state,
          ...{ loading: true, error: null },
        };
      case actionTypes.FORMULARY_ADD_SUCCESS:
        return {
          ...state,
          message: action.payload,
          data:[],
          brandData:'',
          tempData:[],
          loading: false,
        };
        case actionTypes.FORMULARY_ADD_EMPTY:
          return {
            ...state,
            message:'',
            loading: false,
          };
        case actionTypes.FORMULARY_ADD_FAILURE:
          return {
            ...state,
            message:'',
            loading: false,
          };
    default:
      return state;
  }
};

export default formularyReducer;
