export const HANDLE_VIDEO_CALL_SUBSCRIBTION = "HANDLE_VIDEO_CALL_SUBSCRIBTION";

export const START_VIDEO_CALL = "START_VIDEO_CALL";

export const handleVideoCallSubscribtion = (payload) => {
  return {
    type: HANDLE_VIDEO_CALL_SUBSCRIBTION,
    payload: payload,
  };
};

export const startVideoCall = (payload) => {
  return {
    type: START_VIDEO_CALL,
    payload: payload,
  };
};