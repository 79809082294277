import * as actionTypes from "actions";
const userInitialState = {
  diet: [],
  loading:false,
  dietName:[]
};

const dietReducer = (state = userInitialState,  action) => {
  switch (action.type) {
    case actionTypes.FETCH_DIET_TEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_DIET_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        diet: action.payload,
        error: "",
      };
    case actionTypes.FETCH_DIET_TEST_FAILURE:
      return {
        ...state,
        loading: false,
        diet: [],
        error: action.payload,
      };
      case actionTypes.FETCH_DIET_TEST_BY_NAME_START:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.FETCH_DIET_TEST_BY_NAME_SUCCESS:
        return {
          ...state,
          loading: false,
          dietName:action.payload,
          error: "",
        };
      case actionTypes.FETCH_DIET_TEST_BY_NAME_FAILURE:
        return {
          ...state,
          loading: false,
          dietName:[],
          error: action.payload,
        };
    default:
      return state;
  }
};

export default dietReducer;
