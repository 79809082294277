export const FETCH_MEDICALPROBLEM_REQUEST = 'FETCH_MEDICALPROBLEM_REQUEST';
export const FETCH_MEDICALPROBLEM_SUCCESS = 'FETCH_MEDICALPROBLEM_SUCCESS';
export const FETCH_MEDICALPROBLEM_FAILURE = 'FETCH_MEDICALPROBLEM_FAILURE';
export const EDIT_MEDICAL='EDIT_MEDICAL'
export const EDIT_MEDICAL_START='EDIT_MEDICAL_START'
export const EDIT_MEDICAL_SUCCESS='EDIT_MEDICAL_SUCCESS'
export const EDIT_MEDICAL_FAIL='EDIT_MEDICAL_FAIL'

export const fetchMedicalRequest = (patientId,visitId) => {
  return {
      type: FETCH_MEDICALPROBLEM_REQUEST,
      patientId:patientId,
      visitId:visitId
  }
}

export const fetchMedicalSuccess = medical => {
  return {
      type: FETCH_MEDICALPROBLEM_SUCCESS,
      payload: medical
  }
}

export const fetchMedicalFailure = error => {
  return {
      type: FETCH_MEDICALPROBLEM_FAILURE,
      payload: error
  }
}

export const editMedical = (formData,id,visitId) => {
  return {
      type: 'EDIT_MEDICAL',
      formData :formData,
      id:id,
      visitId:visitId
  }
};

export const editMedicalStart = () => {
  return {
      type: 'EDIT_MEDICAL_START'
  }
};

export const editMedicalSuccess = () => {
  return {
      type: 'EDIT_MEDICAL_SUCCESS',
  }
};

export const editMedicalFail = (error) => {
  return {
      type: 'EDIT_MEDICAL_FAIL',
      error: error
  }
};