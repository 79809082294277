import { takeEvery, call, put,delay } from "redux-saga/effects";
import { FETCH_FAMILYHISTORY_REQUEST,EDIT_FAMILYHISTORY,FETCH_FAMILYHISTORY_DATA_FOR_EDIT_REQUEST } from '../actions';
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherFamilyHistorySaga() {
  yield takeEvery(FETCH_FAMILYHISTORY_REQUEST, familyHistorySaga);
  yield takeEvery(FETCH_FAMILYHISTORY_DATA_FOR_EDIT_REQUEST, familyHistoryEditDataSaga);
  yield takeEvery(EDIT_FAMILYHISTORY, workerEditFamilyHistorySaga);

}

function* familyHistorySaga(action) {
  try {
    const response = yield call(doctorService.fetchFamilyHistory,action.patientId);
    yield put( actions.fetchFamilyHistorySuccess(response.data.data) );
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_patient_family_history_by_patient_id',
       method:'GET',
     })
    }
    yield put( actions.fetchFamilyHistoryFailure(error) );
  }
}

function* familyHistoryEditDataSaga(action) {
  try {
    const response = yield call(doctorService.fetchFamilyHistoryEditData,action.patientId);
    yield put( actions.fetchFamilyHistoryDataForEditSuccess(response.data.data) );
  } catch (error) {
    yield put( actions.fetchFamilyHistoryDataForEditFailure(error) );
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_patient_family_history_by_patient_id?is_editable=1',
       method:'GET',
     })
    }
  }
}


function* workerEditFamilyHistorySaga(action) {
  yield put(actions.editFamilyHistoryStart());

  try {
    const response = yield call(doctorService.editFamilyHistory, action.formData,action.id);
    var updateFamilyHistory = yield response.json();
    if(errorResponsesArray.includes(parseInt(response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:response.statusText,
       errorCode : response.status,
       endPoint:'save_patient_family_histories',
       method:'PUT',
     })
    }
    if (response.status === 200) {
      yield put(actions.editFamilyHistorySuccess());
      // alert(updateFamilyHistory.message)
      const res = yield call(doctorService.fetchFamilyHistory,action.id);
      yield put( actions.fetchFamilyHistorySuccess(res.data.data) );
    }else{
      alert(updateFamilyHistory.error)
    }

  } catch (error) {
    console.log(error);
    yield put(actions.editFamilyHistoryFail(error));
  }
}


