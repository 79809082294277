export const FETCH_EXERCISE_TEST_REQUEST = 'FETCH_EXERCISE_TEST_REQUEST';
export const FETCH_EXERCISE_TEST_SUCCESS = 'FETCH_EXERCISE_TEST_SUCCESS';
export const FETCH_EXERCISE_TEST_FAILURE = 'FETCH_EXERCISE_TEST_FAILURE';

export const FETCH_EXERCISE_TEST_BY_NAME_REQUEST = 'FETCH_EXERCISE_TEST_BY_NAME_REQUEST';
export const FETCH_EXERCISE_TEST_BY_NAME_SUCCESS = 'FETCH_EXERCISE_TEST_BY_NAME_SUCCESS';
export const FETCH_EXERCISE_TEST_BY_NAME_FAILURE = 'FETCH_EXERCISE_TEST_BY_NAME_FAILURE';


export const fetchExerciseRequest = (tests) => {
  console.log(tests)
  return {
      type: FETCH_EXERCISE_TEST_REQUEST,
      tests:tests,
  }
}

export const fetchExerciseSuccess = (tests) => {
  return {
      type: FETCH_EXERCISE_TEST_SUCCESS,
      payload: tests,
  }
}

export const fetchExerciseFailure = error => {
  return {
      type: FETCH_EXERCISE_TEST_FAILURE,
      payload: error
  }
}

export const fetchExerciseByNameRequest = (testName) => {
  console.log(testName)
  return {
      type: FETCH_EXERCISE_TEST_BY_NAME_REQUEST,
      testName:testName,
  }
}

export const fetchExerciseByNameSuccess = (testName) => {
  return {
      type: FETCH_EXERCISE_TEST_BY_NAME_SUCCESS,
      payload: testName,
  }
}

export const fetchExerciseByNameFailure = error => {
  return {
      type: FETCH_EXERCISE_TEST_BY_NAME_FAILURE,
      payload: error
  }
}
