export const FLOWBOARD_STATUS_CHANGE_REQUEST = 'FLOWBOARD_STATUS_CHANGE_REQUEST';
export const FLOWBOARD_STATUS_CHANGE_SUCCESS = 'FLOWBOARD_STATUS_CHANGE_SUCCESS';
export const FLOWBOARD_STATUS_CHANGE_FAILURE = 'FLOWBOARD_STATUS_CHANGE_FAILURE';

export const FETCH_FLOWBOARD_STATUS_REQUEST = 'FETCH_FLOWBOARD_STATUS_REQUEST';
export const FETCH_FLOWBOARD_STATUS_SUCCESS = 'FETCH_FLOWBOARD_STATUS_SUCCESS';
export const FETCH_FLOWBOARD_STATUS_FAILURE = 'FETCH_FLOWBOARD_STATUS_FAILURE';


export const REVIEW_PATIENT_REQUEST = 'REVIEW_PATIENT_REQUEST';
export const REVIEW_PATIENT_SUCCESS = 'REVIEW_PATIENT_SUCCESS';
export const REVIEW_PATIENT_FAILURE = 'REVIEW_PATIENT_FAILURE';


export const RESET_FLOWBOARD_STATUS = 'RESET_FLOWBOARD_STATUS';

export const resetFlowboardStatus = () => {
  return {
      type: RESET_FLOWBOARD_STATUS,
  }
}


export const reviewRequestRequest = (patientId,visitId) => {
  return {
      type: REVIEW_PATIENT_REQUEST,
      visitId:visitId,
      patientId:patientId
  }
}

export const reviewRequestSuccess = status => {
  return {
      type: REVIEW_PATIENT_SUCCESS,
      payload: status
  }
}



export const reviewRequestFailure = error => {
  return {
      type: REVIEW_PATIENT_FAILURE,
      payload: error
  }
}



export const fetchFlowBoardRequest = (visitId) => {
  return {
      type: FETCH_FLOWBOARD_STATUS_REQUEST,
      visitId:visitId
  }
}

export const fetchFlowBoardSuccess = status => {
  return {
      type: FETCH_FLOWBOARD_STATUS_SUCCESS,
      payload: status
  }
}



export const fetchFlowBoardFailure = error => {
  return {
      type: FETCH_FLOWBOARD_STATUS_FAILURE,
      payload: error
  }
}




export const flowBoardStatusChangeRequest = (flowboard) => {
  return {
      type: FLOWBOARD_STATUS_CHANGE_REQUEST,
      flowboard:flowboard
  }
}

export const flowBoardStatusChangeSuccess = data => {
  return {
      type: FLOWBOARD_STATUS_CHANGE_SUCCESS,
      payload: data
  }
}

export const flowBoardStatusChangeFailure = error => {
  return {
      type: FLOWBOARD_STATUS_CHANGE_FAILURE,
      payload: error
  }
}
