import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import avatar from "../../../../../assets/img/chikitsamitra.png";
import CardAvatar from "../../../../../components/Card/CardAvatar";
import moment from "moment";
import rx from "../../../../../assets/img/rx.png";
import Vitals from "../../../../Prescription/PrescriptionToSend/component/VitalData";
import PathologyNotesData from "../../../../Prescription/PrescriptionToSend/component/PathologyNotesData";
import RadioNotesData from "../../../../Prescription/PrescriptionToSend/component/RadioNotesData";
import DietNotesData from "../../../../Prescription/PrescriptionToSend/component/DietNotesData";
import ExerciseNotesData from "../../../../Prescription/PrescriptionToSend/component/ExerciseNotesData";
import PrescriptionDataTable from "../../../../Prescription/PrescriptionToSend/component/PrescriptionData";
import DifferentialData from "../../../../Prescription/PrescriptionToSend/component/DifferentialData";
import {
  capitalizeFirstLetter,
  ageCalculator,
} from "../../../../../utils/helper";
import {
  fetchVitalRequest,
} from "../../../../../actions";
import { connect } from "react-redux";
import ReferredData from "../../../../Prescription/PrescriptionData/component/ReferredData";

const useStyles = makeStyles((theme) => ({
  centreName: {
    color: "rgb(40, 108, 140)",
    fontSize: 21,
    fontWeight: 600,
    marginTop: 5,
    marginLeft: 15,
  },
  centreAddress: {
    color: "#1b6d3b",
    fontSize: 14,
    fontWeight: 500,
    marginTop: 5,
    marginLeft: 22,
    wordWrap: "break-word",
  },
  DoctorHeading: {
    color: "black",
    fontSize: 15,
    fontWeight: 500,
    marginTop: 12,
    marginLeft: 20,
  },
  docQuali: {
    color: "black",
    fontSize: 14,
    fontWeight: 400,
    // marginTop: 12,
    marginLeft: 20,
  },
}));

const PrescriptionToSend = ({ prescriptionToSend,fetchVitalRequest,doctorDesignation}) => {
  const classes = useStyles();
  const [docQualification, setDocQualification] = useState("");

  useEffect(() => {
    let doctorQualification = "";
    for (
      let i = 0;
      i <
      prescriptionToSend[0].attributes.doctor
        .user_qualifications.length;
      i++
    ) {
      doctorQualification =
        doctorQualification +
        "," +
        prescriptionToSend[0].attributes.doctor
          .user_qualifications[i].qualification;
    }
    doctorQualification = doctorQualification.substring(1);
    setDocQualification(doctorQualification);
    fetchVitalRequest(prescriptionToSend[0].attributes.patient.id, prescriptionToSend[0].attributes.visit_id);
  }, [prescriptionToSend[0].attributes.visit_id]);

  return (
    <div>
      <React.Fragment>
        {prescriptionToSend[0] !== undefined ? (
          <div
          >
            <form>
              <Grid container>
                <Grid item xs={12} sm={3} md={3} container direction="column">
                  <Typography className={classes.centreName}>
                    {capitalizeFirstLetter(
                      prescriptionToSend[0].attributes.mso
                        .centre.centre_title
                    )}{" "}
                  </Typography>
                  <Typography className={classes.centreAddress}>
                    <span
                      style={{
                        color: "black",
                        fontWeight: 400,
                      }}
                    >
                      {
                        prescriptionToSend[0].attributes.mso
                          .centre.centre_address
                      }
                    </span>
                  </Typography>
                  <Typography
                    style={{
                      color: "black",
                      fontSize: 14,
                      fontWeight: 500,
                      marginTop: 5,
                      marginLeft: 21,
                    }}
                  >
                    Number :
                    <span
                      style={{
                        color: "black",
                        fontWeight: 400,
                        marginLeft: 6,
                      }}
                    >
                      {
                        prescriptionToSend[0].attributes.mso
                          .mobile
                      }
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2} md={2}></Grid>
                <Grid item xs={12} sm={3} md={3} container direction="column">
                  <div style={{ marginTop: 45 }}>
                    <div>
                      <Typography className={classes.DoctorHeading}>
                        Dr.{" "}
                        {
                          prescriptionToSend[0].attributes.doctor
                            .name
                        }
                      </Typography>
                      <Typography
                        style={{
                          color: "black",
                          fontSize: 15,
                          fontWeight: 400,
                          marginTop: 1,
                          marginLeft: 20,
                        }}
                      >
                        {
                          prescriptionToSend[0].attributes.doctor
                            .designation
                        }
                      </Typography>
                    </div>
                    <div>
                      <Typography className={classes.docQuali}>
                        ({docQualification})
                      </Typography>
                    </div>
                    <div>
                      <Typography className={classes.docQuali}>
                        Registration No:
                        <span style={{ color: "black", fontWeight: 400 }}>
                          {
                            prescriptionToSend[0].attributes.doctor
                              ?.medical_registration_no
                          }
                        </span>
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <CardAvatar>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        src={avatar}
                        alt="..."
                        style={{
                          width: 130,
                          marginLeft: -10,
                          marginTop: 3,
                        }}
                      />
                    </a>
                  </CardAvatar>
                </Grid>
                <Grid item xs={12} sm={12} md={12}></Grid>
                <Grid item xs={12} sm={1} md={1}>
                  <Typography
                    style={{
                      color: "black",
                      fontSize: 14,
                      fontWeight: 500,
                      marginTop: 9,
                      marginLeft: "11%",
                    }}
                  >
                    Name :
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <Typography
                    style={{
                      fontWeight: 400,
                      color: "black",
                      marginLeft: -8,
                      marginTop: 9,
                      borderBottom: "1px dotted #afafa8",
                    }}
                  >
                    {prescriptionToSend[0].attributes.patient.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={1} md={1}>
                  <Typography
                    style={{
                      color: "black",
                      fontSize: 14,
                      fontWeight: 500,
                      marginTop: 9,
                      marginLeft: 18,
                    }}
                  >
                    Age :
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={1} md={1}>
                  <Typography
                    style={{
                      fontWeight: 400,
                      color: "black",
                      // marginLeft: 11,
                      marginTop: 10,
                      borderBottom: "1px dotted #afafa8",
                    }}
                  >
                    {ageCalculator(
                      prescriptionToSend[0].attributes.patient.dob
                    )}{" "}
                    /
                    {prescriptionToSend[0].attributes.patient.gender[0].toUpperCase()}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={1} md={1}>
                  <Typography
                    style={{
                      color: "black",
                      fontSize: 14,
                      fontWeight: 500,
                      marginTop: 9,
                      marginLeft: 18,
                    }}
                  >
                    Date :
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <Typography
                    style={{
                      fontWeight: 400,
                      color: "black",
                      // marginRight: 22,
                      marginTop: 9,
                      borderBottom: "1px dotted #afafa8",
                    }}
                  >
                    {moment(prescriptionToSend[0].attributes.created_at).format(
                      "YYYY-MM-DD"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}></Grid>
                <br />
                <Grid item xs={12} sm={2} md={2} container direction="column">
                  <div style={{ marginTop: 1 }}>
                    <img
                      src={rx}
                      alt="logo"
                      style={{ width: 24, marginLeft: 12 }}
                    />
                    <br />
                    <div>
                      <PathologyNotesData
                        pathologyTest={prescriptionToSend[0]}
                      />
                    </div>
                    <br />
                    <div>
                      <RadioNotesData radiologyTest={prescriptionToSend[0]} />
                    </div>
                    <div>
                      <br />
                      <div
                        style={{
                          borderBottom: "1px solid #a0a092",
                          padding: 5,
                        }}
                      >
                        <Typography
                          style={{
                            color: "black",
                            fontWeight: 600,
                            fontSize: 15,
                          }}
                        >
                          Vitals
                        </Typography>
                      </div>
                      <Vitals
                        patientId={prescriptionToSend[0].attributes.patient.id}
                        visitId={prescriptionToSend[0].attributes.visit_id}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={9} md={9}>
                  <div style={{ marginLeft: 26 }}>
                    {doctorDesignation !== "Dietician" && (
                    <div style={{ width: "80%" }}>
                      <DifferentialData
                        differentialData={prescriptionToSend[0]}
                      />
                    </div>)}
                    <br />
                    {prescriptionToSend[0].attributes.is_referred !== 1 ? (
                      <>
                      {doctorDesignation !== "Dietician" ?
                      <div style={{ width: "80%" }}>
                        <Typography
                          style={{
                            color: "black",
                            fontWeight: 600,
                            marginLeft: 2,
                          }}
                        >
                          Medication
                        </Typography>
                        <PrescriptionDataTable
                          prescriptionData={prescriptionToSend[0]}
                        />
                      </div>:null}
                      </>
                    ) : (
                      <div style={{ width: "80%" }}>
                        <Typography
                          style={{
                            color: "black",
                            fontWeight: 500,
                            marginLeft: 2,
                          }}
                        >
                          Referred
                        </Typography>
                        <ReferredData
                          prescriptionSubmitData={prescriptionToSend}
                        />
                      </div>
                    )}
                    <br />
                    <div style={{ width: "80%" }}>
                      <Typography
                        style={{
                          color: "black",
                          fontWeight: 500,
                          fontSize: 14,
                        }}
                      >
                        Instruction :
                        <span
                          style={{
                            color: "#263238",
                            marginLeft: 6,
                            fontWeight: 400,
                            fontSize: 13,
                          }}
                        >
                          {prescriptionToSend[0].attributes.instructions}
                        </span>
                      </Typography>
                    </div>
                    {prescriptionToSend[0].attributes.exercise_note !== null ? (
                      <div style={{ width: "80%" }}>
                        <div style={{ display: "flex" }}>
                          <Typography
                            style={{
                              color: "black",
                              fontWeight: 500,
                              // marginLeft: -1,
                              marginTop: 15,
                              fontSize: 14,
                            }}
                          >
                            Exercise Notes :
                          </Typography>
                          <ExerciseNotesData
                            exerciseNotes={prescriptionToSend[0]}
                          />
                        </div>
                      </div>
                    ) : null}
                    {prescriptionToSend[0].attributes.diet_note !== null ? (
                      <div style={{ width: "80%" }}>
                        <div style={{ display: "flex" }}>
                          <Typography
                            style={{
                              color: "black",
                              fontWeight: 500,
                              // marginLeft: -1,
                              // marginTop:15
                              fontSize: 14,
                            }}
                          >
                            Diet Notes :
                          </Typography>
                          <DietNotesData dietNotes={prescriptionToSend[0]} />
                        </div>
                      </div>
                    ) : null}
                    {prescriptionToSend[0].attributes.is_referred !== 1 && (
                      <div>
                        <Typography
                          style={{
                            color: "black",
                            fontWeight: 500,
                            fontSize: 14,
                          }}
                        >
                          Follow-up in :
                          <span
                            style={{
                              color: "#263238",
                              marginLeft: 6,
                              fontWeight: 400,
                              fontSize: 13,
                            }}
                          >
                            {prescriptionToSend[0].attributes.follow_up}{" "}
                            {prescriptionToSend[0].attributes.follow_up_type}
                            <span style={{ marginLeft: 6 }}>for </span>
                            {prescriptionToSend[0].attributes.follow_up_for}
                          </span>
                        </Typography>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        ) : null}
      </React.Fragment>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    doctorDesignation : state.login.designation
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVitalRequest: (patientId, visitId) =>
      dispatch(fetchVitalRequest(patientId, visitId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionToSend);
