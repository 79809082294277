import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useTable from "../../../../components/UseTable/useTable";
import { TableBody, TableRow, TableCell, Grid } from "@material-ui/core";
// import CardBody from '../../../components/Card/CardBody'
// import Card from '../../../components/Card/Card'

const styles = (theme) => ({});
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      // backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles(styles);

const DifferentialData = (props) => {
  const classes = useStyles();
  const { differentialData } = props;
  const [headCells, setHeadCells] = useState([]);

  useEffect(() => {
    if (differentialData !== undefined) {
      setHeadCells([
        {
          id: `${differentialData.attributes.diagnosis_note.diagnosis_type}`,
          label: `${differentialData.attributes.diagnosis_note.diagnosis_type}`,
        },
      ]);
    }
  }, [differentialData]);

  const { TblContainer, TblHead } = useTable(
    differentialData,
    headCells !== undefined ? headCells : ""
  );

  return (
    <div>
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
          {/* <Card style={{ marginTop:1 }}> */}
          {/* <CardBody> */}
          {differentialData !== undefined ? (
            <div>
              <TblContainer
                style={{
                  borderBottom: "1px solid #d0c2c2",
                  borderCollapse: "unset",
                }}
              >
                <TblHead style={{ backgroundColor: "white" }} />
                <TableBody>
                  {differentialData.attributes.diagnosis_note.hasOwnProperty(
                    "icd_data"
                  ) ? (
                    <React.Fragment>
                      {differentialData.attributes.diagnosis_note.icd_data.map(
                        (item) => (
                          <StyledTableRow key={item.id}>
                            {/* <TableCell>{item.code}</TableCell> */}
                            <TableCell>{item.long_desc}</TableCell>
                          </StyledTableRow>
                        )
                      )}
                    </React.Fragment>
                  ) : null}
                  {differentialData.attributes.diagnosis_note.hasOwnProperty(
                    "diagnoses"
                  ) ? (
                    <React.Fragment>
                      {differentialData.attributes.diagnosis_note.diagnoses.map(
                        (item) => (
                          <StyledTableRow key={item}>
                            {/* <TableCell>N/A</TableCell> */}
                            <TableCell>{item}</TableCell>
                          </StyledTableRow>
                        )
                      )}
                    </React.Fragment>
                  ) : null}
                </TableBody>
              </TblContainer>
            </div>
          ) : null}
          {/* </CardBody> */}
          {/* </Card> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default DifferentialData;
