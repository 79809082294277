import { takeEvery, call, put, delay } from "redux-saga/effects";
import {
  SEARCH_DRUG_IN_FORMULARY,
  ADD_PRESCRIPTION,
  POST_PRESCRIPTION,
  FETCH_PRESCRIPTION_BY_ID,
  SEND_TO_MSO_PRESCRIPTION,
  UPDATE_PRESCRIPTION,
  POST_FEEDBACK,
  LAST_VISIT_PRESCRIPTION,
  FETCH_MSO_DETAILS
} from "../actions";
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherSearchDrugInFormularySaga() {
  yield takeEvery(SEARCH_DRUG_IN_FORMULARY, workerSearchDrugInFormularySaga);
  yield takeEvery(ADD_PRESCRIPTION, workerPrescriptionDataSaga);
  yield takeEvery(POST_PRESCRIPTION, workerPostPrescriptionDataSaga);
  yield takeEvery(FETCH_PRESCRIPTION_BY_ID, workerFetchPrescriptionDataSaga);
  yield takeEvery(SEND_TO_MSO_PRESCRIPTION, workerSendToMsoSaga);
  yield takeEvery(UPDATE_PRESCRIPTION, workerUpdatePrescriptionDataSaga);
  yield takeEvery(POST_FEEDBACK, workerPostFeedbackSaga);
  yield takeEvery(LAST_VISIT_PRESCRIPTION, workerFetchLastVisitPrescriptionDataSaga);
  yield takeEvery(FETCH_MSO_DETAILS, workerMsoDetailSaga);

}

function* workerUpdatePrescriptionDataSaga(action) {
  yield put(actions.isUpdatePrescription(true))
  yield put(actions.updatePrescriptionStart());
  try {
    const res = yield call(
      doctorService.updatePrescription,
      action.formData,
      action.id
    );
    var updatePrescription = yield res.json();
    if(errorResponsesArray.includes(parseInt(res.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:res.statusText,
       errorCode : res.status,
       endPoint:'prescriptions',
       method:'PUT',
     })
    }
    if (res.status === 200) {
      yield put(actions.updatePrescriptionSuccess(true));
      yield put(actions.fetchFlowBoardRequest(action.visitId));
      yield put(actions.fetchSuggestionTitleEmptySuccess(null));
      // alert(updatePrescription.message);
      yield put(actions.isUpdatePrescription(false))
         
    } else {
      alert(updatePrescription.error);
    }
  } catch (error) {
    yield put(actions.updatePrescriptionFail(error));
  }
}

function* workerFetchPrescriptionDataSaga(action) {
  try {
    const res = yield call(
      doctorService.fetchPrescriptionById,
      action.patientId,
      action.visitId
    );
    let prescriptionById = [];
    prescriptionById.push(res.data.data);
    yield put(actions.fetchPrescriptionByIdSuccess(prescriptionById));
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_prescription_by_visit_id',
       method:'GET',
     })
    }
    yield put(actions.fetchPrescriptionByIdFail(error));
  }
}

function* workerFetchLastVisitPrescriptionDataSaga(action) {
  try {
    const res = yield call(
      doctorService.fetchPrescriptionById,
      action.patientId,
      action.visitId,
      true
    );
    // console.log(res)
    let prescriptionById = [];
    prescriptionById.push(res.data.data);
    yield put(actions.fetchLastVisitPrescriptionSuccess(prescriptionById,''));

  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_prescription_by_visit_id',
       method:'GET',
     })
    }
    yield put(actions.fetchLastVisitPrescriptionFail(error));
  }
}

function* workerSendToMsoSaga(action) {
  yield put(actions.sendToMsoPrescriptionStart());
  try {
    const response = yield call(
      doctorService.sendToMso,
      action.prescription,
      action.pId
    );
    alert(response.data.message);
    yield put(actions.sendToMsoPrescriptionSuccess(response.data.message, 1));
    yield put(actions.isShowPrescriptionToSendSuccess(false));
    yield delay(1000);
    yield put(actions.fetchFlowBoardRequest(action.prescription.visit_id));
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'send_prescription_to_mso',
       method:'PUT',
     })
    }
    yield put(actions.sendToMsoPrescriptionFail(error.response.data.error));
    alert(error.response.data.error);
  }
}

function* workerSearchDrugInFormularySaga(action) {
  yield put(actions.searchDrugInFormularyStart());
  try {
    const res = yield call(doctorService.searchFormulary,action.drug)
    yield put(actions.searchDrugInFormularySuccess(res.data.data));
    if(res.data.data.length === 0){
      const tempDrug = yield call(doctorService.searchPendingTempDrug,action.drug.term) 
      let tempDrugArray = []
      for(let i = 0 ; i<tempDrug.data.data.length;i++){
        tempDrugArray.push({
          id:tempDrug.data.data[i].attributes.id,
          drug_name:tempDrug.data.data[i].attributes.drug_name,
          manufacturer:{legalName:tempDrug.data.data[i].attributes.manufacturer},
          is_temp:1,
          strength:tempDrug.data.data[i].attributes.strength
        })
      }
      yield put(actions.searchDrugInFormularySuccess(tempDrugArray)); 
    }
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_drug_data',
       method:'GET',
     })
    }
    yield put(actions.searchDrugInFormularyFail(error));
  }
}

function* workerPrescriptionDataSaga(action) {
  yield put(actions.addPrescriptionStart());
  try {
    yield put(actions.addPrescriptionSuccess(action.drug));
  } catch (error) {
    yield put(actions.addPrescriptionFail(error));
  }
}

function* workerMsoDetailSaga(action) {
  try {
    const res = yield call(doctorService.fetchMsoInformation,action.msoId);
    localStorage.setItem('msoId',res.data.data.attributes.id)
    yield put(actions.fetchMsoDetailSuccess(res.data.data.attributes.current_availability_status?.value));
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'users',
       method:'GET',
     })
    }
    yield put(actions.fetchMsoDetailFail(error));
  }
}

function* workerPostPrescriptionDataSaga(action) {
  yield put(actions.postPrescriptionStart());
  try {
    const res = yield call(doctorService.Prescription, action.formData);
    var prescription = yield res.json();
    if(errorResponsesArray.includes(parseInt(res.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:res.statusText,
       errorCode : res.status,
       endPoint:'prescriptions',
       method:'POST',
     })
    }
    if (res.status === 201) {
      yield put(actions.postPrescriptionSuccess(true));
      yield put(actions.fetchSuggestionTitleEmptySuccess(null));
      yield delay(1000);
      yield put(actions.isShowFeedbackModalSuccess(true));
    } else {
      alert(prescription.error);
    }
  } catch (error) {
    yield put(actions.postPrescriptionFail(error));
    // alert(error.response.data.error)
  }
}

function* workerPostFeedbackSaga(action) {
  yield put(actions.postFeedbackStart());
  try {
    const res = yield call(doctorService.feedbackAdd, action.formData);
    yield put(actions.postFeedbackSuccess(false, false));
    // yield put(actions.isShowFeedbackModalSuccess(true));
    // alert(res.data.message);
  } catch (error) {
    yield put(actions.postFeedbackFail(error));
    alert(error.response.data.error);
  }
}
