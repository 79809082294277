import React, { useEffect } from "react";
import { Dialog, Typography, Grid, Link } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/styles";
import Button from "../CustomButtons/Button";
import { getRequest } from "../../api/Api";
import { Link as RouterLink } from "react-router-dom";
import { logoutSuccess } from "../../actions";
import { connect } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({}));

const ContinueConsulationAlert = ({
  open,
  onClose,
  countDownTime,
  setCountDownTimerOn,
  logoutSuccess,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (countDownTime === 0) localStorage.clear();
  }, [countDownTime]);

  const continueConsulationHandler = (value) => {
    const token = "Bearer " + localStorage.getItem("jwt");
    const doctorConslutationId = localStorage.getItem("consultationId");
    const endPoint = `confirm_appointment_cancellation?consultation_id=${doctorConslutationId}&is_consult_appointments=${value}`;
    getRequest(endPoint, token).then((data) => {
      if (data.error) {
        alert(data.error);
        onClose(false);
        localStorage.removeItem("consultationId");
        setCountDownTimerOn(false);
        onClose(localStorage.setItem('consultationContinueValue',false));
      } else {
        alert(data.message);
        localStorage.removeItem("consultationId");
        setCountDownTimerOn(false);
        onClose(localStorage.setItem('consultationContinueValue',false));
      }
    });
  };

  return (
    <Dialog TransitionComponent={Transition} open={open}>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          md={12}
          sm={12}
          justify="center"
          direction="column"
          alignItems="center"
        >
          {countDownTime === 0 ? (
            <>
              <Typography
                align="center"
                gutterBottom
                variant="h5"
                style={{
                  marginTop: "4%",
                  fontSize: "25px",
                  widht: "90%",
                  color: "red",
                }}
              >
                Oops! You ran out of time
              </Typography>
              <br />
              <Link
                component={RouterLink}
                style={{ fontSize: 20, textDecoration: "underline" }}
                onClick={() => logoutSuccess("", 1)}
                to="/auth/login"
              >
                Please login to continue
              </Link>
            </>
          ) : (
            <>
              <Typography
                align="center"
                gutterBottom
                variant="h5"
                style={{ marginTop: "4%", fontSize: "21px" }}
              >
                Do you wish to consult the patients whose appointments are
                booked?
              </Typography>
              <Typography
                align="center"
                gutterBottom
                variant="h5"
                style={{
                  marginTop: "4%",
                  fontSize: "21px",
                  color: countDownTime < 11 ? "red" : "blue",
                }}
              >
                {countDownTime} seconds left to give confirmation
              </Typography>
            </>
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={12}
          sm={12}
          justify="center"
          alignItems="center"
        >
          {countDownTime !== 0 && (
            <>
              <Button
                style={{ backgroundColor: "rgb(50, 123, 175)", color: "white" }}
                onClick={() => continueConsulationHandler(1)}
              >
                Yes
              </Button>
              <Button
                style={{
                  backgroundColor: "#b3b2b0",
                  color: "black",
                  marginLeft: "2%",
                }}
                onClick={() => continueConsulationHandler(0)}
              >
                No
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutSuccess: (msg, val) => dispatch(logoutSuccess(msg, val)),
  };
};

export default connect(null, mapDispatchToProps)(ContinueConsulationAlert);
