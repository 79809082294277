export const FETCH_CURRENTMEDICATION_REQUEST = 'FETCH_CURRENTMEDICATION_REQUEST';
export const FETCH_CURRENTMEDICATION_SUCCESS = 'FETCH_CURRENTMEDICATION_SUCCESS';
export const FETCH_CURRENTMEDICATION_FAILURE = 'FETCH_CURRENTMEDICATION_FAILURE';


export const fetchCurrentMedicationRequest = (patientId,visitId) => {
  return {
      type: FETCH_CURRENTMEDICATION_REQUEST,
      patientId:patientId,
      visitId:visitId
  }
}

export const fetchCurrentMedicationSuccess = medicine => {
  return {
      type: FETCH_CURRENTMEDICATION_SUCCESS,
      payload: medicine
  }
}

export const fetchCurrentMedicationFailure = error => {
  return {
      type: FETCH_CURRENTMEDICATION_FAILURE,
      payload: error
  }
}
