import { createStore, applyMiddleware } from "redux";
import rootReducer from '../reducers/rootReducer'
import logger from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/rootSaga";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer,composeWithDevTools(applyMiddleware(logger,sagaMiddleware)))


sagaMiddleware.run(rootSaga);

export default store;
