import { takeEvery, call, put,delay } from "redux-saga/effects";
import { FETCH_CURRENTMEDICATION_REQUEST } from '../actions';
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherCurrentSaga() {
  yield takeEvery(FETCH_CURRENTMEDICATION_REQUEST, currentMedicationSaga);
}

function* currentMedicationSaga(action) {
  try {
    yield delay(2000)
    const response = yield call(doctorService.fetchCurrentMedication,action.patientId,action.visitId);
    yield put( actions.fetchCurrentMedicationSuccess(response.data.data) );
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_patient_current_medications_by_visit_id',
       method:'GET',
     })
    }
    yield put( actions.fetchCurrentMedicationFailure(error) );
  }
}


