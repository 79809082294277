import { takeEvery, call, put,delay } from "redux-saga/effects";
import { FETCH_ALLINTERACTION_REQUEST,STORE_ALLERGY } from '../actions';
import doctorService from "../services/doctorService";
import * as actions from "../actions";


export function* watcherAllInteractionSaga() {
  yield takeEvery(FETCH_ALLINTERACTION_REQUEST, interactionSaga);
  yield takeEvery(STORE_ALLERGY, storeAllergySaga);

}

function* interactionSaga(action) {
  try {
    // yield delay(2000)
    const response = yield call(doctorService.allInteraction,action.formData);
    // console.log(response.text())
    // var htmlResponse = yield response.text()
    // console.log(htmlResponse)
    yield put( actions.fetchAllInteractionSuccess(response.data.data,response.data.data_length));
  } catch (error) {
    yield put( actions.fetchAllInteractionFailure(error) );
  }
}

function* storeAllergySaga(action) {
  try {
    yield put(actions.storeAllergyStart())
    yield put( actions.storeAllergySuccess(action.molecule,action.substance) );
  } catch (error) {
    yield put( actions.storeAllergyFail(error) );
  }
}


