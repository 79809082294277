import { takeEvery, call, put,delay } from "redux-saga/effects";
import { FETCH_LIFESTYLE_REQUEST,EDIT_LIFESTYLE } from '../actions';
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherLifestyleSaga() {
  yield takeEvery(FETCH_LIFESTYLE_REQUEST, lifestyleSaga);
  yield takeEvery(EDIT_LIFESTYLE, workerEditLifeStyleSaga);

}

function* lifestyleSaga(action) {
  try {
    const response = yield call(doctorService.fetchLifestyle,action.patientId);
    yield put( actions.fetchLifestyleSuccess(response.data.data) );
  } catch (error) {
    yield put( actions.fetchLifestyleFailure(error) );
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_patient_lifestyles_by_patient_id',
       method:'GET',
     })
    }
  }
}

function* workerEditLifeStyleSaga(action) {
  yield put(actions.editLifestyleStart());

  try {
    const response = yield call(doctorService.editLifeStyle, action.formData,action.id);
    var updateLifestyle = yield response.json();
    if(errorResponsesArray.includes(parseInt(response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:response.statusText,
       errorCode : response.status,
       endPoint:'save_patient_lifestyles',
       method:'PUT',
     })
    }
    if (response.status === 200) {
      yield put(actions.editLifestyleSuccess());
    // alert(updateLifestyle.message)
    const res = yield call(doctorService.fetchLifestyle,action.id);
    yield put( actions.fetchLifestyleSuccess(res.data.data) );
    }else{
      alert(updateLifestyle.error)
    }

  } catch (error) {
    console.log(error);
    yield put(actions.editLifestyleFail(error));
  }
}




