import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import Card from "../../../../components/Card/Card";
import CardBody from "components/Card/CardBody";

const ReferredData = (props) => {
  const { prescriptionSubmitData } = props;

  return (
    <div>
      <Card style={{ marginTop: 1, marginBottom: 0, boxShadow: "none" }}>
        {/* {prescriptionSubmitData[0] && ( */}
        <CardBody>
          <Grid container>
            <Grid item xs={12} sm={6} md={6} container direction="column">
              <Typography>
                {prescriptionSubmitData[0].attributes.referred_specialty
                  ? "Speciality :"
                  : "Tertiary care-center :"}
              </Typography>
              <Typography style={{ fontWeight: 600, lineHeight: 3 }}>
                {prescriptionSubmitData[0].attributes.referred_specialty
                  ? prescriptionSubmitData[0].attributes.referred_specialty
                  : prescriptionSubmitData[0].attributes.referred_to}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} container direction="column">
              <Typography>Referral Note :</Typography>
              <Typography style={{ fontSize: 14,wordBreak:'break-word' }}>
                {prescriptionSubmitData[0].attributes.referral_note}
              </Typography>
            </Grid>
          </Grid>
        </CardBody>
        {/* )} */}
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferredData);
