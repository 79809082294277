export const FETCH_DIET_TEST_REQUEST = 'FETCH_DIET_TEST_REQUEST';
export const FETCH_DIET_TEST_SUCCESS = 'FETCH_DIET_TEST_SUCCESS';
export const FETCH_DIET_TEST_FAILURE = 'FETCH_DIET_TEST_FAILURE';

export const FETCH_DIET_TEST_BY_NAME = 'FETCH_DIET_TEST_BY_NAME';
export const FETCH_DIET_TEST_BY_NAME_START = 'FETCH_DIET_TEST_BY_NAME_START';
export const FETCH_DIET_TEST_BY_NAME_SUCCESS = 'FETCH_DIET_TEST_BY_NAME_SUCCESS';
export const FETCH_DIET_TEST_BY_NAME_FAILURE = 'FETCH_DIET_TEST_BY_NAME_FAILURE';


export const fetchDietTestRequest = (tests) => {
  console.log(tests)
  return {
      type: FETCH_DIET_TEST_REQUEST,
      tests:tests,
  }
}

export const fetchDietTestSuccess = (tests) => {
  return {
      type: FETCH_DIET_TEST_SUCCESS,
      payload: tests,
  }
}

export const fetchDietTestFailure = error => {
  return {
      type: FETCH_DIET_TEST_FAILURE,
      payload: error
  }
}

export const fetchDietTestByName = (testName) => {
  console.log(testName)
  return {
      type: FETCH_DIET_TEST_BY_NAME,
      testName:testName,
  }
}
export const fetchDietTestByNameStart = () => {
  return {
      type: 'FETCH_DIET_TEST_BY_NAME_START'
  }
};

export const fetchDietTestByNameSuccess = (testName) => {
  return {
      type: FETCH_DIET_TEST_BY_NAME_SUCCESS,
      payload: testName,
  }
}

export const fetchDietTestByNameFailure = error => {
  return {
      type: FETCH_DIET_TEST_BY_NAME_FAILURE,
      payload: error
  }
}
