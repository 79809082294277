import React, { Fragment, useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography, Button } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import useRouter from "utils/useRouter";
import AccountIcon from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Notifications from "@material-ui/icons/Notifications";
import classNames from "classnames";
import { Redirect } from "react-router";
import { getRequest } from '../../../../api/Api'
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import Switch from "../../../../components/Switch/Switch";
import SettingModal from "../../../../views/Setting/Setting";
import EndConsultation from "../../../../views/Consultation/EndConsultationModal";
import moment from "moment";
import EndconsultationAlertBox from "../../../../components/EndconsultationAlert/endconsultationAlert";
import { logout, 
  startConsultation,
  endConsultation,
  isCloseExtendTimeMOdal,
  fetchMsoDetail,
  isStartConsultationOnSlotCheck,
  fetchPatientCounter,
  msoUnavailable,
  msoAvailable
 } from "../../../../actions";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ContinueConsulationAlert from '../../../../components/ContinueConsiltationAlert/ContinueConsulationAlert'
import { checkEndTime } from '../../../../utils/helper'
import NotificationSound from "./NotificationSound";
import ActionCable from "actioncable";
import UnassignAppointmentAlert from "components/UnassignAppointmentAlert/UnassignAppointmentAlert";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  notifications: {
    zIndex: "4",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: "2px",
      border: "1px solid white",
      right: "4px",
      fontSize: "9px",
      background: "red",
      color: "white",
      minWidth: "16px",
      height: "16px",
      borderRadius: "10px",
      textAlign: "center",
      lineHeight: "16px",
      verticalAlign: "middle",
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      marginRight: "8px",
    },
  },
  manager: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    display: "inline-block",
    position: "absolute",
    left: "80%",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// const drawerWidth = 240;
const NavBar = (props) => {
  const {
    openMobile,
    logout,
    logOut,
    onMobileClose,
    startConsultation,
    checkConsultationMode,
    extendTimeCounter,
    isShowAlertToDoctor,
    endConsultation,
    flowboardStatus,
    isCloseExtendTimeMOdal,
    isStartConsultationOnSlotCheck,
    isStartConsultationOnSlot,
    fetchMsoDetail,
    patientId,
    fetchPatientCounter,
    isConsultationStart,
    msoUnavailable,
    msoAvailable
  } = props;
  const classes = useStyles();
  const router = useRouter();
  const [value, setValue] = useState(false);
  const [threshold, setThreshHold] = useState();
  const [open, setOpen] = React.useState(false);
  const [openNotification, setOpenNotification] = useState(null);
  const [settingModalOpen, setSettingModalOpen] = useState(false);
  const [endConsultationModalOpen, setendConsultationModalOpen] = useState(
    false
  );
  const [endConsultationAlertOpen, setendConsultationAlertOpen] = useState(
    false
  );
  const [logOffType,setLogOffType] = useState('')
  const [count, setCount] = useState(0);
  const [message, setMessage] = useState("");
  const [openSnack, setOpenSnack] = useState(true);
  const [severity, setSeverity] = useState("info");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opens = Boolean(anchorEl);
  const [isShowUnassignApoointmentAlert,setIsShowUnassignApoointmentAlert] = useState(false)
  const [currentVisitId,setCurrentVisitId] = useState(null)
  const [openSnackOfDoctorSlot, setOpenSnackOfDoctorSlot] = useState(false);
  const [severityOfDoctorSlot, setSeverityOfDoctorSlot] = useState("info");
  const [doctorSlotMsg,setDoctorSlotMsg] = useState('')
  const [isShowConsultationContinueAlert,setIsShowConsultationContinueAlert] = useState(JSON.parse(localStorage.getItem('consultationContinueValue')))
  const [countDownTime, setCountDownTime] = useState('');
  const [countDownTimerOn, setCountDownTimerOn] = useState(false);

  const location = useLocation();
  useEffect(()=>{
  if(checkConsultationMode){
  setThreshHold('')
  }
  },[checkConsultationMode])

  useEffect(()=>{
    if(isStartConsultationOnSlot){
      handleConsultation()
      isStartConsultationOnSlotCheck(false)
    }   
  },[isStartConsultationOnSlot])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  useEffect(()=>{
    if(localStorage.getItem('consultationContinueValue') === 'true'){
      setCountDownTimerOn(true)
      const newDate = new Date(localStorage.getItem('consultationContinuetime'))
      setCountDownTime(moment(newDate).diff(moment(),'seconds'))
    }else if(localStorage.getItem('consultationContinueValue') === 'false'){
      setCountDownTimerOn(false)
      setCountDownTime('')
    }
  },[])

  useEffect(() => {
    setThreshHold(localStorage.getItem("threshold_time"));
  }, [count]);

  useEffect(() => {
    if(isShowAlertToDoctor){
      setDoctorSlotMsg(`Your consultation will end at ${localStorage.getItem('end_time')} as you don't have slots for today`)
      setOpenSnackOfDoctorSlot(true)
    }
  }, [isShowAlertToDoctor]);

  useEffect(() => {
    let threshholdTime = parseInt(localStorage.getItem("threshold_time"));
      let isConsulattionStart = localStorage.getItem("isConsultationStarted")
      let alertTime = null;
      if(threshholdTime && isConsulattionStart === 'true'){
      let endTime = localStorage.getItem("end_time");
      let PresentTime = moment().format("HH:mm");
      let PresentTimeMss =
        Number(PresentTime.split(":")[0]) * 60 * 60 * 1000 +
        Number(PresentTime.split(":")[1]) * 60 * 1000;
      let endTimeInMss =
        Number(endTime.split(":")[0]) * 60 * 60 * 1000 +
        Number(endTime.split(":")[1]) * 60 * 1000;
      let threshholdTimeInMss = threshholdTime * 60 * 1000;
        alertTime = endTimeInMss - (PresentTimeMss + threshholdTimeInMss);
      console.log(alertTime)
      if(alertTime === 0 && localStorage.getItem("isConsultationStarted") === 'true'){
        isCloseExtendTimeMOdal(false)
        handleEndConsultationAlertOpen();
      }
      setTimeout(() => {
        if(localStorage.getItem("isConsultationStarted") === 'true'){
        isCloseExtendTimeMOdal(false)
        handleEndConsultationAlertOpen();
        }
      }, alertTime);
    }
  }, [extendTimeCounter])

  useEffect(() => {
    let interval = null;
    if (!countDownTime) {
      return;
    }
    if (countDownTimerOn) {
      interval = setInterval(() => {
        setCountDownTime(countDownTime - 1);
      }, 1000);
    } else if (!countDownTimerOn) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [countDownTimerOn, countDownTime]);

  const handleSettingOpen = () => {
    setSettingModalOpen(true);
    setAnchorEl(null);
  };

  const handleSettingClose = () => {
    setSettingModalOpen(false);
  };

  const handleLogout = () => {

    let scheduledEndTime = localStorage.getItem("end_time"); 
    let isEndTimeMet = checkEndTime(scheduledEndTime,logOffType)
    if(isEndTimeMet){
      handleConsultationOpen('pre_logout')
    }else{
      logout();
    }
    setAnchorEl(null);
  };

  const handleConsultationOpen = (type) => {
    setLogOffType(type)
    setendConsultationModalOpen(true);
  };

  const handleConsultationClose = () => {
    setendConsultationModalOpen(false);
  };

  const handleEndConsultationAlertOpen = () => {
    setendConsultationAlertOpen(true);
  };

  const handleEndConsultationAlertClose = () => {
    setendConsultationAlertOpen(false);
  };


  const fetchTodayStartSlot = () => {
    let jwt = localStorage.getItem("jwt")
  const endpoint = `get_doctor_slots?id=${localStorage.getItem("userId")}&day_of_week=${moment().weekday()}`;
  const token = "Bearer " + jwt;
  let param = [];
  getRequest(endpoint, token, param).then((data) => {
  if (data.error) {
    console.log(data.error);
  } else {
    console.log(data.data)
    if(data.data.length !== 0){
      localStorage.setItem("firstSlotOfDoctor",data.data[0].attributes.slot_from)
      checkEarlyLoginCase('slots available')
    }else{
      localStorage.setItem("firstSlotOfDoctor",0)
      checkEarlyLoginCase('No slots')
    }
  }
});
  }

  const checkEarlyLoginCase = (data)=>{
    let d1 = new Date();
    let firstSlot = localStorage.getItem('firstSlotOfDoctor')
    let desiredDate = moment().format("YYYY-MM-DD");
    desiredDate += " " + firstSlot;
    if (new Date(d1).getTime() >= new Date(desiredDate).getTime()) {
      startConsultation(data);
      setTimeout(() => {
        setThreshHold(localStorage.getItem("threshold_time"));
      }, 1000);
    } else {
      if (
        window.confirm(
          "This will make you available for consultation. Are you sure?"
        )
      ) {
        startConsultation(data);
        setTimeout(() => {
          setThreshHold(localStorage.getItem("threshold_time"));
        }, 1000);
      }
    }
  }
  let token = localStorage.getItem("jwt");
  let cable = ActionCable.createConsumer(
    `${process.env.REACT_APP_SOCKET_URL}${token}`
  );
  useEffect(() => {
    cable.subscriptions.create("NotificationChannel", {
      initialized: () => console.log("Connecting....."),
      connected: () => console.log("Way to go! You did it!"),
      received: (message) => {
        handleReceived(message);
      },
      disconnected: () => console.log("We are no longer connected! 😢"),
    });
    return function cleanup() {
      cable.disconnect();
    };
  });

  const handleConsultation = () => {
    setValue(!value);
    setCount(count + 1);
    if (!threshold) {
      fetchTodayStartSlot()
    } else {
      let scheduledEndTime = localStorage.getItem("end_time"); 
      let isEndTimeMet = checkEndTime(scheduledEndTime,logOffType)
      if(isEndTimeMet){
        handleConsultationOpen('pre_offline');
      }else{
        let endTime = moment().format("YYYY-MM-DD HH:mm:ss");
        const newEndTime = new Date(endTime).toISOString();
        endConsultation(newEndTime, false);
      }    
    }
  };
  const handleReceived = (message) => {
    console.log(message);
    console.log("Recieved");
    try {
      if (message) {
        if(message.notification_type === "alert" && message.hasOwnProperty('call_started') && message.call_started === 1){
           localStorage.setItem("isCallStarted","1")
        }
        if(message.hasOwnProperty('mso_available') && patientId.length !== 0){
          fetchMsoDetail(patientId.attributes.created_by?.id)
          if(message.doctor_category_id){
            setIsShowUnassignApoointmentAlert(true)
            setCurrentVisitId(message.visit_id)
          }
        }
        if(message.notification_type === "alert" && message.hasOwnProperty('mso_available') &&  message.mso_available === 0){
          localStorage.setItem("isVideoCallDisconnected", "0");
        }
        if(message.hasOwnProperty('mso_available') && message.mso_available === 1 && parseInt(localStorage.getItem('msoId')) === message.mso_id){
          msoUnavailable()
          setTimeout(()=>{
            msoAvailable()
            localStorage.setItem("isVideoCallDisconnected", "0");
          },1000)
        }
        if(location.pathname === '/dashboard'){
          fetchPatientCounter()
        }
        if(message.type === 'doctor_appointment_cancel_reminder'){
          localStorage.setItem('consultationContinuetime',moment().add(parseInt(3), "m"))
          localStorage.setItem('consultationContinueValue',true)
          localStorage.setItem('consultationId',message.consultation_id)
          setIsShowConsultationContinueAlert(JSON.parse(localStorage.getItem('consultationContinueValue')))
          setCountDownTimerOn(true)
          setCountDownTime(180)
        }
        setMessage(message.data);
        setOpenSnack(true);
        setSeverity("success");
      } else {
        setMessage("res not OK");
        setOpenSnack(true);
        setSeverity("warning");
      }
    } catch (err) {
      setMessage("Error from catch");
      setOpenSnack(true);
      setSeverity("error");
    }
  };


  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setMessage("");
  };

  const handleCloseSnackOfDoctorSlot = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackOfDoctorSlot(false);
    setDoctorSlotMsg("");
  };

  const navBarComponenet = () => {
    return (
      <Fragment>
        {flowboardStatus !== 5 && message &&
          <NotificationSound
          notificationType={message.notification_type}
          />
        }
        {message ? (
          <Snackbar
            open={openSnack}
            style={{ top: "17%" }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={50000}
            onClose={handleCloseSnack}
          >
            <Alert onClose={handleCloseSnack} severity={severity}>
              {message}
            </Alert>
          </Snackbar>
        ) : null}
          {isShowAlertToDoctor && (
          <Snackbar
            open={openSnackOfDoctorSlot}
            style={{ top: "19%" }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={15000}
            onClose={handleCloseSnackOfDoctorSlot}
          >
            <Alert 
            onClose={handleCloseSnackOfDoctorSlot}
             severity={severityOfDoctorSlot}>
              {doctorSlotMsg}
            </Alert>
          </Snackbar>
        )}
        <div className={classes.root}>
          <AppBar
            style={{ backgroundColor: "#006495" }}
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                // onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
              </IconButton>

              <Typography variant="h6" noWrap></Typography>
              <div style={{ position: "absolute", left: "61%" }}>
                <Switch
                  isOn={threshold || ""}
                  handleToggle={() => handleConsultation()}
                  onColor="rgb(109, 203, 133)"
                />
              </div>
              <EndConsultation
                onClose={handleConsultationClose}
                open={endConsultationModalOpen}
                logOffType={logOffType}
              />
              <div style={{ position: "absolute", left: "68%" }}>
                <Typography style={{ fontWeight: 500 }}>
                  Start Consultation
                </Typography>
              </div>
              <div className={classes.manager}>
                <Button
                  aria-owns={
                    openNotification ? "notification-menu-list-grow" : null
                  }
                  aria-haspopup="true"
                  onClick={handleClickNotification}
                  className={classes.buttonLink}
                >
                  <Notifications
                    style={{ color: "aliceblue" }}
                    className={classes.icons}
                  />
                  {/* <span className={classes.notifications}>5</span> */}
                  <Hidden mdUp implementation="css">
                    <p
                      onClick={handleCloseNotification}
                      className={classes.linkText}
                    >
                      Notification
                    </p>
                  </Hidden>
                </Button>
                <Poppers
                  open={Boolean(openNotification)}
                  anchorEl={openNotification}
                  transition
                  disablePortal
                  className={
                    classNames({ [classes.popperClose]: !openNotification }) +
                    " " +
                    classes.popperNav
                  }
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      id="notification-menu-list-grow"
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener
                          onClickAway={handleCloseNotification}
                        >
                          <MenuList role="menu">
                            <MenuItem
                              onClick={handleCloseNotification}
                              className={classes.dropdownItem}
                            >
                              Notification from Nurse
                            </MenuItem>
                            <MenuItem
                              onClick={handleCloseNotification}
                              className={classes.dropdownItem}
                            >
                              You have 5 new tasks
                            </MenuItem>
                            <MenuItem
                              onClick={handleCloseNotification}
                              className={classes.dropdownItem}
                            >
                              New message from Admin
                            </MenuItem>
                            <MenuItem
                              onClick={handleCloseNotification}
                              className={classes.dropdownItem}
                            >
                              Another Notification
                            </MenuItem>
                            <MenuItem
                              onClick={handleCloseNotification}
                              className={classes.dropdownItem}
                            >
                              New message from Doctor
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Poppers>
              </div>
              <EndconsultationAlertBox
                onClose={handleEndConsultationAlertClose}
                open={endConsultationAlertOpen}
              />
              {isShowConsultationContinueAlert &&(
              <ContinueConsulationAlert
                onClose={setIsShowConsultationContinueAlert}
                open={isShowConsultationContinueAlert}
                setCountDownTimerOn={setCountDownTimerOn}
                countDownTime={countDownTime}
              />)}
              <div style={{ position: "absolute", left: "87%" }}>
                <IconButton
                  aria-haspopup="true"
                  color="inherit"
                  data_testid={`doctor-menu`}
                  aria-controls="profile-menu"
                  onClick={handleClick}
                >
                  <AccountIcon />
                </IconButton>
                <Menu
                  id="fade-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={opens}
                  onClose={handleClose}                  
                  TransitionComponent={Fade}
                >
                  {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                  <MenuItem data_testid={`doctor-menu-My Setting`} onClick={handleSettingOpen}>My Setting</MenuItem>
                  <MenuItem data_testid={`doctor-menu-Logout`} onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
              <SettingModal
                onClose={handleSettingClose}
                open={settingModalOpen}
              />
              {isShowUnassignApoointmentAlert &&(
              <UnassignAppointmentAlert
              open = {isShowUnassignApoointmentAlert}
              onClose={setIsShowUnassignApoointmentAlert}
              visitId={currentVisitId}
              setCurrentVisitId={setCurrentVisitId}           
              />)}
            </Toolbar>
          </AppBar>
        </div>
      </Fragment>
    );
  };
  return (
    <div>
      {navBarComponenet()}
      {logOut === 1 ? (
        <React.Fragment>
          <Redirect to="/auth/login" />
        </React.Fragment>
      ) : null}
    </div>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    goTo: state.login.goTo,
    logOut: state.login.logout,
    loading: state.login.loading,
    checkConsultationMode:state.startConsultation.isChangeConsultationMode,
    extendTimeCounter : state.startConsultation.extendTimeCounter,
    isShowAlertToDoctor:state.startConsultation.isShowAlertToDoctor,
    flowboardStatus: state.flowBoardStatus.status,
    isStartConsultationOnSlot: state.startConsultation.isStartConsultationOnSlot,
    patientId:state.patientDetail.patientData,
    isConsultationStart : state.startConsultation.isConsultationStart
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    startConsultation: (data) => dispatch(startConsultation(data)),
    endConsultation: (endtime, check) =>
    dispatch(endConsultation(endtime, check)),
    isCloseExtendTimeMOdal : (data)=>dispatch(isCloseExtendTimeMOdal(data)),
    isStartConsultationOnSlotCheck : (data)=>dispatch(isStartConsultationOnSlotCheck(data)),
    fetchMsoDetail : (msoId)=>dispatch(fetchMsoDetail(msoId)),
    fetchPatientCounter : ()=>dispatch(fetchPatientCounter()),
    msoUnavailable : ()=>dispatch(msoUnavailable()),
    msoAvailable : ()=>dispatch(msoAvailable()),
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
