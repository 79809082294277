import * as actionTypes from "actions";
const userInitialState = {
  loading: false,
  counts: [],
  appointments:[],
  date:'',
  error: "",
};

const calenderReducer = (state = userInitialState,  action) => {
  switch (action.type) {
    case actionTypes.FETCH_CALENDER_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_CALENDER_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        counts: action.payload,
        error: "",
      };
    case actionTypes.FETCH_CALENDER_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        counts: [],
        error: action.payload,
      };
      case actionTypes.FETCH_CALENDER_APPOINTMENTS:
        return {
          ...state,
          loading: true,
          date:action.date
        };
      case actionTypes.FETCH_CALENDER_APPOINTMENTS_START:
        return {
          ...state,
          loading: true,
        };

      case actionTypes.FETCH_CALENDER_APPOINTMENTS_SUCCESS:
        return {
          ...state,
          loading: false,
          appointments: action.payload,
          error: "",
        };
      case actionTypes.FETCH_CALENDER_APPOINTMENTS_FAILURE:
        return {
          ...state,
          loading: false,
          appointments: [],
          error: action.payload,
        };
    default:
      return state;
  }
};

export default calenderReducer;
