import { takeEvery, call, put, delay } from "redux-saga/effects";
import {
  FLOWBOARD_STATUS_CHANGE_REQUEST,
  FETCH_FLOWBOARD_STATUS_REQUEST,
  REVIEW_PATIENT_REQUEST,
} from "../actions";
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherFlowBoardSaga() {
  yield takeEvery(FLOWBOARD_STATUS_CHANGE_REQUEST, flowBoardStatusSaga);
  yield takeEvery(FETCH_FLOWBOARD_STATUS_REQUEST, fetchFlowboardStatusSaga);
  yield takeEvery(REVIEW_PATIENT_REQUEST, reviewPatientSaga);
}

function* reviewPatientSaga(action) {
  try {
    // console.log("hi");
    const response = yield call(
      doctorService.fetchPatientDetail,
      action.patientId
    );
    // console.log(response.data.data);
    for (let i = 0; i < response.data.data.attributes.visits.length; i++) {
      if (
        response.data.data.attributes.visits[i].visit_id ===
        parseInt(action.visitId)
      ) {
        var idd = response.data.data.attributes.visits[i].appointment_id;
      }
    }
    let flowboard = {
      patientId: action.patientId,
      appointmentId: idd,
      visitId: action.visitId,
      status: "4",
    };
    const res = yield call(doctorService.flowBoardStatus,flowboard.appointmentId,flowboard.patientId,flowboard.status);
    // console.log(res)
    if(res.status === 200){
      // console.log('j')
      const flowboardStatus = yield call(doctorService.fetchFlowboardStatus,action.visitId);
      yield put( actions.flowBoardStatusChangeSuccess(flowboardStatus.data.message));
    }
    
    // yield put( actions.fetchFlowBoardSuccess(res.data.flowboard_status));
  } catch (error) {
    yield put(actions.reviewRequestFailure(error.response.data.error));
    // alert(error.response.data.error)
  }
}

function* flowBoardStatusSaga(action) {
  try {
    const response = yield call(
      doctorService.flowBoardStatus,
      action.flowboard.appointmentId,
      action.flowboard.patientId,
      action.flowboard.status
    );
    yield put(actions.flowBoardStatusChangeSuccess(response.data.message));
    // alert(response.data.message);
    const res = yield call(
      doctorService.fetchFlowboardStatus,
      action.flowboard.visitId
    );
    yield put(actions.fetchFlowBoardSuccess(res.data.flowboard_status));
  } catch (error) {
    yield put(actions.flowBoardStatusChangeFailure(error.response.data.error));
    // alert(error.response.data.error)
  }
}

function* fetchFlowboardStatusSaga(action) {
  try {
    const res = yield call(doctorService.fetchFlowboardStatus, action.visitId);
    yield put(actions.fetchFlowBoardSuccess(res.data.flowboard_status));
  } catch (error) {
    yield put(actions.fetchFlowBoardFailure(error.response.data.error));
  }
}
