import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

import AuthLayout from "./layouts/Auth";
import ErrorLayout from "./layouts/Error";
import DashboardLayout from "./layouts/Dashboard";
import Dashboard from "./views/Dashboard";
import ZoomLayout from "./layouts/Zoom/Zoom";

const routes = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/auth/login" />,
  },
  {
    path: "/auth",
    component: AuthLayout,
    routes: [
      {
        path: "/auth/login",
        exact: true,
        component: lazy(() => import("views/login")),
      },
      {
        path: "/auth/forgot_password",
        exact: true,
        component: lazy(() => import("./views/login/ForgotPassword")),
      },
      {
        path: "/auth/password_resets/:id",
        exact: true,
        component: lazy(() => import("./views/login/ResetPassword")),
      },
      {
        path: "/vonage",
        exact: true,
        component: lazy(() =>
          import("./views/VonageVideoCall/VonageVideoCall")
        ),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: "/vonage",
    component: ZoomLayout,
    routes: [
      {
        path: "/vonage/:visit",
        exact: true,
        component: lazy(() =>
          import("./views/VonageVideoCall/VonageVideoCall")
        ),
      },
      {
        path: "/zoom/zoom_call_end/:id/:type",
        exact: true,
        component: lazy(() => import("./views/Prescription/Zoom/ZoomFinish")),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: "/errors",
    component: ErrorLayout,
    routes: [
      {
        path: "/errors/error-404",
        exact: true,
        component: lazy(() => import("views/Error404")),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },

  {
    route: "*",
    component: DashboardLayout,
    routes: [
      {
        path: "/dashboard",
        exact: true,
        component: Dashboard,
      },
      {
        path: "/patientdetail/:id/:type",
        exact: true,
        component: lazy(() => import("./views/PatientDetail/PatientDetail")),
      },
      {
        path: "/patient_detail_visit_wise/:id/:type",
        exact: true,
        component: lazy(() =>
          import(
            "./views/Dashboard/components/SearchPatientTab/SearchPatientTab"
          )
        ),
      },

      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
];

export default routes;
