import * as actionTypes from "actions";

const INITIAL_STATE = {
  error: null,
  message:'',
  todaySchedule:[],
  isChangeConsultationMode:false,
  extendTimeCounter:0,
  isShowAlertToDoctor:false,
  isCloseModal:false,
  allSchedule:[],
  loading: false,
  isStartConsultationOnSlot:false,
  isConsultationStart : false
};

const startConsultationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_SCHEDULE:
      return {
        ...state,
        ...{ loading: true, error: null },
      };
    case actionTypes.FETCH_ALL_SCHEDULE_SUCCESS:
      return {
        ...state,
        allSchedule: action.payload,
        loading: false,
      };
      case actionTypes.FETCH_ALL_SCHEDULE_FAILURE:
        return {
          ...state,
          allSchedule:[],
          loading: false,
        };
    case actionTypes.FETCH_TODAY_SCHEDULE:
      return {
        ...state,
        ...{ loading: true, error: null },
      };
    case actionTypes.FETCH_TODAY_SUCCESS:
      return {
        ...state,
        todaySchedule: action.payload,
        allSchedule:[],
        loading: false,
      };
      case actionTypes.FETCH_TODAY_FAILURE:
        return {
          ...state,
          todaySchedule:[],
          loading: false,
        };
    case actionTypes.SCHEDULE_ADD_START:
      return {
        ...state,
        ...{ loading: true, error: null },
      };
    case actionTypes.SCHEDULE_ADD_SUCCESS:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
      case actionTypes.SCHEDULE_ADD_FAILURE:
        return {
          ...state,
          message:'',
          loading: false,
        };
      case actionTypes.START_CONSULTATION_START:
        return {
          ...state,
          ...{ loading: true, error: null },
        };
      case actionTypes.START_CONSULTATION_SUCCESS:
        return {
          ...state,
          isChangeConsultationMode: action.payload,
          isShowAlertToDoctor:action.isSlot,
          isConsultationStart:true,
          loading: false,
        };
        case actionTypes.START_CONSULTATION_FAILURE:
          return {
            ...state,
            message:'',
            isShowAlertToDoctor:false,
            isConsultationStart:false,
            loading: false,
          };
          case actionTypes.END_CONSULTATION_START:
            return {
              ...state,
              ...{ loading: true, error: null },
            };
          case actionTypes.END_CONSULTATION_SUCCESS:
            return {
              ...state,
              isChangeConsultationMode: action.payload,
              isConsultationStart:false,
              isShowAlertToDoctor:false,  
              loading: false,
            };
            case actionTypes.END_CONSULTATION_FAILURE:
              return {
                ...state,
                message:'',
                loading: false,
              };
              case actionTypes.IS_CLOSE_EXTEND_TIME_MODAL:
                return {
                  ...state,
                  message:'',
                  loading: false,
                  isCloseModal:action.data,
                };
              case actionTypes.PRE_LOGOFF_CONSULTATION_START:
            return {
              ...state,
              ...{ loading: true, error: null },
            };
          case actionTypes.PRE_LOGOFF_CONSULTATION_SUCCESS:
            return {
              ...state,
              isChangeConsultationMode: action.payload,
              isConsultationStart:false,
              isShowAlertToDoctor:false,
              loading: false,
            };
            case actionTypes.PRE_LOGOFF_CONSULTATION_FAILURE:
              return {
                ...state,
                message:'',
                loading: false,
              };
              case actionTypes.EXTEND_TIME_COUNTER:
                return {
                  ...state,
                  extendTimeCounter:action.count,
                  loading: false,
                };
                case actionTypes.IS_START_CONSULTATION_ON_SLOT_CHECK:
                  return {
                    ...state,
                    isStartConsultationOnSlot:action.payload,
                    loading: false,
                  };
    default:
      return state;
  }
};

export default startConsultationReducer;
