export const FETCH_PATIENTDETAIL_BY_ID_REQUEST = 'FETCH_PATIENTDETAIL_BY_ID_REQUEST';
export const FETCH_PATIENTDETAIL_BY_ID_SUCCESS = 'FETCH_PATIENTDETAIL_BY_ID_SUCCESS';
export const FETCH_PATIENTDETAIL_BY_ID_FAILURE = 'FETCH_PATIENTDETAIL_BY_ID_FAILURE';


export const fetchPatientDetailByIdRequest = (patientId) => {
  return {
      type: FETCH_PATIENTDETAIL_BY_ID_REQUEST,
      patientId:patientId,
  }
}

export const fetchPatientDetailByIdSuccess = data => {
  return {
      type: FETCH_PATIENTDETAIL_BY_ID_SUCCESS,
      payload: data
  }
}

export const fetchPatientDetailByIdFailure = error => {
  return {
      type: FETCH_PATIENTDETAIL_BY_ID_FAILURE,
      payload: error
  }
}
